import React, { useState, useContext, useMemo } from 'react'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { makeStyles } from '@mui/styles'
import { useToggle } from 'hooks/useToggle'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import {
  Autocomplete,
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel
} from '@mui/material'
import PartsOrderingJobRow from './components/PartsOrderingJobRow'
import { Money } from 'quoteCalculator/types'
import { Dollars } from 'tools'

// import { Autocomplete, TextField, DateField } from '@mui/material/DateF'

// const useStyles = makeStyles(theme => ({
//   techBlock: {
//     display: 'inline-block',
//     width: '100px',
//     backgroundColor: 'white',
//     fontWeight: 'bold',
//     border: '1px solid #434343',
//   },
//   gridBlock: {
//     display: 'block',
//     height: '60px',
//     width: '100px',
//     backgroundColor: 'white',
//     border: '1px solid #434343',
//     padding: '2px',
//   },
// }))

const ORDER_QUERY = gql`
  query upcomingJobs($date: ISO8601DateTime!, $technicianId: String, $market: String) {
    upcomingJobs(date: $date, technicianId: $technicianId, market: $market) {
      id
      startDatetime
      endDatetime

      quoteId
      quote {
        id
        leadId

        parts {
          id
          status
          price
          cost
          name
          number
          type
          referenceNumber
          prePaymentRequired
          etaBusinessDays
          specialOrder
          orderPlacedAt
          pickupHistory
        }
      }

      technicianId
      technician {
        name
      }

      leadId
      lead {
        name
      }
    }
  }
`

const MARKETS_QUERY = gql`
  query markets {
    markets {
      market
      state
      stateCode
    }
  }
`

export default function Order () {
  // const classes = useStyles()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [date, setDate] = useState(DateTime.local().startOf('day'))
  const [market, setMarket] = useState(null)
  const [technicianId, setTechnicianId] = useState(null)
  const [hideCompleted, toggleHideCompleted, disableHideCompleted, enableHideCompleted] = useToggle(true)

  const { error: jobsError, data: jobsData } = useQuery(ORDER_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      date: date.toISO(),
      market,
      technicianId
    }
  })

  const { error: marketsError, data: marketsData } = useQuery(MARKETS_QUERY, {
    variables: {
      date: date.toISO(),
      market,
      technicianId
    }
  })

  const techById = global.technicians.reduce((acc, tech) => ({ ...acc, [tech.id]: tech }), {})
  const technicianOptions = global.technicians
    .filter(technician => technician.active)
    .map(technician => ({
      value: technician.id,
      label: technician.name
    }))

  const { jobsByTechnician, datasums } = useMemo(() => {
    const jobsByTechnician = []
    const allPartsInView = []
    const needsOrderingToday = []
    let jobsCount = 0

    jobsData?.upcomingJobs.forEach(job => {
      if (job.quote.parts.length < 1) return

      const daysAhead = Math.floor(DateTime.fromISO(job.startDatetime, { zone: 'utc' }).diff(date, ['days']).days)
      if (hideCompleted && skipHideCompleted(job, daysAhead)) return

      jobsCount++
      jobsByTechnician[job.technicianId] = [...(jobsByTechnician[job.technicianId] ?? []), job]

      job.quote.parts.forEach(val => {
        allPartsInView.push(val)

        if (!val.orderPlacedAt && ((val.etaBusinessDays == null && daysAhead <= 3) || val.etaBusinessDays >= daysAhead)) { needsOrderingToday.push(val.id) }
      })
    })

    const allPartsCostSum = allPartsInView.reduce((acc, val) => acc.add(Money(val.cost)), Money(0)).toNumber()
    const allPartsPriceSum = allPartsInView.reduce((acc, val) => acc.add(Money(val.price)), Money(0)).toNumber()

    return {
      jobsByTechnician,
      datasums: {
        jobsCount,
        allPartsCostSum,
        allPartsPriceSum,
        allPartsRevenue: allPartsPriceSum - allPartsCostSum,
        allPartsCount: allPartsInView.length,
        allPartsCostAvg: allPartsCostSum / allPartsInView.length,
        allPartsPriceAvg: allPartsPriceSum / allPartsInView.length,
        needsOrderingCount: needsOrderingToday.length,
        allPartsCountAvg: allPartsInView.length / jobsCount,
        allPartsRevenueAvg: (allPartsPriceSum - allPartsCostSum) / allPartsInView.length
      }
    }
  }, [jobsData, date, hideCompleted])

  const marketOptions = useMemo(() => mapMarkets([...(marketsData?.markets ?? [])]), [marketsData])

  if (jobsError) return <>{JSON.stringify(jobsError)}</>
  if (marketsError) return <>{JSON.stringify(marketsError)}</>

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            disablePast
            value={date}
            onChange={handleChangeDate}
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>

        <Autocomplete
          clearOnBlur
          options={technicianOptions}
          getOptionLabel={value => value?.label ?? techById[value]?.name}
          renderInput={params => <TextField {...params} label='Technician' />}
          value={technicianId}
          onChange={handleChangeTechnician}
          sx={{ flex: 1 }}
        />

        <Autocomplete
          clearOnBlur
          options={marketOptions}
          getOptionLabel={value => value?.label ?? value}
          renderInput={params => <TextField {...params} label='Market' />}
          value={market}
          onChange={handleChangeMarket}
          sx={{ minWidth: '320px' }}
        />

        <FormControlLabel
          control={<Checkbox checked={hideCompleted} onChange={toggleHideCompleted} />}
          label='Hide ordered'
        />

      </Box>

      <Box sx={{ display: 'flex' }}>
        <LabeledFigure label='Number of parts' value={datasums.allPartsCount} />
        <LabeledFigure label='Average cost per part' value={<Dollars value={datasums.allPartsCostAvg} />} />
        <LabeledFigure label='Average parts per job' value={datasums.allPartsCountAvg.toFixed(2)} />
        <LabeledFigure label='Total cost of parts' value={<Dollars value={datasums.allPartsCostSum} />} />
        <LabeledFigure label='Total revenue from parts' value={<Dollars value={datasums.allPartsRevenue} />} />
        <LabeledFigure label='Number left to buy' value={datasums.needsOrderingCount} />
        {/* <LabeledFigure label='Number of jobs' value={datasums.jobsCount} /> */}
        {/* <LabeledFigure label='Average price of parts' value={<Dollars value={datasums.allPartsPriceAvg} />} /> */}
        {/* <LabeledFigure label='Total price of parts' value={<Dollars value={datasums.allPartsPriceSum} />} /> */}
        {/* <LabeledFigure label='Average revenue of parts' value={<Dollars value={datasums.allPartsRevenueAvg} />} /> */}
      </Box>

      {Object.keys(jobsByTechnician).map(technicianId => (
        <div key={technicianId}>
          <>{techById[technicianId]?.name}</>
          <TableContainer sx={{ td: { p: 0 } }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Parts to order today</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Job date</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell># Parts</TableCell>
                  <TableCell>Parts Sum</TableCell>
                  <TableCell>Parts Sum for Today</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsByTechnician[technicianId].map(job => (
                  <PartsOrderingJobRow key={job.id} job={job} today={date} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
    </>
  )

  function handleChangeTechnician (e, value) {
    setTechnicianId(value?.value ?? null)
  }

  function handleChangeDate (date) {
    setDate(date)
  }

  function handleChangeMarket (e, value) {
    if (value?.value == 'All') setMarket(null)
    else setMarket(value?.value ?? null)
  }
}

function LabeledFigure ({ label, value }) {
  return (
    <Box sx={{ p: 1, flex: 1 }}>
      <Box sx={{ p: 1, border: '2px solid', backgroundColor: '#03a9f418', borderColor: 'info.light', borderRadius: 4 }}>
        <code style={{ fontSize: '12px' }}>{label.toUpperCase()}</code>
        <Box sx={{ color: 'primary.dark', fontSize: '24px' }}>{value}</Box>
      </Box>
    </Box>
  )
}

// mapMarkets iterates markets, returns new [] with {label:string, value:string}
const mapMarkets = markets => {
  // const foundStates = {}
  const items = [{ label: 'All', value: 'All' }]

  markets.sort((a, b) => (a.stateCode < b.stateCode ? -1 : a.stateCode > b.stateCode))

  markets.forEach(market => {
    // if (!foundStates[market.state]) {
    // foundStates[market.state] = true
    // items.push({ label: market.state, value: market.stateCode })
    // }
    items.push({ label: market.market, value: market.market })
  })

  return items.sort((a, b) => (a.label > b.label ? 1 : -1))
}

function skipHideCompleted (job, daysAhead) {
  for (let i = 0, len = job.quote.parts.length, val = job.quote.parts[i]; i < len; val = job.quote.parts[++i]) {
    if (!val.orderPlacedAt && ((val.etaBusinessDays == null && daysAhead <= 3) || val.etaBusinessDays >= daysAhead)) { return false }
  }

  return true
}
