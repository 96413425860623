import { PaidTimeOffType } from './types/paidTimeOff'
import { SickTimeOffType } from './types/sickTimeOff'
import { TimeCardType } from './types/timecard'
import { UnpaidTimeOffType } from './types/unpaidTimeOff'

export const calculateWeeklyPayAdjustments = ({
  timeCards,
  pto,
  uto,
  sto,
  hourlyRate,
  state,
  quoteCommissionStructure,
}: {
  pto: Array<PaidTimeOffType>
  uto: Array<UnpaidTimeOffType>
  sto: Array<SickTimeOffType>
  timeCards: Array<TimeCardType>
  hourlyRate: number
  state: string
  quoteCommissionStructure: string
}) => {
  const totalTimeCardHourlyPayWithoutOvertime = timeCards.reduce(
    (acc: number, item: any) => acc + item.payoutData.totalHourlyPayWithoutOvertime,
    0
  )
  const totalTimeCardPaidHours = timeCards.reduce((acc: number, item: any) => acc + item.payoutData.totalPaidHours, 0)
  const totalPtoHours = pto.reduce((acc: number, item: any) => acc + Number(item.totalHours || 0), 0)
  const totalUtoHours = uto.reduce((acc: number, item: any) => acc + Number(item.totalHours || 0), 0)
  const totalStoHours = sto.reduce((acc: number, item: any) => acc + Number(item.totalHours || 0), 0)
  const totalPaidHours = totalPtoHours + totalTimeCardPaidHours

  let otHoursTotal = timeCards.reduce((acc: number, item: any) => acc + item.payoutData.totalOvertimeHours, 0)
  let otPayTotal = timeCards.reduce((acc: number, item: any) => acc + item.payoutData.totalOvertimePay, 0)
  let overtimeAdjustment = 0
  let minimumPayAdjustment = 0
  const ptoPay = totalPtoHours * hourlyRate
  const stoPay = totalStoHours * hourlyRate

  const totalHourlyPayWithPtoStoAndOT = totalTimeCardHourlyPayWithoutOvertime + ptoPay + stoPay + otPayTotal
  const minimumHoursToPayTech = 40 - Number(totalUtoHours)
  const technicianHourlyMinimum = Number(minimumHoursToPayTech) * Number(hourlyRate)

  const getPayAdjustmentForCalifornia = () => {
    if (totalTimeCardPaidHours > 40) {
      // 1. establish that overtime is maybe payable
      // 2. establish the number of hours that are overtime but not yet calculated as such
      // 3. Add the unpaid overtime directly into the pay (half rate due to the full rate already being applied directly on the paid hours of the time card.)
      const unpaidOvertimeHours = totalTimeCardPaidHours - otHoursTotal - 40 // ✅
      otHoursTotal += unpaidOvertimeHours // ✅
      otPayTotal += unpaidOvertimeHours * (Number(hourlyRate) * 1.5) // ✅ (used as display value)
      overtimeAdjustment += unpaidOvertimeHours * (Number(hourlyRate) * 0.5) // ✅ (used as calculation value)
    } else {
      // they have not earned the minimum so we need to add an increase
      if (totalHourlyPayWithPtoStoAndOT > technicianHourlyMinimum) {
      } else {
        const additionalPayToReachMinimum = Number(technicianHourlyMinimum) - Number(totalHourlyPayWithPtoStoAndOT)
        minimumPayAdjustment += Number(additionalPayToReachMinimum)
      }
    }
    return {
      overtimePay: otPayTotal,
      overtimeHours: otHoursTotal,
    }
  }

  const getPayAdjustmentsForNonCalifornia = () => {
    // if they have worked overtime...
    if (totalPaidHours > 40) {
      const hoursOver40 = Number(totalPaidHours) - 40
      // at this point, any hours worked over 40 have already been compensated at the normal rate on each of the timecards (inside the timecard payout data)
      // EX: if they worked 42 hours total for all of the timecards, then one of the timecards will have 2 hours OT on it that hasn't been charged at OT rate
      // so... since those hours have already been added at the old rate, we need to add them again at a half rate

      const overtimePayAmountForWeek = Number(hoursOver40 * (Number(hourlyRate) * 1.5))
      let halfRateOtPay = hoursOver40 * (hourlyRate * 0.5)
      overtimeAdjustment += halfRateOtPay

      // if (totalPtoHours > 0) {
      //   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //   // PTO hours must get calculated in overtime at the FULL overtime rate.
      //   // work hours get calculated in overtime at half the rate of normal which we see above.
      //   // for PTO we have to add back on the additional adjustment amount
      //   //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //   // simple terms: basically, if there is overtime for non california and this overtime includes PTO
      //   // then we must calculate the overtimeAdjustment correctly... our issue is that timecards don't individually know
      //   // if they have overtime, we only know there is overtime for the entire payroll period... so any overtime hours that
      //   // were added onto the timecard must have the actual pay adjusted in the correct way. thats what we're doing here,
      //   // adjusting the total pay for the payroll period in order to reflect the actual overtime they should get paid
      //   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      //   const ptoPayAtRegularPay = Number(totalPtoHours * Number(hourlyRate))
      //   overtimeAdjustment += ptoPayAtRegularPay
      // }

      return {
        overtimePay: overtimePayAmountForWeek,
        overtimeHours: hoursOver40,
      }
    } else {
      // we only pay them the minumum 40 hour week if they have been eligable to work 40 hours
      const technicianHourlyMinimum = Number(minimumHoursToPayTech) * Number(hourlyRate)

      if (totalHourlyPayWithPtoStoAndOT > technicianHourlyMinimum) {
        // they have earned more than the minumum so we do nothing
      } else {
        // they have not earned the minimum so we need to add an increase
        const additionalPayToReachMinimum = Number(technicianHourlyMinimum) - Number(totalHourlyPayWithPtoStoAndOT)
        minimumPayAdjustment += Number(additionalPayToReachMinimum)
      }
      return {
        overtimePay: 0,
        overtimeHours: 0,
      }
    }
  }

  if (state === 'CA') {
    const { overtimePay, overtimeHours } = getPayAdjustmentForCalifornia()
    //////
    // if the hourly pay with overtime included is less than the minimum pay for the week
    // then bump the totalHourlyPay to be the minimum for that technician for that week
    // DO NOT ADD ADDITIONAL OVERTIME ON TOP OF THE MINIMUM PAY ADJUSTMENT...
    //////
    let totalHourlyPay = 0

    if (totalHourlyPayWithPtoStoAndOT < technicianHourlyMinimum) {
      totalHourlyPay = Number(totalHourlyPayWithPtoStoAndOT) + Number(minimumPayAdjustment)
      // overtimeAdjustment += overtimePay
      // if the hourly + OT is less than the minimum, then consider the overtimeAdjustment
      // as 0 -- the minimum adjustment will catch this
    } else {
      //////
      // if they have made more than the minimum for their week, then pay them
      // total hourly pay of the all the hours + overtime pay
      //////
      totalHourlyPay = Number(totalHourlyPayWithPtoStoAndOT)
    }

    if (quoteCommissionStructure === 'labor cost') {
      totalHourlyPay = Number(stoPay) + Number(ptoPay)
    }

    return {
      totalPaidHours: Number(totalPaidHours.toFixed(2)),
      totalStoHours: Number(totalStoHours.toFixed(2)),
      totalUtoHours: Number(totalUtoHours.toFixed(2)),
      totalPtoHours: Number(totalPtoHours.toFixed(2)),
      totalTimeCardHours: Number(totalTimeCardPaidHours.toFixed(2)),
      minimumPayAdjustment: Number(minimumPayAdjustment.toFixed(2)),
      overtimeAdjustment: Number(overtimeAdjustment.toFixed(2)),
      overtimePay: Number(overtimePay.toFixed(2)),
      overtimeHours: Number(overtimeHours.toFixed(2)),
      totalHourlyPay: Number(totalHourlyPay.toFixed(2)),
    }
  } else {
    const { overtimePay, overtimeHours } = getPayAdjustmentsForNonCalifornia()
    // overtimeAdjustment is used as the actual math amount, and overtimePay is just a descroption basically
    let totalHourlyPay =
      Number(totalHourlyPayWithPtoStoAndOT) + Number(minimumPayAdjustment) + Number(overtimeAdjustment)

    if (quoteCommissionStructure === 'labor cost') {
      totalHourlyPay = Number(stoPay) + Number(ptoPay)
    }

    return {
      totalPaidHours: Number(totalPaidHours.toFixed(2)),
      totalStoHours: Number(totalStoHours.toFixed(2)),
      totalUtoHours: Number(totalUtoHours.toFixed(2)),
      totalPtoHours: Number(totalPtoHours.toFixed(2)),
      totalTimeCardHours: Number(totalTimeCardPaidHours.toFixed(2)),
      minimumPayAdjustment: Number(minimumPayAdjustment.toFixed(2)),
      overtimeAdjustment: Number(overtimeAdjustment.toFixed(2)),
      overtimePay: Number(overtimePay.toFixed(2)),
      overtimeHours: Number(overtimeHours.toFixed(2)),
      totalHourlyPay: Number(totalHourlyPay.toFixed(2)),
    }
  }
}
