import isLaborTaxable from './isLaborTaxable'
import { Quote, Lead, Money, PrimaryLabor, Totals, Part } from './types'

interface priced {
  price: number
}

interface hourly {
  totalHours: number
}

function sumMoney<T extends priced>(list: Array<T>): Big {
  return list.reduce((acc: Big, item: T) => acc.add(Money(item.price)), Money(0)).round(2)
}

function hoursReducer<T extends hourly>(list: Array<T>) {
  return list.reduce((acc: number, item: T) => Number(item.totalHours.toFixed(2) || 0) + acc, 0)
}

// always set to zero until we decide to add back a volume discount
export const getVolumeDiscountPercent = (primaryLabor: Array<PrimaryLabor>) => {
  // switch (true) {
  //   case primaryLabor.length === 0:
  //     return 0
  //   case primaryLabor.length === 1:
  //     return 0
  //   case primaryLabor.length === 2:
  //     return 10
  //   case primaryLabor.length === 3:
  //     return 15
  //   case primaryLabor.length === 4:
  //     return 20
  //   case primaryLabor.length > 4:
  //     return 25
  //   default:
  //     return 0
  // }
  return Money(0)
}

export default function newTotals(quote: Quote, lead: Lead): Totals {
  const primaryLaborVoided = quote.primaryLaborItems.filter(labor => labor.void)
  const primaryLaborVoidedSum = sumMoney(primaryLaborVoided)
  const primaryLaborItems = quote.primaryLaborItems.filter(labor => !labor.void)
  const primaryLaborSum = sumMoney(primaryLaborItems)

  // volume discount
  const primaryLaborVolumeDiscountPercent = getVolumeDiscountPercent(primaryLaborItems)
  const primaryLaborVolumeDiscountAmount = primaryLaborSum.mul(primaryLaborVolumeDiscountPercent).div(100).round(2)
  const primaryLaborSumAfterVolumeDiscount = primaryLaborSum.sub(primaryLaborVolumeDiscountAmount) // ADR v0.1.0

  const otherLaborVoided = quote.otherLaborItems.filter(c => c.void)
  const otherLaborVoidedSum = sumMoney(otherLaborVoided)
  const otherLaborItems = quote.otherLaborItems.filter(c => !c.void)
  const otherLaborSum = sumMoney(otherLaborItems)

  // offered discount
  const laborSumAfterVolumeDiscount = primaryLaborSumAfterVolumeDiscount.add(otherLaborSum)
  let offeredDiscount = Money(quote.offeredDiscount)
  if (offeredDiscount.gt(laborSumAfterVolumeDiscount)) offeredDiscount = laborSumAfterVolumeDiscount
  const laborSumAfterAllDiscounts = laborSumAfterVolumeDiscount.sub(offeredDiscount)

  // const parts = quote.parts.filter(p => !p.void)
  // const partsVoided = quote.parts.filter(p => p.void)
  const parts = quote.parts
  const partsPrePay = parts.filter((part: Part) => part.prePaymentRequired)

  // const partsCount = parts.length
  const partsSum = sumMoney(parts)
  const partsPrePaySum = sumMoney(partsPrePay)
  // const partsVoidedSum = sumMoney(partsVoided)

  const carbodylabChargesVoided = quote.carbodylabCharges.filter(c => c.void)
  const carbodylabChargesVoidedSum = sumMoney(carbodylabChargesVoided)
  const carbodylabCharges = quote.carbodylabCharges.filter(c => !c.void)
  const carbodylabChargesSum = sumMoney(carbodylabCharges)

  const technicianChargesVoided = quote.technicianCharges.filter(c => c.void)
  const technicianChargesVoidedSum = sumMoney(technicianChargesVoided)
  const technicianCharges = quote.technicianCharges.filter(c => !c.void)
  const technicianChargesSum = sumMoney(technicianCharges)

  const preTaxGrandTotal = laborSumAfterAllDiscounts.add(partsSum).add(carbodylabChargesSum).add(technicianChargesSum)
  const preJobTotalBeforeTax = partsPrePaySum
  const postJobTotalBeforeTax = preTaxGrandTotal.sub(preJobTotalBeforeTax) // ADR v0.1.0

  const laborIsTaxable = isLaborTaxable(quote, lead)
  const taxRate = quote.taxRate
  const taxableAmount = partsSum.add(laborIsTaxable ? laborSumAfterAllDiscounts : Money(0))
  const taxTotal = taxableAmount.mul(taxRate).round(2)
  const postTaxGrandTotal = preTaxGrandTotal.add(taxTotal)
  const preJobTaxAmount = preJobTotalBeforeTax.mul(taxRate).round(2)
  const preJobGrandTotal = preJobTotalBeforeTax.add(preJobTaxAmount)
  const postJobGrandTotal = postTaxGrandTotal.sub(preJobGrandTotal) // ADR v0.1.0

  const voidedSum = primaryLaborVoidedSum
    .add(otherLaborVoidedSum)
    .add(technicianChargesVoidedSum)
    .add(carbodylabChargesVoidedSum)
  // .add(partsVoidedSum)

  return {
    primaryLaborSum: primaryLaborSum.toNumber(),
    otherLaborSum: otherLaborSum.toNumber(),
    carbodylabChargesSum: carbodylabChargesSum.toNumber(),
    technicianChargesSum: technicianChargesSum.toNumber(),
    offeredDiscount: offeredDiscount.toNumber(),

    primaryLaborSumBeforeVolumeDiscount: primaryLaborSum.toNumber(),
    primaryLaborSumAfterVolumeDiscount: primaryLaborSumAfterVolumeDiscount.toNumber(),
    primaryLaborVolumeDiscountPercent: primaryLaborVolumeDiscountPercent.toNumber(),
    primaryLaborVolumeDiscountAmount: primaryLaborVolumeDiscountAmount.toNumber(),
    laborSumAfterVolumeDiscount: laborSumAfterVolumeDiscount.toNumber(),

    laborSum: primaryLaborSum.add(otherLaborSum).toNumber(),
    totalLaborHours: hoursReducer(primaryLaborItems),
    laborSumAfterAllDiscounts: laborSumAfterAllDiscounts.toNumber(),
    voidedSum: voidedSum.toNumber(),
    // partsCount,
    partsSum: partsSum.toNumber(),
    taxableAmount: taxableAmount.toNumber(),
    preJobTotalBeforeTax: preJobTotalBeforeTax.toNumber(),
    postJobTotalBeforeTax: postJobTotalBeforeTax.toNumber(),
    preTaxGrandTotal: preTaxGrandTotal.toNumber(),

    laborIsTaxable,
    taxRate,
    taxTotal: taxTotal.toNumber(),

    preJobTaxTotal: preJobTaxAmount.toNumber(),
    preJobGrandTotal: preJobGrandTotal.toNumber(),
    postJobGrandTotal: postJobGrandTotal.toNumber(),
    postTaxGrandTotal: postTaxGrandTotal.toNumber(),
    quoteGrandTotal: postTaxGrandTotal.toNumber(),
  }
}
