import Big from 'big.js'

export interface Charge {
  id: string
  name: string
  price: number
  void: boolean
}
export function isChargeEqual(c1: Charge, c2: Charge): boolean {
  return c1.id == c2.id && c1.name == c2.name && c1.price == c2.price && c1.void == c2.void
}

export interface Diff {
  primaryLaborAdd?: Array<PrimaryLabor>
  primaryLaborDelete?: Array<PrimaryLabor>
  otherLaborAdd?: Array<Charge>
  otherLaborDelete?: Array<Charge>
  carbodylabChargesAdd?: Array<Charge>
  carbodylabChargesDelete?: Array<Charge>
  techChargesAdd?: Array<Charge>
  techChargesDelete?: Array<Charge>
  offeredDiscount?: number
}

export interface PrimaryLabor extends Charge {
  totalHours: number
}
export function isPrimaryLaborEqual(p1: PrimaryLabor, p2: PrimaryLabor): boolean {
  return isChargeEqual(p1, p2) && p1.totalHours == p2.totalHours
}

export interface Lead {
  id: string
  addressLineOne: string
  addressLineTwo: string
  state: string
  zip: string
  city: string
  address: string
}

export interface Part {
  id: string
  price: number
  // void: boolean
  prePaymentRequired: boolean
}

export interface Quote {
  id: string
  parts: Array<Part>
  primaryLaborItems: Array<PrimaryLabor>
  otherLaborItems: Array<Charge>
  carbodylabCharges: Array<Charge>
  technicianCharges: Array<Charge>
  offeredDiscount: number
  taxRate: number
}

export interface Totals {
  primaryLaborSum: number
  otherLaborSum: number
  carbodylabChargesSum: number
  technicianChargesSum: number
  offeredDiscount: number

  // volume discount
  primaryLaborSumBeforeVolumeDiscount: number
  primaryLaborSumAfterVolumeDiscount: number
  primaryLaborVolumeDiscountPercent: number
  primaryLaborVolumeDiscountAmount: number
  laborSumAfterVolumeDiscount: number

  // derived
  laborSum: number
  totalLaborHours: number
  laborSumAfterAllDiscounts: number
  voidedSum: number
  partsSum: number
  taxableAmount: number
  preJobTotalBeforeTax: number
  postJobTotalBeforeTax: number
  preTaxGrandTotal: number

  // tax
  laborIsTaxable: boolean
  taxRate: number
  taxTotal: number

  // derived from tax
  preJobTaxTotal: number
  preJobGrandTotal: number
  postJobGrandTotal: number
  postTaxGrandTotal: number

  // deprecated
  quoteGrandTotal: number // duplicate of postTaxGrandTotal

  // removed
  // preJobAmountDue: number
  // laborSumAfterDiscount: number
}

export interface QuoteWithTotals extends Quote, Totals {}

export const Money = (function () {
  const money = Big()
  money.DP = 2
  return money
})()
