import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
  , Button
} from '@mui/material'
import { useRef, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Dollars, keysToCamel } from 'tools'
import { DateTime } from 'luxon'
import { ExpandMoreRounded, InfoRounded, LaunchRounded, MoreVertRounded } from '@mui/icons-material'
import { ChangeCommissionDialog } from './ChangeCommissionDialog'
import { JobSkeletonRow } from '../TimeCards'
import { useLocalStorage } from 'hooks/useLocalStorage'
// import { calculateBToBJobPayoutData } from './bToBJob'
import { calculateBToBJobPayoutData } from 'payrollCalculator'

const B_TO_B_JOB_QUERY = gql`
  query BToBJob($bToBJobId: String!) {
    bToBJob(bToBJobId: $bToBJobId) {
      id
      technician {
        id
        commissionAmount
        hourlyRate
      }
      startDatetime
      endDatetime
      scheduledLength
      dealer {
        id
        businessName
        addressLineOne
        addressLineTwo
        city
        state
        zip
        primaryContact {
          firstName
          lastName
          email
        }
      }
      workLog {
        id
        createdAt
        startedAt
        completedAt
        type
        isPaid
        jobId
        travelDistance
        totalHours
        commissionType
        selectedForCommissionPayment
        hourlyType
        payoutData
      }
    }
  }
`

export const BToBJobRow = ({ bToBJobId, previousLogs }) => {
  const { loading, error, data, refetch } = useQuery(B_TO_B_JOB_QUERY, { variables: { bToBJobId } })
  const [menuOpen, setMenuOpen] = useState(false)
  const [commissionMenuOpen, setCommissionMenuOpen] = useState(false)
  const [payrollOpen] = useLocalStorage('payrollSessionOpen', 'false', false)

  const popoverItemRef = useRef()

  if (loading) return <JobSkeletonRow />
  if (error) return <div>Error!</div>

  const job = data?.bToBJob
  const { workLog } = keysToCamel(job)
  const quoteIsAlreadyPaid = false

  const quoteIsNotAlreadyPaid = true

  const payoutData = calculateBToBJobPayoutData({ workLog: { ...workLog, technician: job.technician, bToBJob: job } })

  return (
    !loading && (
      <>
        <TableRow
          sx={[
            {
              background: '#f9f9f9',
              borderRadius: '12px'
            },
            quoteIsNotAlreadyPaid && { background: '#2e7d321a' },
            quoteIsAlreadyPaid && { background: '#ff00001a' }
          ]}
        >
          <TableCell align='right'>
            <a href={`/b-to-b/dealers/${job.dealer.id}`}>
              {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('t')} -{' '}
              {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat('t')}
            </a>
          </TableCell>
          <TableCell align='right'>
            <a
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 1,
                maxWidth: '200px'
              }}
              href={`/b-to-b/dealers/${job.dealer.id}`}
            >
              {' '}
              {job.dealer.businessName}{' '}
            </a>{' '}
          </TableCell>
          <TableCell align='right'>{DateTime.fromISO(job.startDatetime, { zone: 'UTC' }).toFormat('HH:mm')}</TableCell>
          <TableCell align='right'>{DateTime.fromISO(workLog.startedAt, { zone: 'UTC' }).toFormat('HH:mm')}</TableCell>
          <TableCell align='right'>{job.scheduledLength}</TableCell>
          <TableCell align='right'>{workLog.totalHours}</TableCell>

          <TableCell
            align='right'
            sx={{
              fontWeight: 'bold',
              '& svg': { fontSize: '18px', cursor: 'pointer', color: '#999', '&:hover': { color: '#3f51b5' } }
            }}
          >
            <Dollars value={payoutData?.hourlyPay || 0} />
            &nbsp;
            <Tooltip title={payoutData?.hourlyPayDescription}>
              <InfoRounded />
            </Tooltip>
          </TableCell>

          <TableCell
            align='right'
            sx={{
              fontWeight: 'bold'
            }}
          >
            <Dollars value={payoutData?.totalPayout || 0} />
          </TableCell>
          <TableCell>
            <Button
              disabled={payrollOpen === 'false'}
              onClick={() => setMenuOpen(true)}
              ref={popoverItemRef}
              sx={{
                minWidth: '20px',
                minHeight: '20px',
                padding: '.125rem',
                '& svg': { width: '20px', height: '20px' }
              }}
            >
              <MoreVertRounded />
            </Button>
            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              onClose={() => {
                setMenuOpen(false)
                setCommissionMenuOpen(false)
              }}
              open={menuOpen}
              anchorEl={popoverItemRef.current}
            >
              <List size='small' disablePadding>
                <ListItem disablePadding disableGutters>
                  <ListItemButton onClick={_ => setCommissionMenuOpen(!commissionMenuOpen)}>
                    Payment settings <LaunchRounded sx={{ fontSize: '18px', ml: '.5rem' }} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>
          </TableCell>
        </TableRow>
        <ChangeCommissionDialog
          job={job}
          workLog={{ ...workLog, payoutData }}
          commissionMenuOpen={commissionMenuOpen}
          setCommissionMenuOpen={setCommissionMenuOpen}
          refetch={refetch}
        />
      </>
    )
  )
}

export const TimeCardBToBJobsRow = ({ timeCard }) => {
  const jobIds = timeCard?.bToBJobsLogs?.filter(log => log.bToBJobId) || []

  return jobIds.map((log, index) => {
    const logsBeforeThisOne = timeCard.bToBJobsLogs?.filter(
      timeLog => DateTime.fromISO(log.startedAt) > DateTime.fromISO(timeLog.startedAt)
    )

    return <BToBJobRow previousLogs={logsBeforeThisOne} workLog={log} bToBJobId={log.bToBJobId} index={index} />
  })
}

export const BToBJobsDropdown = ({ bToBWorkLogs, expanded, timeCard }) => {
  const [bToBExpanded, setBToBExpanded] = useState(true)

  return (
    <>
      {bToBWorkLogs.length > 0 && (
        <TableRow
          sx={[
            expanded && {
              border: '2px solid #3f51b5',
              borderTop: '0px !important',
              borderBottom: '0px !important'
            }
          ]}
        >
          <TableCell
            sx={{ padding: '.25rem .5rem', background: '#f2f6fa', fontWeight: 600, cursor: 'pointer' }}
            colSpan={14}
          >
            <Box
              onClick={() => setBToBExpanded(!bToBExpanded)}
              sx={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}
            >
              B2B Jobs <ExpandMoreRounded sx={{ transform: bToBExpanded ? 'rotate(180deg)' : 'scale(1)' }} />
            </Box>
          </TableCell>
        </TableRow>
      )}

      {bToBWorkLogs.length > 0 && (
        <TableRow
          sx={[{ background: '#ddd' }, expanded && { border: '2px solid #3f51b5', borderTop: '0px !important' }]}
        >
          <TableCell sx={{ transition: 'all .25s ease-in-out', padding: bToBExpanded ? '.5rem' : '0rem' }} colSpan={14}>
            <Collapse in={bToBExpanded}>
              <Table size='small' component={Paper}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Job
                      </TableCell>
                      {/* <TableCell align='right'>Visit #</TableCell> */}
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Customer
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Scheduled Arrival{' '}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Actual Arrival{' '}
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Hours scheduled
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Hours worked
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Hourly pay
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right'>
                        Total payout
                      </TableCell>
                      <TableCell sx={{ fontSize: '12px' }} align='right' />
                    </TableRow>
                  </TableHead>
                  <TableBody>{bToBWorkLogs.length > 0 && <TimeCardBToBJobsRow timeCard={timeCard} />}</TableBody>
                </Table>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
