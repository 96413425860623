import React, { useEffect, useContext, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { GlobalContext } from 'GlobalStore'
import Button from '@mui/material/Button'
import { UserContext } from 'UserStore'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { API_COMMAND_HEADERS, Dollars, downloadStringAsFile, keysToCamel } from 'tools'
import TextField from '@mui/material/TextField'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ManualCompensations from '../ManualCompensations'
import BToBPayouts from '../BToBPayouts'
import Reimbursements from '../Reimbursements'
import { RefetchRegistry } from 'RefetchRegistry'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Box, Chip, CircularProgress, Drawer, Grid, Snackbar } from '@mui/material'
import {
  ContractorQuotesPayoutRow,
  ContractorQuotesSumRow,
  ContractorQuotesTableHeadRow,
} from '../ResidentialContractor/ResidentialContractor'
import {
  ArrowDropDownRounded,
  ArrowRightRounded,
  CheckCircleOutlineRounded,
  ExpandMore,
  KeyboardArrowRightRounded,
  LaunchRounded,
} from '@mui/icons-material'
import { TimeCardPayoutRow, TimeCardSumRow, TimeCardTableHeadRow } from '../TimeCards/TimeCards'
import {
  ContractorBToBInvoicePayoutRow,
  ContractorBToBInvoiceSumRow,
  ContractorBToBInvoiceTableHeadRow,
} from '../B2BContractors/BToBContractorsV2'
import { EmployeeInvoiceHeadRow, EmployeeInvoicePayoutRow, EmployeeInvoicesSumRow } from '../B2BEmployees/BToBEmployees'
import PaidTimeOff from '../PaidTimeOff'
import UnpaidTimeOff from '../UnpaidTimeOff'
import CommissionChargeBacks from '../CommissionChargeBacks'
import { calculatePayoutData } from 'payrollCalculator'
import SickTimeOff from '../SickTimeOff'
import { PayoutDataPopover } from '../components/PayoutDataPopover'

const JOBS_QUERY = gql`
  query jobs($quoteId: ID!) {
    jobs(quoteId: $quoteId) {
      startDatetime
    }
  }
`

const PAYOUTS_QUERY = gql`
  query payouts($hasPayrollId: Boolean) {
    payouts(hasPayrollId: $hasPayrollId) {
      id
      firstName
      lastName
      businessName
      contractorIsBusiness
      technicianId
      quotePayouts
      quotePayoutsSum
      loanDeductionAmount
      otherLoanDeductionAmount
      createdAt
      einLastFour
      ssnLastFour
      gustoWageAmount
      finalCheckAmount
      note
      payrollId
      quoteIds
      timeCardsSum
      totalPaidTimeOffHours
      totalUnpaidTimeOffHours
      totalSickTimeOffHours
      minimumHourlyPayAdjustment
      overtimePayAdjustment
      deleted
      commissionChargeBacksSum
      commissionChargeBacks {
        createdAt
        id
        amount
        note
        quoteId
        worklog {
          id
          startedAt
          amount
          commissionType
          completedAt
          job {
            id
            startDatetime
            endDatetime
            isActive
          }
        }
      }
      timeCards {
        id
        timeCardDate
        createdAt
        deletedAt
        acceptedAt
        workHours
        paidTravelHours
        unpaidTravelHours
        jobCount
        payoutData
        technicianId
        payrollNotesCount
        childrenPayrollNotesCount
        quoteGrandTotals
        techChargesTotals
        quotes {
          id
          quoteGrandTotal
          tipSum
          ccFeeSum
          totalTransactionAmount
          laborSumAfterAllDiscounts
          technicianChargesSum
          paymentStatus
          fullyPaidAt
          payoutIds
          payrollStatuses
          payrollNotesCount
          technician {
            id
            firstName
            lastName
            hourlyRate
            commissionAmount
            commissionPercent
            loanWeeklyDeduction
            isEmployee
            state
            loanBalance
            quoteCommissionStructure
            techChargesCommissionPercent
          }
          workLogs {
            id
            createdAt
            commissionType
            startedAt
            completedAt
            type
            isPaid
            jobId
            travelDistance
            totalHours
            payoutData
            selectedForCommissionPayment
            hourlyType
            quoteId
            amount
            technician {
              id
              firstName
              lastName
              hourlyRate
              commissionAmount
              commissionPercent
              loanWeeklyDeduction
              isEmployee
              loanBalance
              quoteCommissionStructure
              techChargesCommissionPercent
              state
            }
            job {
              id
              scheduledLength
              isActive
            }
          }
        }
        technician {
          id
          firstName
          lastName
          hourlyRate
          commissionAmount
          commissionPercent
          loanWeeklyDeduction
          isEmployee
          state
          loanBalance
          quoteCommissionStructure
          techChargesCommissionPercent
        }
        bToBJobsLogs {
          id
          createdAt
          startedAt
          completedAt
          type
          isPaid
          jobId
          bToBJobId
          travelDistance
          totalHours
          commissionType
          selectedForCommissionPayment
          hourlyType
          bToBJob {
            id
            scheduledLength
          }
        }
        travelLogs {
          id
          createdAt
          startedAt
          completedAt
          type
          isPaid
          jobId
          bToBJobId
          travelDistance
          totalHours
          technician {
            hourlyRate
            commissionAmount
            loanBalance
            loanWeeklyDeduction
          }
        }
        workLogs {
          id
          createdAt
          startedAt
          completedAt
          type
          isPaid
          jobId
          travelDistance
          totalHours
          amount
          payoutData
          selectedForCommissionPayment
          commissionType
          hourlyType
          quoteId
          technician {
            id
            firstName
            lastName
            hourlyRate
            commissionAmount
            commissionPercent
            isEmployee
            state
            loanBalance
            otherLoanBalance
            loanWeeklyDeduction
            otherLoanWeeklyDeduction
            quoteCommissionStructure
            techChargesCommissionPercent
          }
          job {
            id
            scheduledLength
            isActive
          }
          quote {
            id
            quoteGrandTotal
            tipSum
            ccFeeSum
            totalTransactionAmount
            laborSumAfterAllDiscounts
            technicianChargesSum
            paymentStatus
            fullyPaidAt
            payoutIds
            payrollStatuses
            payrollNotesCount
            technician {
              id
              firstName
              lastName
              hourlyRate
              commissionAmount
              commissionPercent
              isEmployee
              state
              loanBalance
              otherLoanBalance
              loanWeeklyDeduction
              otherLoanWeeklyDeduction
              quoteCommissionStructure
              techChargesCommissionPercent
            }
            workLogs {
              id
              hourlyType
              createdAt
              startedAt
              completedAt
              type
              commissionType
              amount
              isPaid
              jobId
              travelDistance
              totalHours
              quoteId
              payoutData
              selectedForCommissionPayment
              technician {
                id
                firstName
                lastName
                hourlyRate
                commissionAmount
                commissionPercent
                isEmployee
                loanWeeklyDeduction
                state
                loanBalance
                quoteCommissionStructure
                techChargesCommissionPercent
              }
              job {
                id
                scheduledLength
                isActive
              }
            }
          }
        }
      }
      technician {
        contractorIsBusiness
        isEmployee
        ssn
        ein
        businessName
        firstName
        lastName
        commissionAmount
        commissionPercent
        loanBalance
        otherLoanBalance
        loanWeeklyDeduction
        otherLoanWeeklyDeduction
        hourlyRate
        enableB2bSquareFee
        state
        quoteCommissionStructure
        bToBInvoiceCommissionStructure
        techChargesCommissionPercent
      }
      reimbursements {
        id
        createdByType
        createdById
        createdAt
        amount
        photoUrls
        payoutId
        note
      }
      reimbursementsSum
      sickTimeOff {
        id
        deletedAt
        totalHours
        note
        userId
        timeOffDate
      }
      paidTimeOff {
        id
        deletedAt
        totalHours
        note
        userId
        timeOffDate
      }
      unpaidTimeOff {
        id
        deletedAt
        totalHours
        note
        userId
        timeOffDate
      }
      manualCompensations {
        id
        deletedAt
        amount
        note
        userId
        quote {
          id
          markedInsuranceAt
          lead {
            id
            name
          }
        }
      }
      quotes {
        id
        quoteGrandTotal
        tipSum
        ccFeeSum
        totalTransactionAmount
        laborSumAfterAllDiscounts
        technicianChargesSum
        paymentStatus
        fullyPaidAt
        payoutIds
        payrollStatuses
        payoutBonus
        commissionPercentOverride
        technician {
          id
          firstName
          lastName
          hourlyRate
          commissionAmount
          commissionPercent
          isEmployee
          loanBalance
          loanWeeklyDeduction
          state
          quoteCommissionStructure
          techChargesCommissionPercent
        }
        workLogs {
          id
          hourlyType
          createdAt
          startedAt
          completedAt
          type
          isPaid
          jobId
          amount
          travelDistance
          totalHours
          quoteId
          payoutData
          commissionType
          selectedForCommissionPayment
          technician {
            id
            firstName
            lastName
            hourlyRate
            commissionAmount
            commissionPercent
            isEmployee
            loanBalance
            loanWeeklyDeduction
            state
            quoteCommissionStructure
            techChargesCommissionPercent
          }
          job {
            id
            scheduledLength
            isActive
            quote {
              id
              quoteGrandTotal
              tipSum
              ccFeeSum
              totalTransactionAmount
              laborSumAfterAllDiscounts
              technicianChargesSum
              paymentStatus
              fullyPaidAt
              payoutIds
              payrollStatuses
              payrollNotesCount
              commissionPercentOverride
              technician {
                id
                commissionAmount
                commissionPercent
                isEmployee
                loanBalance
                loanWeeklyDeduction
                techChargesCommissionPercent
              }
            }
          }
        }
      }
      manualCompensationsSum
      bToBPayouts {
        id
        deletedAt
        amount
        note
        userId
        photoUrls
      }
      bToBPayoutsSum
      bToBInvoices {
        id
        createdAt
        completedAt
        acceptedAt
        make
        model
        year
        grandTotal
        payoutData
        commissionPercent
        lineItemsCommissionSum
        lineItemsPointsSum
        lineItems
        invoiceNumber
        payoutOverrideTotal
        dealer {
          businessName
        }
      }
      bToBInvoicesSum
    }
  }
`

const StartNewPayout = ({ existingPendingPayouts }) => {
  const [global] = useContext(GlobalContext)
  const [user] = useContext(UserContext)
  const [isOpen, setIsOpen] = React.useState(false)
  const [techId, setTechId] = useState(null)

  const handleClickCreate = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/create_empty_payout`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        technician_id: techId,
        user_id: user.id,
      }),
    })
      .then(res => {
        if (res.ok) {
          setIsOpen(false)
          setTechId(null)
        } else {
          throw new Error('Error')
        }
      })
      .catch(error => alert(error))
  }

  const handleSelectedTechnician = event => {
    setTechId(event.target.value)
  }

  const techsForSelect = global.technicians
    .filter(tech => !existingPendingPayouts.map(payout => payout.technicianId).includes(tech.id))
    .map(tech => ({ name: tech.name, id: tech.id }))
    .sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1))

  const disabled = techId === null

  return (
    <>
      <Button variant='outlined' color='primary' onClick={() => setIsOpen(true)}>
        Create a Technician Payout
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Select a Technician</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will create an empty Payout. As the technician completes Work Orders you can add them to the payout
            until the payout gets claimed by a Payroll.
          </DialogContentText>

          <FormControl sx={{ mt: 2 }}>
            <InputLabel>Technician</InputLabel>
            <Select
              style={{ minWidth: 120 }}
              value={techId || ''}
              label='Technician'
              onChange={handleSelectedTechnician}
            >
              {techsForSelect.map(tech => (
                <MenuItem key={tech.id} value={tech.id}>
                  {tech.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button disabled={disabled} onClick={handleClickCreate} color='primary'>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const LastJobDateOfQuoteId = ({ quoteId }) => {
  const { loading, error, data } = useQuery(JOBS_QUERY, {
    variables: { quoteId },
  })
  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const jobs = data.jobs

  const latestJob = jobs
    .slice()
    .sort((a, b) => (DateTime.fromISO(a.startDatetime) < DateTime.fromISO(b.startDatetime) ? 1 : -1))[0]

  return <>last job: {DateTime.fromISO(latestJob?.startDatetime).toISODate()}</>
}

const PendingPayouts = () => {
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [waiting, setWaiting] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)

  const { loading, error, data, refetch } = useQuery(PAYOUTS_QUERY, {
    variables: { hasPayrollId: false },
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  useEffect(() => {
    const key = registerUpdateRefetch({
      types: ['BToBInvoice', 'Technician', 'Payout', 'ManualCompensation', 'Reimbursement', 'PaidTimeOff'],
      refetch,
    })
    return () => unregisterUpdateRefetch(key)
  }, [])

  useEffect(() => {
    const key = registerRefetch({ types: ['Payout'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  if (loading) return <>loading</>
  if (error) return <>error</>
  if (!data) return <>wait</>

  const payouts = keysToCamel(data.payouts)

  const handleClickGeneratePayroll = () => {
    setRequestLoading(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/generate_new_payroll`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
    })
      .then(res => {
        if (res.ok) {
          console.log('ok')
        } else {
          throw new Error('Error')
        }
      })
      .then(() => {
        setRequestLoading(false)
        setRequestSuccess('Payroll created')
        refetch()
      })
      .catch(err => alert(err))
  }

  const handleClickDownloadAsCsv = () => {
    setRequestLoading(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/download_current_payouts_as_csv`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
    })
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error('Error')
        }
      })
      .then(data => {
        downloadStringAsFile({ string: data.csv_string, fileName: data.csv_filename })
      })
      .then(() => {
        setRequestLoading(false)
        setRequestSuccess('File Downloaded')
        refetch()
      })
      .catch(err => alert(err))
  }

  const employeePayouts = payouts
    .filter(payout => payout.technician.isEmployee)
    .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
  const contractorPayouts = payouts
    .filter(payout => !payout.technician.isEmployee)
    .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))

  return (
    <Box sx={{ pb: '2rem' }}>
      <Snackbar
        onClose={() => setRequestSuccess(false)}
        open={!!requestSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5', mr: '1rem', height: '20px', width: '20px' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' },
          mb: '3rem',
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutlineRounded /> {requestSuccess}
          </Box>
        }
      />
      <Snackbar
        onClose={() => setRequestLoading(false)}
        open={requestLoading}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' },
          mb: '3rem',
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={'1rem'} sx={{ mr: '1rem' }} /> Loading...{' '}
          </Box>
        }
      />

      <Button
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'fixed',
          bottom: '1rem',
          left: '1rem',
          zIndex: 1199,
          '& svg': {
            ml: '.5rem',
          },
        }}
        variant='contained'
        onClick={() => setDrawerOpen(true)}
      >
        View additional compensations <LaunchRounded />
      </Button>

      <Button
        sx={{ position: 'fixed', bottom: '1rem', right: '1rem', '& svg': { ml: '.5rem' }, zIndex: 1199 }}
        disabled={waiting}
        variant='contained'
        color='primary'
        onClick={() => window.confirm('are you sure you want to move all to payroll?') && handleClickGeneratePayroll()}
      >
        move all to Payroll <KeyboardArrowRightRounded />
      </Button>

      <Box>
        <Grid container>
          <Grid item xs={12}>
            {[
              { label: 'Employees', list: employeePayouts, isEmployee: true },
              { label: 'Contractors', list: contractorPayouts, isEmployee: false },
            ].map(e => {
              return (
                <Box
                  sx={{
                    '& .MuiButtonBase-root': {
                      minHeight: '30px',
                      padding: '0rem .75rem',
                    },
                    '& .MuiAccordionSummary-content': {
                      margin: '0px',
                    },
                    '& .MuiAccordionDetails-root': {
                      padding: '.5rem',
                      background: '#ddd',
                    },
                  }}
                >
                  <Accordion
                    elevation={0}
                    disablePadding
                    disableGutters
                    defaultExpanded
                    sx={{
                      border: '1px solid #ddd',
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <b>{e.label}</b>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper} sx={{ mb: '.5rem' }}>
                        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Tech
                              </TableCell>
                              {e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  Hourly
                                </TableCell>
                              )}
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Quotes
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Reimbursements
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Manual compensations
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                B2B Payouts
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                B2B Invoices
                              </TableCell>
                              {!e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  Equip. loan deduction
                                </TableCell>
                              )}
                              {!e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  Equip. loan balance
                                </TableCell>
                              )}
                              {!e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  Other loan deduction
                                </TableCell>
                              )}
                              {!e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  Other loan balance
                                </TableCell>
                              )}
                              {e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  PTO (hours)
                                </TableCell>
                              )}
                              {e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  STO (hours)
                                </TableCell>
                              )}
                              {e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  UTO (hours)
                                </TableCell>
                              )}
                              {e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  Hourly pay adj.
                                </TableCell>
                              )}
                              {e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  OT pay
                                </TableCell>
                              )}

                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Charge backs
                              </TableCell>

                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Final check amount
                              </TableCell>
                              {e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  Revenue
                                </TableCell>
                              )}
                              {e.isEmployee && (
                                <TableCell sx={{ fontSize: '12px' }} align='right'>
                                  TC%
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {e.list
                              .filter(payout => !payout.payrollId)
                              .filter(payout => !payout.deleted)
                              .map((payout, index) => (
                                <PayoutRows
                                  setRequestLoading={setRequestLoading}
                                  setRequestSuccess={setRequestSuccess}
                                  index={index}
                                  key={payout.id}
                                  payout={payout}
                                  refetch={refetch}
                                />
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )
            })}
          </Grid>
        </Grid>
      </Box>
      <Drawer
        PaperProps={{ sx: { overflowY: 'auto', maxWidth: '90%' } }}
        open={drawerOpen}
        anchor='right'
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ display: 'flex', padding: '.5rem', justifyContent: 'space-between' }}>
          <StartNewPayout existingPendingPayouts={payouts} />
          <Button onClick={handleClickDownloadAsCsv}>download as csv</Button>
        </Box>
        <ManualCompensations refetchAll={refetch} />
        <Reimbursements refetchAll={refetch} />
        <BToBPayouts refetchAll={refetch} />
        <CommissionChargeBacks refetchAll={refetch} />
        <PaidTimeOff refetchAll={refetch} />
        <UnpaidTimeOff refetchAll={refetch} />
        <SickTimeOff refetchAll={refetch} />
      </Drawer>
    </Box>
  )
}

const PayoutRows = ({ payout, refetch, index, setRequestLoading, setRequestSuccess }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [expanded, setExpanded] = useState(false)
  const [user] = useContext(UserContext)
  const [waiting, setWaiting] = useState(false)
  const [payoutDataOpen, setPayoutDataOpen] = useState(false)

  const NoteDialog = () => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [note, setNote] = useState(payout.note || '')
    const handleCloseDialog = () => setDialogIsOpen(false)

    const handleNoteChanged = event => setNote(event.target.value)

    const handleClickedSaveNote = () => {
      setRequestLoading(true)
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/update_payout_note`, {
        method: 'POST',
        headers: API_COMMAND_HEADERS,
        body: JSON.stringify({
          payoutId: payout.id,
          userId: user.id,
          note,
        }),
      })
        .then(res => {
          if (res.ok) {
            setRequestLoading(true)
            setRequestSuccess(true)
            setDialogIsOpen(false)
          } else {
            throw new Error('Error')
          }
        })
        .catch(error => alert(error))
    }

    return (
      <>
        <span onClick={() => setDialogIsOpen(true)} style={{ cursor: 'pointer' }}>
          [edit{dialogIsOpen ? 'ing' : ''}]
        </span>
        <Dialog open={dialogIsOpen} onClose={handleCloseDialog}>
          <DialogTitle>Edit the Payout Note</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Set or edit a single Note for the Payout; The technician can see this.
            </DialogContentText>
            <TextField
              required
              value={note}
              onChange={handleNoteChanged}
              autoFocus
              margin='dense'
              label='Note'
              multiline
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color='primary'>
              Close
            </Button>
            <Button onClick={handleClickedSaveNote} color='primary' autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const handleClickedDeletePayout = () => {
    if (
      payout.bToBInvoices?.length > 0 ||
      payout.quotes?.length > 0 ||
      payout.timeCarsd?.length > 0 ||
      payout.manualCompensations?.length > 0 ||
      payout.paidTimeOff?.length > 0 ||
      payout.unpaidTimeOff?.length > 0 ||
      payout.reimbursements?.length > 0
    ) {
      setRequestSuccess('Unable to delete. First unlink all attached items.')
      return false
    }

    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/delete_payout`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        payoutId: payout.id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Payout deleted')
        }, 500)
      )
  }

  const handleClickedUnlinkBToBPayout = id => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_b_to_b_payout_from_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        payoutId: payout.id,
        bToBPayoutId: id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('B2B Payout Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkCommissionChargeBack = id => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_commission_charge_back_from_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        payoutId: payout.id,
        commissionChargeBackId: id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Charge Back Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkBToBInvoice = id => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_b_to_b_invoice_from_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        bToBInvoiceId: id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('BToBInvoice Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkBToBInvoices = ({ btoBInvoiceIds }) => {
    setRequestLoading(true)

    new Promise((resolve, reject) => {
      btoBInvoiceIds.map((bToBInvoiceId, index) =>
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_b_to_b_invoice_from_payout`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            payoutId: payout.id,
            bToBInvoiceId: bToBInvoiceId,
            userId: user.id,
          }),
        })
      )

      setTimeout(() => resolve(), 500 * btoBInvoiceIds.length)
    })

      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('BToBInvoices Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkTimeCard = id => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_time_card_from_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        timeCardId: id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Time Card Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkTimeCards = ({ timeCardIds }) => {
    setRequestLoading(true)

    new Promise((resolve, reject) => {
      timeCardIds.map(timecardId =>
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_time_card_from_payout`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            payoutId: payout.id,
            timeCardId: timecardId,
            userId: user.id,
          }),
        })
      )

      setTimeout(() => resolve(), 500 * timeCardIds.length)
    })

      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Time Cards Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkReimbursement = reimbursementId => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_reimbursement_from_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        payoutId: payout.id,
        reimbursementId: reimbursementId,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Reimbursement Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkManualCompensation = manualCompensationId => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_manual_compensation_from_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        manualCompensationId: manualCompensationId,
        payoutId: payout.id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Manual Compensation Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkPaidTimeOff = paidTimeOffId => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_paid_time_off_from_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        paidTimeOffId: paidTimeOffId,
        payoutId: payout.id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('PTO Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkSickTimeOff = paidTimeOffId => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_sick_time_off_from_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        sickTimeOffId: paidTimeOffId,
        payoutId: payout.id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Sick Time Removed')
        }, 500)
      )
  }

  const handleClickedUnlinkUnpaidTimeOff = unpaidTimeOffId => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_unpaid_time_off_from_payout`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        unpaidTimeOffId: unpaidTimeOffId,
        payoutId: payout.id,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Unpaid Time Off Removed')
        }, 500)
      )
  }

  const handleClickDiscludeThisQuote = ({ quoteId }) => {
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/disclude_quote_from_payout`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        payoutId: payout.id,
        quoteId: quoteId,
        userId: user.id,
      }),
    })
      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Quote Removed')
        }, 500)
      )
  }

  const handleClickDiscludeQuotes = ({ quoteIds }) => {
    setRequestLoading(true)

    new Promise((resolve, reject) => {
      quoteIds.map(quoteId =>
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/disclude_quote_from_payout`, {
          method: 'POST',
          headers: API_COMMAND_HEADERS,
          body: JSON.stringify({
            payoutId: payout.id,
            quoteId: quoteId,
            userId: user.id,
          }),
        })
      )

      setTimeout(() => resolve(), 500 * quoteIds.length)
    })

      .then(res => {
        refetch()
      })
      .catch(err => alert(err))
      .finally(() =>
        setTimeout(() => {
          setRequestLoading(false)
          setRequestSuccess('Quotes Removed')
        }, 500)
      )
  }

  const contractorInvoices = payout.bToBInvoices.filter(
    i => i.payoutData?.payoutStructure === 'labor cost' || i.payoutData?.payoutStructure === 'contractor'
  )
  const employeeInvoices = payout.bToBInvoices.filter(
    i => i.payoutData?.payoutStructure === 'panel' || i.payoutData?.payoutStructure === 'employee'
  )

  const payoutCalculations = calculatePayoutData({
    reimbursements: payout.reimbursements,
    pto: payout.paidTimeOff,
    uto: payout.unpaidTimeOff,
    sto: payout.sickTimeOff,
    manualCompensations: payout.manualCompensations,
    chargeBacks: payout.commissionChargeBacks,
    timeCards: payout.timeCards,
    quotes: payout.quotes,
    bToBInvoices: payout.bToBInvoices,
    bToBPayouts: payout.bToBPayouts,
    technician: payout.technician,
    loanDeductionAmount: payout.loanDeductionAmount,
    otherLoanDeductionAmount: payout.otherLoanDeductionAmount,
  })

  const isEmployee = payout.technician.isEmployee

  return (
    <>
      {waiting && (
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111140',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            zIndex: 99,
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}

      <TableRow
        key={payout.id}
        sx={[
          {
            '&:last-child td, &:last-child th': { border: 0 },
            background: index % 2 === 0 ? '#fff' : '#f9f9f9',
            cursor: 'pointer !important',
          },
          expanded && {
            background: '#f2f6fa',
            borderTop: '2px solid #3f51b5 !important',
            borderLeft: '2px solid #3f51b5',
            borderRight: '2px solid #3f51b5',
          },
        ]}
        onClick={() => setExpanded(!expanded)}
      >
        <TableCell align='right'>
          <span
            style={{ position: 'absolute', left: '25px', cursor: 'pointer' }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? <ArrowDropDownRounded /> : <ArrowRightRounded />}
          </span>
          &nbsp;
          <Link target='_blank' to={`/technicians/${payout.technicianId}`}>
            {payout.firstName} {payout.lastName}
          </Link>
        </TableCell>
        {isEmployee && (
          <TableCell align='right'>
            <Dollars value={payoutCalculations.timeCardsSum + payoutCalculations.overtimePayAdjustment} />
          </TableCell>
        )}
        <TableCell align='right'>
          <Dollars value={payoutCalculations.quotePayoutsSum} />
        </TableCell>

        <TableCell align='right'>
          <Dollars value={payoutCalculations.reimbursementsSum} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={payoutCalculations.manualCompensationsSum} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={payoutCalculations.bToBPayoutsSum} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={payoutCalculations.bToBInvoicesSum} />
        </TableCell>

        {!isEmployee && (
          <TableCell align='right'>
            <b>
              <Dollars value={payoutCalculations.loanDeductionAmount} />
            </b>
          </TableCell>
        )}

        {!isEmployee && (
          <TableCell align='right'>
            <Dollars value={payout.technician.loanBalance} />
          </TableCell>
        )}

        {!isEmployee && (
          <TableCell align='right'>
            <Dollars value={payoutCalculations.otherLoanDeductionAmount} />
          </TableCell>
        )}

        {!isEmployee && (
          <TableCell align='right'>
            <Dollars value={payout.technician.otherLoanBalance} />
          </TableCell>
        )}

        {isEmployee && (
          <TableCell align='right'>
            <b>{payoutCalculations.totalPaidTimeOffHours}</b>
          </TableCell>
        )}
        {isEmployee && (
          <TableCell align='right'>
            <b>{payoutCalculations.totalSickTimeOffHours}</b>
          </TableCell>
        )}

        {isEmployee && (
          <TableCell align='right'>
            <b>{payoutCalculations.totalUnpaidTimeOffHours}</b>
          </TableCell>
        )}
        {isEmployee && (
          <TableCell align='right'>
            <b>
              <Dollars value={payoutCalculations.minimumHourlyPayAdjustment || 0} />
              <b style={{ fontSize: '11px' }}>
                ~{(payoutCalculations.minimumHourlyPayAdjustment / payout.technician.hourlyRate || 0).toFixed(2) || 0}
                hour(s)
              </b>
            </b>
          </TableCell>
        )}

        {isEmployee && (
          <TableCell align='right'>
            <b>
              <Dollars value={payoutCalculations.overtimePay || 0} />
            </b>
          </TableCell>
        )}

        <TableCell align='right'>
          <b>
            <Dollars value={-payoutCalculations.commissionChargeBacksSum} />
          </b>
        </TableCell>

        <TableCell align='right'>
          <b>
            <Dollars value={payoutCalculations.finalCheckAmount} />
          </b>
        </TableCell>

        {isEmployee && (
          <TableCell align='right'>
            <b>
              <Dollars value={payoutCalculations.invoiceAndQuoteLaborTotal} />
            </b>
          </TableCell>
        )}

        {isEmployee && (
          <TableCell align='right'>
            <b>
              {(payoutCalculations.payoutTotalOverLaborTotal &&
                (payoutCalculations.payoutTotalOverLaborTotal * 100).toFixed(2)) ||
                0}
              %
            </b>
          </TableCell>
        )}
      </TableRow>

      {expanded && (
        <TableRow
          sx={{
            backgroundColor: '#fff',
            borderLeft: '2px solid #3f51b5',
            borderRight: '2px solid #3f51b5',
            borderBottom: '2px solid #3f51b5',
            borderTop: 0,
            width: '100%',
            '& .MuiButtonBase-root': {
              minHeight: '30px',
              padding: '0rem .75rem',
              background: '#f2f6fa',
            },
            '& .MuiAccordionSummary-content': {
              margin: '0px',
            },
            '& .MuiAccordionDetails-root': {
              padding: '.5rem',
              background: '#ddd',
            },
            '& .MuiTable-root': {
              background: '#fff',
              borderRadius: '6px',
            },
            '& .totalNumber': {
              color: '#fff',
              borderRadius: '100px',
              fontSize: '10px',
              fontWeight: 800,
              height: '17px',
              width: '17px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#3f51b5',
              border: '1px solid #3f51b5',
              fontFamily: 'Arial',
              cursor: 'default',
              mr: '.5rem',
              lineHeight: 4,
            },
            '& .numItems-0': {
              opacity: '.20 !important',
              fontWeight: '400 !important',
              pointerEvents: 'none',
              background: '#aaa',
              alignItems: 'center',
              '& .totalNumber': {
                display: 'none',
              },
            },
          }}
        >
          <TableCell colSpan={16} sx={{ padding: '0rem' }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                padding: '.5rem',
                background: '#fff',
              }}
            >
              <Box>
                note:&nbsp;
                {payout.note && (
                  <span title={payout.note}>
                    {payout.note.substring(0, 15) + (payout.note.length > 15 ? '...' : '')}
                  </span>
                )}
                <NoteDialog payout={payout} />
              </Box>

              <Button
                variant='outlined'
                size='small'
                onClick={() => setPayoutDataOpen(!payoutDataOpen)}
                sx={{
                  marginLeft: 'auto',
                  background: 'transparent !important',
                  padding: '.125rem',
                  fontSize: '12px !important',
                  minWidth: '30px',
                  minHeight: '15px !important',
                  '&  .MuiButtonBase-root': {
                    padding: '.125rem',
                    minHeight: '15px !important',
                  },
                }}
              >
                view full calculations
              </Button>
              <Button
                color='error'
                variant='outlined'
                size='small'
                onClick={() => handleClickedDeletePayout(payout.id)}
                sx={{
                  cursor: 'pointer',
                  background: 'transparent !important',
                  ml: '1rem',
                  padding: '.125rem',
                  fontSize: '12px !important',
                  minWidth: '30px',
                  minHeight: '15px !important',
                  '&  .MuiButtonBase-root': {
                    padding: '.125rem',
                    minHeight: '15px !important',
                  },
                }}
              >
                delete this payout
              </Button>
            </Box>
            {payoutDataOpen && (
              <PayoutDataPopover
                payoutDataOpen={payoutDataOpen}
                setPayoutDataOpen={setPayoutDataOpen}
                data={payoutCalculations}
              />
            )}

            <Accordion disablePadding disableGutters>
              <AccordionSummary className={`numItems-${payout?.quotes?.length}`} expandIcon={<ExpandMore />}>
                <span className='totalNumber'>{payout?.quotes?.length}</span>
                <b>Quote payouts - ${payoutCalculations.quotePayoutsSum.toFixed(2)}</b>
                {payout.quotes.length > 0 && payout.technician.quoteCommissionStructure === 'labor cost' && (
                  <Button
                    variant='outlined'
                    color='error'
                    sx={{
                      padding: '.125rem',
                      fontSize: '12px !important',
                      minWidth: '30px',
                      minHeight: '15px !important',
                      ml: '.5rem',
                      '&  .MuiButtonBase-root': {
                        padding: '.125rem',
                        minHeight: '15px !important',
                      },
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      handleClickDiscludeQuotes({ quoteIds: payout.quoteIds })
                    }}
                  >
                    remove all
                  </Button>
                )}
              </AccordionSummary>
              {payout.quotes.length > 0 && (
                <AccordionDetails>
                  <Table size='small' component={Paper}>
                    <ContractorQuotesTableHeadRow
                      showDiscludeButton={payout.technician.quoteCommissionStructure === 'hourly' ? false : true}
                      hideCheckbox
                      isEmployee={payout.technician.quoteCommissionStructure === 'hourly'}
                    />
                    {payout.quotes
                      .map(qp => keysToCamel(qp))
                      .map((quote, index) => {
                        return (
                          <ContractorQuotesPayoutRow
                            key={index}
                            quote={quote}
                            index={index}
                            hideCheckbox
                            handleClickDiscludeThisQuote={
                              payout.technician.quoteCommissionStructure === 'hourly'
                                ? null
                                : () => handleClickDiscludeThisQuote({ quoteId: quote.id })
                            }
                            isEmployee={payout.technician.quoteCommissionStructure === 'hourly'}
                          />
                        )
                      })}
                    <ContractorQuotesSumRow
                      showDiscludeButton={payout.technician.quoteCommissionStructure === 'hourly' ? false : true}
                      hideCheckbox
                      quotes={payout.quotes}
                      isEmployee={payout.technician.quoteCommissionStructure === 'hourly'}
                    />
                  </Table>
                </AccordionDetails>
              )}
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.timeCards?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.timeCards?.length}</span>

                <b>Time Card Payouts - ${payoutCalculations.timeCardsSum.toFixed(2)}</b>
                {payout.timeCards.length > 0 && (
                  <Button
                    color='error'
                    variant='outlined'
                    sx={{
                      padding: '.125rem',
                      fontSize: '12px !important',
                      minWidth: '30px',
                      minHeight: '15px !important',
                      ml: '.5rem',
                      '&  .MuiButtonBase-root': {
                        padding: '.125rem',
                        minHeight: '15px !important',
                      },
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      handleClickedUnlinkTimeCards({ timeCardIds: payout.timeCards.map(b => b.id) })
                    }}
                  >
                    remove all
                  </Button>
                )}
              </AccordionSummary>
              {payout.timeCards.length > 0 && (
                <AccordionDetails>
                  <Table size='small' component={Paper}>
                    <TimeCardTableHeadRow showDiscludeButton hideCheckbox />
                    {payout.timeCards
                      .map(qp => keysToCamel(qp))
                      .map((timeCard, index) => {
                        return (
                          <TimeCardPayoutRow
                            hideCheckbox
                            expandJobsArrow
                            key={index}
                            timeCard={timeCard}
                            index={index}
                            handleClickDisclude={() => handleClickedUnlinkTimeCard(timeCard.id)}
                          />
                        )
                      })}
                    <TimeCardSumRow showDiscludeButton hideCheckbox timeCards={payout.timeCards} />
                  </Table>
                </AccordionDetails>
              )}
            </Accordion>

            {payout.bToBInvoices.length > 0 && (
              <Accordion disablePadding disableGutters>
                <AccordionSummary className={`numItems-${payout?.bToBInvoices?.length}`} expandIcon={<ExpandMore />}>
                  <span className='totalNumber'>{payout?.bToBInvoices?.length}</span>
                  <b>B2B Invoices - ${payoutCalculations.bToBInvoicesSum.toFixed(2)}</b>
                  {payout.bToBInvoices.length > 0 && (
                    <Button
                      variant='outlined'
                      color='error'
                      sx={{
                        padding: '.125rem',
                        fontSize: '12px !important',
                        minWidth: '30px',
                        minHeight: '15px !important',
                        ml: '.5rem',
                        '&  .MuiButtonBase-root': {
                          padding: '.125rem',
                          minHeight: '15px !important',
                        },
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        handleClickedUnlinkBToBInvoices({ btoBInvoiceIds: payout.bToBInvoices.map(b => b.id) })
                      }}
                    >
                      remove all
                    </Button>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {contractorInvoices.length > 0 && (
                    <Table size='small' component={Paper}>
                      <ContractorBToBInvoiceTableHeadRow showDiscludeButton hideCheckbox />
                      {contractorInvoices
                        .map(qp => keysToCamel(qp))
                        .map((invoice, index) => {
                          return (
                            <ContractorBToBInvoicePayoutRow
                              key={index}
                              invoice={invoice}
                              index={index}
                              hideCheckbox
                              handleClickDisclude={() => handleClickedUnlinkBToBInvoice(invoice.id)}
                            />
                          )
                        })}
                      <ContractorBToBInvoiceSumRow showDiscludeButton hideCheckbox invoices={contractorInvoices} />
                    </Table>
                  )}

                  {employeeInvoices.length > 0 && (
                    <Table size='small'>
                      <EmployeeInvoiceHeadRow showDiscludeButton hideCheckbox />
                      {employeeInvoices
                        .map(qp => keysToCamel(qp))
                        .map((invoice, index) => {
                          return (
                            <EmployeeInvoicePayoutRow
                              key={index}
                              invoice={invoice}
                              index={index}
                              handleClickDisclude={() => handleClickedUnlinkBToBInvoice(invoice.id)}
                            />
                          )
                        })}
                      <EmployeeInvoicesSumRow showDiscludeButton hideCheckbox invoices={employeeInvoices} />
                    </Table>
                  )}
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.reimbursements?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.reimbursements?.length}</span>
                <b>
                  Reimbursements - <Dollars value={payoutCalculations.reimbursementsSum} />
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.reimbursements.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.reimbursements.map(reimbursement => (
                          <li key={reimbursement.id} style={{ padding: '0.5em' }}>
                            <span title={reimbursement.note}>
                              {reimbursement.note.substring(0, 15) + (reimbursement.note.length > 15 ? '...' : '')}
                            </span>
                            :&nbsp;
                            <Dollars value={reimbursement.amount} />
                            &nbsp;
                            <span
                              style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => handleClickedUnlinkReimbursement(reimbursement.id)}
                            >
                              <b>[unlink]</b>
                            </span>
                            {reimbursement.photoUrls && reimbursement.photoUrls.length > 0 && (
                              <>
                                <br />
                                photos <br />
                              </>
                            )}
                            {reimbursement.photoUrls &&
                              reimbursement.photoUrls.map(photoUrl => (
                                <img
                                  key={photoUrl}
                                  title='open in new window'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => window.open(photoUrl)}
                                  width={50}
                                  src={photoUrl}
                                />
                              ))}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.manualCompensations?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.manualCompensations?.length}</span>

                <b>
                  Manual Compensations - <Dollars value={payoutCalculations.manualCompensationsSum} />
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.manualCompensations.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.manualCompensations.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Link target='_blank' to={`/leads/${comp.quote.lead.id}/quotes/${comp.quote.id}`}>
                              quote
                            </Link>
                            &nbsp;
                            <span title={comp.note}>{comp.note}</span> :&nbsp;
                            <Dollars value={comp.amount} />
                            &nbsp;
                            <span
                              style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => handleClickedUnlinkManualCompensation(comp.id)}
                            >
                              <b>[unlink]</b>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.paidTimeOff?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.paidTimeOff?.length}</span>

                <b>
                  PTO - <b>{payoutCalculations.totalPaidTimeOffHours} hour(s)</b>
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.paidTimeOff.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.paidTimeOff.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Chip
                              sx={{ mr: '.25rem' }}
                              size='small'
                              label={DateTime.fromISO(comp.timeOffDate).toFormat('kkkk-LL-dd')}
                            />
                            <span title={comp.note}>{comp.note}</span> :&nbsp;
                            {comp.totalHours} hour(s) &nbsp;
                            <span
                              style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => handleClickedUnlinkPaidTimeOff(comp.id)}
                            >
                              <b>[unlink]</b>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.sickTimeOff?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.sickTimeOff?.length}</span>

                <b>
                  Sick Time - <b>{payoutCalculations.totalSickTimeOffHours} hour(s)</b>
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.sickTimeOff.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.sickTimeOff.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Chip
                              sx={{ mr: '.25rem' }}
                              size='small'
                              label={DateTime.fromISO(comp.timeOffDate).toFormat('kkkk-LL-dd')}
                            />
                            <span title={comp.note}>{comp.note}</span> :&nbsp;
                            {comp.totalHours} hour(s) &nbsp;
                            <span
                              style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => handleClickedUnlinkSickTimeOff(comp.id)}
                            >
                              <b>[unlink]</b>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.unpaidTimeOff?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.unpaidTimeOff?.length}</span>

                <b>
                  Unpaid Time Off - <b>{payoutCalculations.totalUnpaidTimeOffHours || 0} hour(s)</b>
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.unpaidTimeOff.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.unpaidTimeOff.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Chip
                              sx={{ mr: '.25rem' }}
                              size='small'
                              label={DateTime.fromISO(comp.timeOffDate).toFormat('kkkk-LL-dd')}
                            />
                            <span title={comp.note}>{comp.note}</span> :&nbsp;
                            {comp.totalHours} hour(s) &nbsp;
                            <span
                              style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => handleClickedUnlinkUnpaidTimeOff(comp.id)}
                            >
                              <b>[unlink]</b>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.bToBPayouts?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <span className='totalNumber'>{payout?.bToBPayouts?.length}</span>

                <b>
                  B2B Payouts -
                  <b>
                    <Dollars value={payoutCalculations.bToBPayoutsSum} />
                  </b>
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.bToBPayouts.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.bToBPayouts.map(bToBPayout => (
                          <li key={bToBPayout.id} style={{ padding: '0.5em' }}>
                            <span title={bToBPayout.note}>
                              {bToBPayout.note.substring(0, 15) + (bToBPayout.note.length > 15 ? '...' : '')}
                            </span>
                            :&nbsp;
                            <Dollars value={bToBPayout.amount} />
                            &nbsp;
                            <span
                              style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => handleClickedUnlinkBToBPayout(bToBPayout.id)}
                            >
                              <b>[unlink]</b>
                            </span>
                            {bToBPayout.photoUrls && bToBPayout.photoUrls.length > 0 && (
                              <>
                                <br />
                                photos <br />
                              </>
                            )}
                            {bToBPayout.photoUrls &&
                              bToBPayout.photoUrls.map(photoUrl => (
                                <img
                                  key={photoUrl}
                                  title='open in new window'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => window.open(photoUrl)}
                                  width={50}
                                  src={photoUrl}
                                />
                              ))}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters disablePadding>
              <AccordionSummary
                className={`numItems-${payout?.commissionChargeBacks?.length}`}
                disablePadding
                expandIcon={<ExpandMore />}
              >
                <b>
                  Charge backs <Dollars value={-payoutCalculations.commissionChargeBacksSum} />
                </b>
              </AccordionSummary>
              <AccordionDetails>
                <Table size='small' component={Paper}>
                  {payout.commissionChargeBacks.length > 0 && (
                    <div>
                      <ul style={{ padding: '0 0 0 2em', margin: 0 }}>
                        {payout.commissionChargeBacks.map(comp => (
                          <li key={comp.id} style={{ padding: '0.5em' }}>
                            <Chip
                              sx={{ mr: '.25rem' }}
                              size='small'
                              label={DateTime.fromISO(comp.createdAt).toFormat('kkkk-LL-dd')}
                            /> &nbsp;<Link to={`/quotes/${comp.quoteId}`}>see quote</Link>:&nbsp;
                            <span title={comp.note}>{comp.note}</span>&nbsp;
                            <span
                              style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => handleClickedUnlinkCommissionChargeBack(comp.id)}
                            >
                              <b>[unlink]</b>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default PendingPayouts
