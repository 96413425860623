import {
  Badge,
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Skeleton,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { UserContext } from 'UserStore'
import { Grid, Button, Typography, Card } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { API_COMMAND_HEADERS, Dollars, keysToCamel } from 'tools'
import { DateTime } from 'luxon'
import { ConfigurationContext } from '../Configure/Configure'
import {
  CheckCircleOutlineRounded,
  ChevronRightRounded,
  KeyboardArrowRightRounded,
  MoreVertRounded,
} from '@mui/icons-material'
import { NotesDialog } from '../TimeCards/components/NotesDialog'
import { calculateQuotePayoutData, calculatePayoutData } from 'payrollCalculator'
import { useTechnicianChargeBacks } from 'hooks/useTechnicianChargeBacks'
import CommissionChargeBacks from '../TimeCards/components/CommissionChargeBacks'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { QuoteType } from 'types/types'
import { PayoutDataPopover } from '../components/PayoutDataPopover'

const PAYOUT_PREVIEWS_QUERY = gql`
  query Quotes($readyForPayout: Boolean, $employeesOnly: Boolean) {
    quotes(readyForPayout: $readyForPayout, employeesOnly: $employeesOnly) {
      id
      payrollStatuses
      tipSum
      ccFeeSum
      technicianChargesSum
      laborSumAfterAllDiscounts
      totalTransactionAmount
      forceIncludeInPayroll
      commissionPercentOverride
      payoutBonus
      assignedTechnicianId
      technician {
        commissionPercent
        quoteCommissionStructure
        techChargesCommissionPercent
        isEmployee
      }
    }
  }
`

const JOBS_QUERY = gql`
  query jobs($quoteId: ID!, $includeCanceled: Boolean) {
    jobs(quoteId: $quoteId, includeCanceled: $includeCanceled) {
      id
      startDatetime
      quote {
        id
        payrollStatuses
        tipSum
        ccFeeSum
        technicianChargesSum
        laborSumAfterAllDiscounts
        totalTransactionAmount
        forceIncludeInPayroll
        commissionPercentOverride
        payoutBonus
        assignedTechnicianId
        payrollStatuses
        payrollNotesCount
        technician {
          commissionPercent
          quoteCommissionStructure
          techChargesCommissionPercent
        }
      }
      lead {
        id
        make
        model
        year
        customer {
          id
          firstName
          lastName
        }
      }
    }
  }
`

const TECHNICIAN_QUERY = gql`
  query technician($id: ID!, $cached: Boolean!) {
    technician(id: $id, cached: $cached) {
      id
      contractorIsBusiness
      ssn
      ein
      businessName
      firstName
      lastName
      loanBalance
      otherLoanBalance
      loanWeeklyDeduction
      otherLoanWeeklyDeduction
      quoteCommissionStructure
      commissionPercent
      techChargesCommissionPercent
      isEmployee
    }
  }
`

export const ContractorQuotesPayoutRow = ({
  quote,
  index,
  quoteIsSelected,
  selectInvoice,
  handleClickDiscludeThisQuote,
  hideCheckbox,
  actionButtons,
  isEmployee,
  refetch,
}: any) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [popoverItemRef, setPopoverItemRef] = useState<any>(null)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)

  const { loading, error, data } = useQuery(JOBS_QUERY, {
    variables: { quoteId: quote.id, includeCanceled: true },
  })

  if (loading) return <SkeletonRow />
  if (error) return <div>Error!</div>

  const jobs = data.jobs || []

  const latestJob = jobs
    .slice()
    .sort((a: any, b: any) => (DateTime.fromISO(a.startDatetime) < DateTime.fromISO(b.startDatetime) ? 1 : -1))[0]

  const payrollNotesCount = latestJob?.quote?.payrollNotesCount

  const quotePayoutData: any = calculateQuotePayoutData({ quote, technician: quote.technician })

  const handleClickForceRemove = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_quote_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        quoteId: quote.id,
        updatedAttributes: {
          force_exclude_from_payroll: true,
          force_include_in_payroll: false,
        },
      }),
    }).then(res => refetch() || window.alert('Error'))

  return (
    <>
      <TableRow sx={{ background: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
        {!hideCheckbox && (
          <TableCell sx={{ maxWidth: '60px', padding: '0rem .5rem' }}>
            <Checkbox
              sx={{
                padding: '0px',
                '& svg': {
                  height: '15px',
                  width: '15px',
                },
              }}
              onClick={selectInvoice}
              checked={quoteIsSelected}
              size='small'
            />
          </TableCell>
        )}
        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
          <Link key={latestJob.lead.id} target='_blank' to={`/jobs/${latestJob.id}`}>
            {DateTime.fromISO(latestJob.startDatetime).toISODate()}
          </Link>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
          <Link key={latestJob.lead.id} target='_blank' to={`/leads/${latestJob.lead.id}`}>
            {latestJob.lead.customer.firstName} {latestJob.lead.customer.lastName}
          </Link>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} align='right'>
          {latestJob.lead.year} {latestJob.lead.make} {latestJob.lead.model}
        </TableCell>
        <TableCell align='right' sx={{ '& .percent': { color: '#777', fontSize: '12px' } }}>
          <Dollars value={quotePayoutData?.fullPayoutItemsAfterCcFee || 0} />
          {quotePayoutData?.techChargesCommissionPercent && quotePayoutData?.techChargesCommissionPercent !== 100 ? (
            <span className='percent'> ({quotePayoutData?.techChargesCommissionPercent}%)</span>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell align='right'>
          <Dollars value={quotePayoutData?.tipAfterFee || 0} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={quotePayoutData?.payoutBonus || 0} />
        </TableCell>
        <TableCell align='right'>
          <Dollars value={quotePayoutData?.totalLaborAmount || 0} />
        </TableCell>
        <TableCell align='right'>
          {isEmployee ? (
            <Dollars value={quotePayoutData?.laborCommissionAfterCcFee || 0} />
          ) : (
            quotePayoutData?.commissionPercentApplied
          )}
        </TableCell>
        <TableCell align='right'>
          <Dollars value={quotePayoutData?.totalPayout || 0} />
        </TableCell>
        {handleClickDiscludeThisQuote && (
          <TableCell align='right'>
            <button onClick={handleClickDiscludeThisQuote}>remove</button>
          </TableCell>
        )}
        {actionButtons && (
          <TableCell
            align='right'
            sx={{
              position: 'sticky',
              right: '-1px',
              background: {
                xs: '#F8FAFB',
                sm: '#F8FAFB',
                md: '0px',
              },
            }}
          >
            <Badge overlap='circular' color='primary' badgeContent={payrollNotesCount}>
              <Button
                onClick={e => {
                  setMenuOpen(true)
                  setPopoverItemRef(e.target)
                }}
                // @ts-ignore
                sx={{
                  minWidth: '20px',
                  minHeight: '20px',
                  padding: '.125rem',
                  '& svg': { width: '20px', height: '20px' },
                }}
              >
                <MoreVertRounded />
              </Button>
            </Badge>

            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => {
                setMenuOpen(false)
              }}
              open={menuOpen}
              anchorEl={popoverItemRef}
            >
              <List>
                <ListItem onClick={() => setNoteDialogOpen(true)} disablePadding disableGutters>
                  <ListItemButton>View notes</ListItemButton>
                </ListItem>
                <Divider />
                <ListItem onClick={handleClickForceRemove} disablePadding disableGutters>
                  <ListItemButton>Exclude from payroll</ListItemButton>
                </ListItem>
              </List>
            </Popover>
          </TableCell>
        )}
      </TableRow>
      <NotesDialog
        timeCard={null}
        quote={quote}
        noteDialogOpen={noteDialogOpen}
        setNoteDialogOpen={setNoteDialogOpen}
      />
    </>
  )
}

const PayoutPreview = ({ setRequestSuccess, setRequestLoading, refetch, technicianId, quotes }: any) => {
  const [user] = useContext(UserContext)
  const [ballerMode, setBallerMode] = useState(false)
  const [selectedQuoteIds, setSelectedQuoteIds] = useState<any>(quotes.map((quote: any) => quote.id))
  const { commissionChargebacks } = useTechnicianChargeBacks({ technicianId: technicianId })
  const [payoutDataOpen, setPayoutDataOpen] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(true)
  const [waiting, setWaiting] = useState(false)

  const { loading, error, data } = useQuery(TECHNICIAN_QUERY, {
    variables: { id: technicianId, cached: true },
    onCompleted: () => setRequestLoading(false),
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const technician = data.technician

  const handleClickMoveToPayout = () => {
    setWaiting(true)
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/move_selected_quotes_for_technician_to_payout`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        technician_id: technicianId,
        user_id: user.id,
        quote_ids: selectedQuoteIds,
      }),
    }).then(res => {
      setRequestLoading(false)
      setRequestSuccess('Quotes moved to payout')
    })
  }

  const allInvoicesAreSelected =
    JSON.stringify(selectedQuoteIds) === JSON.stringify(quotes.map((quote: any) => quote.id))

  const payoutCalculations = calculatePayoutData({
    reimbursements: [],
    pto: [],
    uto: [],
    sto: [],
    manualCompensations: [],
    chargeBacks: ballerMode ? commissionChargebacks : [],
    timeCards: [],
    quotes: keysToCamel(quotes).map((q: any) => ({ ...q, technician: q.technician })),
    bToBInvoices: [],
    bToBPayouts: [],
    technician: technician,
  })

  const {
    loanDeductionAmount,
    otherLoanDeductionAmount,
    quoteTechChargesTotalAmount,
    quoteTotalLaborAmount,
    gustoWageAmount,
    quoteCommissionBeforeTip,
    payoutTotalOverLaborTotal,
  } = payoutCalculations

  return (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '12px', mb: '1rem', padding: '.5rem' }}>
      <Box sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
        <Typography color='textSecondary'>
          <Link target='_blank' to={`/technicians/${technicianId}`}>
            <b>
              {technician.firstName} {technician.lastName}
              {technician.contractorIsBusiness ? `(${technician.businessName})` : ''}
            </b>
          </Link>
          <small>
            {technician.loanBalance > 0 ? (
              <>
                {' '}
                &nbsp;—&nbsp; Equipment Loan Balance: <Dollars value={technician.loanBalance} />
              </>
            ) : (
              ''
            )}
          </small>
          <small>
            {technician.otherLoanBalance > 0 ? (
              <>
                &nbsp;—&nbsp; Other Loan Balance: <Dollars value={technician.otherLoanBalance} />
              </>
            ) : (
              ''
            )}
          </small>
        </Typography>
        <FormControlLabel
          sx={{
            ml: 'auto',
            '& .MuiTypography-root': {
              fontSize: '12px !important',
            },
          }}
          control={
            <Switch
              size='small'
              checked={ballerMode}
              onChange={e => {
                e.stopPropagation()
                setBallerMode(!ballerMode)
              }}
            />
          }
          label='Show with chargeback calculations'
        />
        <Button
          disableRipple
          sx={{ '& svg': { transform: collapseOpen ? 'rotate(90deg)' : 'rotate(0deg)' } }}
          size='small'
          onClick={() => setCollapseOpen(!collapseOpen)}
        >
          <ChevronRightRounded />
        </Button>
      </Box>

      <Collapse in={collapseOpen}>
        {technician && (
          <Collapse in={ballerMode}>
            <CommissionChargeBacks prefetched={ballerMode ? commissionChargebacks : []} technician={technician} />
          </Collapse>
        )}

        <Table size='small'>
          <ContractorQuotesTableHeadRow
            selectAll={() =>
              allInvoicesAreSelected
                ? setSelectedQuoteIds([])
                : setSelectedQuoteIds(quotes.map((quote: any) => quote.id))
            }
            allSelected={allInvoicesAreSelected}
            actionButtons
          />
          <TableBody>
            {quotes
              .map((quote: any) => keysToCamel(quote))
              .map((quote: any, index: number) => (
                <ContractorQuotesPayoutRow
                  quoteIsSelected={selectedQuoteIds.includes(quote.id)}
                  selectInvoice={() =>
                    selectedQuoteIds.includes(quote.id)
                      ? setSelectedQuoteIds((prev: any) => prev.filter((id: string) => id !== quote.id))
                      : setSelectedQuoteIds((prev: any) => [...prev, quote.id])
                  }
                  index={index}
                  key={quote.id}
                  quote={quote}
                  actionButtons
                  refetch={refetch}
                />
              ))}
            <ContractorQuotesSumRow actionButtons quotes={keysToCamel(quotes)} />
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <Button
            disabled={waiting}
            variant='contained'
            color='primary'
            endIcon={<ArrowRightIcon />}
            onClick={handleClickMoveToPayout}
            size='small'
          >
            Move to Payout
          </Button>

          <Button
            size='small'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => setPayoutDataOpen(!payoutDataOpen)}
          >
            Open payout data
          </Button>

          <Box
            sx={{
              background: '#f1f1f1',
              borderRadius: '6px',
              padding: '.125rem .5rem',
              marginTop: '.5rem',
              ml: 'auto',
            }}
          >
            <span style={{ fontSize: '11px' }}>Quote labor total:</span>
            <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
              <Dollars value={quoteTotalLaborAmount} />
            </Box>
          </Box>

          <Box
            sx={{
              background: '#f1f1f1',
              borderRadius: '6px',
              padding: '.125rem .5rem',
              marginTop: '.5rem',
              ml: '.5rem',
            }}
          >
            <span style={{ fontSize: '11px' }}>Commission total (before tip):</span>
            <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
              <Dollars value={quoteCommissionBeforeTip + quoteTechChargesTotalAmount} />
            </Box>
          </Box>

          <Box
            sx={{
              background: '#f1f1f1',
              borderRadius: '6px',
              padding: '.125rem .5rem',
              marginTop: '.5rem',
              ml: '.5rem',
            }}
          >
            <span style={{ fontSize: '11px' }}>Technician Cost (Percentage):</span>
            <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
              <span>{Number(payoutTotalOverLaborTotal * 100).toFixed(2)}%</span>
            </Box>
          </Box>

          {loanDeductionAmount > 0 && (
            <Box
              sx={{
                background: '#fff0f0',
                borderRadius: '6px',
                color: '#ff5757',
                padding: '.125rem .5rem',
                marginTop: '.5rem',
                ml: '.5rem',
              }}
            >
              <span style={{ fontSize: '11px' }}>Loan Deduction Amount:</span>
              <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
                <Dollars value={-loanDeductionAmount} />
              </Box>
            </Box>
          )}

          {otherLoanDeductionAmount > 0 && (
            <Box
              sx={{
                background: '#fff0f0',
                borderRadius: '6px',
                color: '#ff5757',
                padding: '.125rem .5rem',
                marginTop: '.5rem',
                ml: '.5rem',
              }}
            >
              <span style={{ fontSize: '11px' }}>Other Loan Deduction Amount:</span>
              <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
                <Dollars value={-otherLoanDeductionAmount} />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              background: '#f1f1f1',
              borderRadius: '6px',
              padding: '.125rem .5rem',
              marginTop: '.5rem',
              ml: '.5rem',
              mr: '.5rem',
            }}
          >
            <span style={{ fontSize: '11px' }}>Tech charges:</span>
            <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
              <Dollars value={quoteTechChargesTotalAmount} />
            </Box>
          </Box>

          <Box sx={{ background: '#f1f1f1', borderRadius: '6px', padding: '.125rem .5rem', marginTop: '.5rem' }}>
            <span style={{ fontSize: '11px' }}>Tech payout total (with tips):</span>
            <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
              <Dollars value={gustoWageAmount} />
            </Box>
          </Box>
        </Box>
      </Collapse>

      {payoutDataOpen && (
        <PayoutDataPopover
          payoutDataOpen={payoutDataOpen}
          setPayoutDataOpen={setPayoutDataOpen}
          data={payoutCalculations}
        />
      )}
    </Box>
  )
}

const Payroll = () => {
  const [waiting, setWaiting] = useState(false)
  const [user] = useContext(UserContext)
  const { startDatetime, endDatetime } = useContext(ConfigurationContext)
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [requestSuccess, setRequestSuccess] = useState<any>(false)
  const [requestLoading, setRequestLoading] = useState(false)

  const { loading, error, data, refetch } = useQuery(PAYOUT_PREVIEWS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      readyForPayout: true,
      employeesOnly: true,
    },
  })

  // if (loading) return <div>Loading</div>
  if (error) return <div>Error!</div>

  const handleClickGeneratePayouts = () => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/generate_all_payouts`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        startDatetime,
        endDatetime,
        employeesOnly: true,
      }),
    })
      .then((res: any) => {
        res.ok ? refetch() : alert('Error')
      })
      .finally(() => setWaiting(false))
  }

  const handleClickForceAdd = () => {
    const quoteId = window.prompt(
      'please enter the quote_id as you find it in the URL of the quote (crm.carbodylab.com/.../quotes/QUOTE_ID_HERE/'
    )
    if (quoteId && quoteId !== '') {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_quote_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          quoteId,
          updatedAttributes: {
            force_include_in_payroll: true,
            force_exclude_from_payroll: false,
          },
        }),
      }).then(res => refetch() || window.alert('Error'))
    }
  }

  const quotes = keysToCamel(data?.quotes || []) || []

  const quotesGroupedByTechnician = quotes.reduce(
    (acc: any, quote: QuoteType) =>
      acc[quote.assignedTechnicianId]
        ? { ...acc, [quote.assignedTechnicianId]: [...acc[quote.assignedTechnicianId], quote] }
        : { ...acc, [quote.assignedTechnicianId]: [quote] },
    {}
  )

  return (
    <Box>
      <Snackbar
        onClose={() => setRequestSuccess(false)}
        open={!!requestSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5', mr: '1rem', height: '20px', width: '20px' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' },
          mb: '3rem',
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutlineRounded /> {requestSuccess}
          </Box>
        }
      />
      <Snackbar
        onClose={() => setRequestLoading(false)}
        open={requestLoading}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' },
          mb: '3rem',
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={'1rem'} sx={{ mr: '1rem' }} /> Loading...{' '}
          </Box>
        }
      />

      {waiting && (
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111140',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            zIndex: 100,
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}

      {!loading && data && quotes.length === 0 && (
        <Box
          sx={{
            zIndex: 2,
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111180',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            color: '#fff',
            backdropFilter: 'blur(2px)',
            '& svg': {
              height: '40px',
              width: '40px',
              mb: '.5rem',
            },
          }}
        >
          <CheckCircleOutlineRounded />
          <Typography variant='body1' sx={{ fontSize: '24px' }}>
            All Contractor Quotes Have Been Added
          </Typography>
        </Box>
      )}

      <Box sx={{ position: 'fixed', bottom: '1rem', right: '1rem', '& svg': { ml: '.5rem' }, zIndex: 1 }}>
        <Button
          sx={{ mr: '.5rem', zIndex: 100 }}
          disabled={waiting}
          variant='contained'
          color='primary'
          onClick={handleClickForceAdd}
        >
          Force add a quote to this payroll
        </Button>
        <Button
          sx={{ zIndex: 100 }}
          disabled={waiting}
          variant='contained'
          color='primary'
          onClick={handleClickGeneratePayouts}
        >
          move all to payouts <KeyboardArrowRightRounded />
        </Button>
      </Box>

      <Grid container padding='.25rem' sx={{ pb: '2rem' }}>
        <Grid item xs={12}>
          {(loading || (data && quotes.length === 0)) && (
            <>
              {[1, 2, 3, 4, 5, 6, 7].map((i: number) => (
                <Card key={i} sx={{ mb: '1rem ' }}>
                  <Box sx={{ width: '150px', padding: '.5rem 1rem' }}>
                    <Skeleton
                      sx={{
                        transform: 'scale(1)',
                        minHeight: '32px',
                      }}
                    />
                  </Box>
                  <Table size='small'>
                    <ContractorQuotesTableHeadRow actionButtons />
                    <TableBody>
                      {[1, 2].map(row => (
                        <SkeletonRow key={row} />
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              ))}
            </>
          )}

          {!loading &&
            Object.entries(quotesGroupedByTechnician).map(group => (
              <PayoutPreview
                requestLoading={requestLoading}
                setRequestLoading={setRequestLoading}
                setRequestSuccess={setRequestSuccess}
                key={group[0]}
                quotes={group[1]}
                technicianId={group[0]}
                refetch={refetch}
              />
            ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export const ResidentialEmployees = () => {
  return (
    <Box>
      <Payroll />
    </Box>
  )
}

export const ContractorQuotesTableHeadRow = ({
  selectAll,
  allSelected,
  hideCheckbox,
  showDiscludeButton,
  actionButtons,
  isEmployee,
}: any) => {
  return (
    <TableHead>
      <TableRow>
        {!hideCheckbox && (
          <TableCell sx={{ maxWidth: '60px', padding: '0rem .5rem' }}>
            <Checkbox
              sx={{
                padding: '0px',
                '& svg': {
                  height: '15px',
                  width: '15px',
                },
              }}
              size='small'
              checked={allSelected}
              onClick={() => selectAll()}
            />
          </TableCell>
        )}
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Last job date
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Customer name
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Vehicle
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Tech Charges
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Tip amount
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Payout bonus
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Labor total
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          {isEmployee ? 'Commission Amount' : 'Commission percent'}
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Total payout
        </TableCell>
        {showDiscludeButton && (
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Actions
          </TableCell>
        )}
        {actionButtons && <TableCell align='right'></TableCell>}
      </TableRow>
    </TableHead>
  )
}

export const ContractorQuotesSumRow = ({
  quotes,
  hideCheckbox,
  showDiscludeButton,
  actionButtons,
  isEmployee,
  cached,
}: any) => {
  let totalPayoutBonus = 0
  let totalLaborTotal = 0
  let totalTipAmount = 0
  let totalPayout = 0
  let commissionTotal = 0
  let techChargesTotal = 0

  const quotesWithPayoutData = cached
    ? quotes
    : quotes.map((quote: any) => ({
        ...quote,
        payoutData: calculateQuotePayoutData({ quote, technician: quote.technician }),
      }))

  if (isEmployee) {
    totalPayoutBonus = quotesWithPayoutData.reduce(
      (acc: any, quote: any) => acc + quote.payoutData?.payoutBonus || 0,
      0
    )
    totalLaborTotal = quotesWithPayoutData
      .map((quote: any) => quote.payoutData?.totalLaborAmount || 0)
      .reduce((acc: any, quote: any) => acc + quote || 0, 0)

    totalTipAmount = quotesWithPayoutData
      .map((quote: any) => quote.payoutData?.tipAfterFee || 0)
      .reduce((acc: any, quote: any) => acc + quote || 0, 0)

    totalPayout = quotesWithPayoutData
      .map((quote: any) => quote.payoutData?.totalPayout || 0)
      .reduce((acc: any, quote: any) => acc + quote || 0, 0)

    commissionTotal = quotesWithPayoutData
      .map((quote: any) => quote.payoutData?.laborCommissionAfterCcFee || 0)
      .reduce((acc: any, quote: any) => acc + quote || 0, 0)

    techChargesTotal = quotesWithPayoutData
      .map((quote: any) => quote.payoutData?.fullPayoutItemsAfterCcFee || 0)
      .reduce((acc: any, quote: any) => acc + quote || 0, 0)
  } else {
    totalPayoutBonus = quotesWithPayoutData.reduce(
      (acc: any, quote: any) => acc + quote.payoutData?.payoutBonus || 0,
      0
    )
    totalLaborTotal = quotesWithPayoutData.reduce(
      (acc: any, quote: any) => acc + keysToCamel(quote).payoutData?.totalLaborAmount || 0,
      0
    )

    totalTipAmount = quotesWithPayoutData.reduce(
      (acc: any, quote: any) => acc + keysToCamel(quote).payoutData?.tipAfterFee || 0,
      0
    )

    totalPayout = quotesWithPayoutData.reduce(
      (acc: any, quote: any) => acc + keysToCamel(quote).payoutData?.totalPayout || 0,
      0
    )

    commissionTotal = quotesWithPayoutData.reduce(
      (acc: any, quote: any) => acc + keysToCamel(quote).payoutData?.laborCommissionAfterCcFee || 0,
      0
    )

    techChargesTotal = quotesWithPayoutData.reduce(
      (acc: any, quote: any) => acc + keysToCamel(quote).payoutData?.fullPayoutItemsAfterCcFee || 0,
      0
    )
  }

  return (
    <TableRow sx={{ background: 'aliceblue', borderTop: '2px solid #a1cff7' }}>
      {!hideCheckbox && <TableCell />}
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        <Dollars value={techChargesTotal} />
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        {/* tip */}
        <Dollars value={totalTipAmount} />
      </TableCell>

      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        <Dollars value={totalPayoutBonus} />
      </TableCell>

      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        <Dollars value={totalLaborTotal} />
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        {isEmployee ? <Dollars value={commissionTotal} /> : ''}
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        <Dollars value={totalPayout} />
      </TableCell>

      {showDiscludeButton && <TableCell align='right'></TableCell>}
      {actionButtons && <TableCell align='right'></TableCell>}
    </TableRow>
  )
}

export const SkeletonRow = () => {
  return (
    <TableRow>
      <TableCell sx={{ maxWidth: '60px', padding: '0rem .5rem' }}>
        <Checkbox
          sx={{
            padding: '0px',
            '& svg': {
              height: '15px',
              width: '15px',
            },
          }}
          size='small'
        />
      </TableCell>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((i: number, index: number) => (
        <TableCell key={i}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '28px',
              transform: 'scale(1)',
              background: index % 2 !== 0 ? '#0089CD1a' : '#f1f1f1',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}
