import { DateTime } from 'luxon'
import { calculateEmployeeQuotePayoutData } from './quotes'
import { WorkLogType } from './types/workLog'
import { TechnicianType } from './types/technician'

export const calculateWorklogPayoutData = ({ workLog, technician }: { workLog: WorkLogType, technician: TechnicianType }) => {
  const quote = workLog.quote
  const job = workLog.job || workLog.bToBJob

  if (!quote || !quote.payrollStatuses) return {}

  const commissionIsPayable = quote.payrollStatuses.includes('commission_payable')
  const commissionHasBeenPaid = quote.payrollStatuses.includes('commission_paid')
  const tipIsPayable = quote.payrollStatuses.includes('tip_payable')
  const commissionIsWithheld = workLog.commissionType === 'Withheld'

  // we want to do all of the logic for determining the workLogs commission amounts
  // right here... right in the calculator, instead of doing that logic on the api side,
  // this will enable us to calculate estimated workLog payout data on the fly in the CRM

  const workLogIsSelectedToPayCommissionForQuote = isWorklogSelectedForCommissionPayment({ workLog: workLog })
  const workLogIsSelectedToPayTipForQuote = isWorklogSelectedForTipPayment({ workLog: workLog })

  // if a workLog has a custom amount, that workLog will showup as being selected for the quote
  // MEANING that "workLogIsSelectedToPayCommissionForQuote" will return true for any workLog that has workLogIsSelectedToPayCommissionForQuote attribute set to true
  // it is the APIs job to update that attribute on a given WorkLog

  // the workLog will never be considered default if it has been  marked as commissionIsWithheld
  // one important thing to check for is the custom commission amounts... that should be checked for first probably...
  // regardless of the workLog having withheld commission, any workLog that has a custom amount set to it will trump

  let payoutData: any = {
    tip: 0,
    commissionAmount: 0,
    commissionHours: 0,
    hourlyPay: 0,
    hourlyType: workLog.hourlyType,
    workedHours: 0,
    // hourlyPayDescription: workLog?.payoutData?.hourlyPayDescription || 0,
    // hourlyPay: workLog?.payoutData?.hourlyPay || 0,
  }

  if (tipIsPayable && workLogIsSelectedToPayTipForQuote) {
    const employeePayoutData = calculateEmployeeQuotePayoutData({
      technicianCharges: quote.technicianChargesSum,
      laborAmount: quote.laborSumAfterAllDiscounts,
      creditCardFeeSum: quote.ccFeeSum,
      tipSumBeforeFee: quote.tipSum,
      totalTransactionAmount: quote.totalTransactionAmount,
      hourlyRate: technician.hourlyRate,
      commissionAmount: technician.commissionAmount,
      technicianId: technician.id
    })

    payoutData.tip = employeePayoutData?.tipAfterFee || 0
  }

  // if the workLog is the default for the quote then tip is mandatorily paid out on the default day
  if (workLogIsSelectedToPayCommissionForQuote) {
    if (commissionHasBeenPaid) {
      // workLog is the default for the quote, but commission has already been paid out
      payoutData.commissionDescription = 'Commission has already been paid for this quote.'
    } else {
      if (commissionIsPayable) {
        // workLog is the default for the quote, commission has not been paid yet and it is commission payable
        const otherSelectedWorklog = quote.workLogs.find((wl: any) => wl?.selectedForCommissionPayment)
        const anotherWorklogHasBeenMarkedForCommission = otherSelectedWorklog?.id !== workLog.id

        if (otherSelectedWorklog && anotherWorklogHasBeenMarkedForCommission) {
          // another workLog for this quote has already been marked for commission payment (ahead of this one)
          payoutData.commissionDescription =
            'Another workLog in payroll has been selected to pay commission for this quote.'
        } else {
          // there is no other workLog selected for commission payment for this quote

          if (workLog.commissionType === 'Job') {
            const { commissionAmount } = calculateCommissionAmountForHours({
              hours: job.scheduledLength,
              commissionRate: technician.commissionAmount,
            })
            payoutData.commissionDescription = `Commission amount of $${commissionAmount} is based on ${job.scheduledLength} scheduled hour(s).`
            payoutData.commissionAmount = commissionAmount
            payoutData.commissionHours = job.scheduledLength
            payoutData.commissionType = 'Job'
          } else if (workLog.commissionType === 'Worklog') {
            const { commissionAmount } = calculateCommissionAmountForHours({
              hours: workLog.totalHours,
              commissionRate: technician.commissionAmount,
            })

            payoutData.commissionDescription = `Commission amount of $${commissionAmount} is based on ${workLog.totalHours} worked hour(s).`
            payoutData.commissionAmount = commissionAmount
            payoutData.commissionHours = workLog.totalHours
            payoutData.commissionType = 'Worklog'
          } else if (workLog.commissionType === 'Withheld') {
            payoutData.commissionDescription = `Commission has been withheld from this quote.`
            payoutData.commissionAmount = 0
            payoutData.commissionHours = 0
            payoutData.commissionType = 'Withheld'
          } else if (workLog.commissionType === 'Custom') {
            payoutData.commissionDescription = `Commission has been custom set to ${workLog.amount}.`
            payoutData.commissionAmount = workLog.amount
            payoutData.commissionHours = 0
            payoutData.commissionType = 'Custom'
          } else {
            const { commissionAmount } = calculateCommissionAmountForHours({
              hours: job.scheduledLength,
              commissionRate: technician.commissionAmount,
            })

            payoutData.commissionDescription = `Commission amount of $${commissionAmount} is based on ${job.scheduledLength} scheduled hour(s).`
            payoutData.commissionAmount = commissionAmount
            payoutData.commissionHours = job.scheduledLength
            payoutData.commissionType = 'Job'
          }
        }
      } else {
        // workLog is the default for the quote, but commission is not payable and has not been paid yet
        payoutData.commissionDescription = 'Commission will be paid out when the quote is fully paid.'
      }
    }
  } else {
    if (commissionIsWithheld) {
      // commission is withtheld for this quote
      payoutData.commissionDescription = 'Commission has been withheld from this job.'
    } else {
      // commission has not been withheld from this quote
      if (commissionHasBeenPaid) {
        // workLog is not the default for the quote, and commission has already been paid
        payoutData.commissionDescription = 'Commission has already been paid for this quote.'
      } else {
        if (commissionIsPayable) {
          // workLog is not the default for the quote, commission has not been paid and commission is payable

          const closestWorklogToQuote = getWorklogToSelectForCommissionPayment({ quote })

          // if this is false, that means the quote isnt fully paid
          if (closestWorklogToQuote) {
            const closestJobStartTime = DateTime.fromISO(closestWorklogToQuote.startedAt)
            const closestJobStartTimeIsInPast = closestJobStartTime < DateTime.now()
            const quoteFullyPaidDate = DateTime.fromISO(quote.fullyPaidAt)

            payoutData.commissionDescription = `Commission will be paid out to the job ${
              closestJobStartTimeIsInPast ? 'that occured' : 'occuring'
            } on ${closestJobStartTime.toFormat(
              'LLL dd yyyy'
            )}, which is closer to the quotes fully paid date of ${quoteFullyPaidDate.toFormat('LLL dd yyyy')}.`
          } else {
            payoutData.commissionDescription = 'Commission will be paid out when the quote is fully paid.'
          }
        } else {
          const quoteFullyPaidDate = DateTime.fromISO(quote.fullyPaidAt)
          if (quote.fullyPaidAt) {
            payoutData.commissionDescription = `Commission for this quote was paid out on ${quoteFullyPaidDate.toFormat(
              'LLL dd yyyy'
            )}.`
          } else {
            payoutData.commissionDescription = 'Commission will be paid out when the quote is fully paid.'
          }
          // workLog is not the default for the quote, commission has not been paid and commission is not payable
        }
      }
    }
  }

  if (workLog.hourlyType == 'Job') {
    const hourlyPay = calculateHourlyPay({
      hours: job.scheduledLength,
      hourlyRate: technician.hourlyRate,
    })
    payoutData.hourlyPay = hourlyPay
    payoutData.hourlyPayDescription = `Total hourly pay of $${hourlyPay} is based on the total scheduled time of ${job.scheduledLength} hour(s)`
    payoutData.hourlyType = 'Job'
    payoutData.workedHours += job.scheduledLength
  } else if (workLog.hourlyType == 'Worklog') {
    const hourlyPay = calculateHourlyPay({
      hours: workLog.totalHours,
      hourlyRate: technician.hourlyRate,
    })
    payoutData.hourlyPay = hourlyPay
    payoutData.hourlyPayDescription = `Total hourly pay of $${hourlyPay} is based on the total worked time of ${workLog.totalHours} hour(s)`
    payoutData.hourlyType = 'Worklog'
    payoutData.workedHours += workLog.totalHours
  } else if (workLog.hourlyType == 'Withheld') {
    payoutData.hourlyPay = 0
    payoutData.hourlyPayDescription = 'Hourly pay has been withheld from this workLog'
    payoutData.hourlyType = 'Withheld'
  } else {
    const hourlyPay = calculateHourlyPay({
      hours: workLog.totalHours,
      hourlyRate: technician.hourlyRate,
    })
    payoutData.hourlyPay = hourlyPay
    payoutData.hourlyPayDescription = `Total hourly pay of $${hourlyPay} is based on the total worked time of ${workLog.totalHours} hour(s)`
    payoutData.hourlyType = 'Worklog'
    payoutData.workedHours += workLog.totalHours
  }

  payoutData.totalPayout = Number(
    (
      Number(payoutData?.commissionAmount || 0) +
      Number(payoutData?.hourlyPay || 0) +
      Number(payoutData?.tip || 0)
    ).toFixed(2)
  )

  ////
  // below we are adding overtime amounts based on 0.5 of the rate
  // this is because the overtime has actually already been calculated into the total hours...
  // so we need to increase the hourly amount by another .5 of the rate of the additional hours of OT
  ////

  if (technician.state === 'CA') {
    if (workLog.hourlyType == 'Job') {
      if (job.scheduledLength > 8) {
        const overtimeHours = job.scheduledLength - 8
        payoutData.overtimePay = Number((overtimeHours * (1.5 * technician.hourlyRate)).toFixed(2))
        payoutData.overtimeHours = overtimeHours
        // payoutData.hourlyPay += payoutData.overtimePay
      }
    } else if (workLog.hourlyType == 'Worklog') {
      if (workLog.totalHours > 8) {
        const overtimeHours = workLog.totalHours - 8
        payoutData.overtimePay = Number((overtimeHours * (1.5 * technician.hourlyRate)).toFixed(2))
        payoutData.overtimeHours = overtimeHours
        // payoutData.hourlyPay += payoutData.overtimePay
      }
    } else if (workLog.hourlyType == 'Withheld') {
      payoutData.overtimePay = 0
      payoutData.overtimeHours = 0
    } else {
      if (job.scheduledLength > 8) {
        const overtimeHours = job.scheduledLength - 8
        payoutData.overtimePay = Number((overtimeHours * (1.5 * technician.hourlyRate)).toFixed(2))
        payoutData.overtimeHours = overtimeHours
        // increase the hourly pay by half the rate (since the hours over 8 were already added into
        // the hourly total, we want to add the additional "half" from "time and a half")
        // payoutData.hourlyPay -= payoutData.overtimePay
      }
    }
  }

  return payoutData
}

export const isWorklogSelectedForCommissionPayment = ({ workLog }: any) => {
  const quote = workLog.quote
  const workLogForCommission = getWorklogToSelectForCommissionPayment({ quote })

  if (workLogForCommission && workLogForCommission.id === workLog.id) {
    return true
  }

  return false
}

const isWorklogSelectedForTipPayment = ({ workLog }: any) => {
  // worklogs can only be selected for tip payment by default IF the job is active
  const quote = workLog.quote
  const workLogForCommission = getOnlyClosestWorklogToQuoteFullyPaid({ quote })

  if (workLogForCommission && workLogForCommission.id === workLog.id) {
    return true
  }

  return false
}

export const getOnlyClosestWorklogToQuoteFullyPaid = ({ quote }: any) => {
  const workLogs = quote.workLogs
    .slice()
    .filter(
      (workLog: any) =>
        !workLog.paidOutAt && !workLog.commissionWithheldAt && (workLog.job ? workLog.job.isActive : true)
    )
  const nearestWorklog = workLogs.sort(
    // @ts-ignore
    (a: any, b: any) =>
      // @ts-ignore
      Math.abs(DateTime.fromISO(a.completedAt) - DateTime.fromISO(quote.fullyPaidAt)) >
      // @ts-ignore
      Math.abs(DateTime.fromISO(b.completedAt) - DateTime.fromISO(quote.fullyPaidAt))
        ? 1
        : -1
  )[0]

  if (quote.fullyPaidAt) {
    return { ...nearestWorklog, quote }
  } else {
    return null
  }
}

export const getWorklogToSelectForCommissionPayment = ({ quote }: any) => {
  // when a quote is determining if commission is payable, it will never
  // return a paid out worklog... which is good, because commission should
  // only be payable for a quote with a paid out worklog IF the quote has commission_payable status

  // worklogs can only be selected for commission payment by default IF the job is active
  const workLogs = quote.workLogs
    .slice()
    .filter(
      (workLog: any) =>
        !workLog.paidOutAt && !workLog.commissionWithheldAt && (workLog.job ? workLog.job.isActive : true)
    )
  const nearestWorklog = workLogs.sort(
    // @ts-ignore
    (a: any, b: any) =>
      // @ts-ignore
      Math.abs(DateTime.fromISO(a.completedAt) - DateTime.fromISO(quote.fullyPaidAt)) >
      // @ts-ignore
      Math.abs(DateTime.fromISO(b.completedAt) - DateTime.fromISO(quote.fullyPaidAt))
        ? 1
        : -1
  )[0]

  if (quote.fullyPaidAt) {
    // if there is a workLog already marked as "selected for commission payment"
    // then return that workLog for this function 'getWorklogToSelectForCommissionPayment'
    const workLogWithCustomAmount = quote.workLogs.find((wl: any) => wl.selectedForCommissionPayment)

    if (workLogWithCustomAmount) {
      return { ...workLogWithCustomAmount, quote }
    } else {
      return { ...nearestWorklog, quote }
    }
  } else {
    return null
  }
}

export const calculateCommissionAmountForHours = ({ hours, commissionRate }: any) => {
  let commissionAmount = hours * commissionRate || 0

  if (hours <= 5) {
    commissionAmount = commissionRate
  } else if (hours > 5 && hours <= 7) {
    let totalHoursAboveFive = hours - 5
    commissionAmount = (commissionRate / 4) * totalHoursAboveFive + commissionRate
  } else if (hours > 7) {
    commissionAmount = commissionRate * 2
  }

  return { commissionAmount }
}

const calculateHourlyPay = ({ hours, hourlyRate }: any) => {
  const hourlyPay = hours * hourlyRate
  return hourlyPay
}

const calculateTipAfterCcFee = ({ quote }: any) => {
  return { quote }
}
