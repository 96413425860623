import {
  Badge,
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Skeleton,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { UserContext } from 'UserStore'
import { Button, Typography, Card, CardContent, Checkbox } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { API_COMMAND_HEADERS, Dollars, keysToCamel } from 'tools'
import { DateTime } from 'luxon'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import { ConfigurationContext } from '../Configure/Configure'
import {
  CheckCircleOutlineRounded,
  ChevronRightRounded,
  ExpandMoreRounded,
  KeyboardArrowRightRounded,
  LaunchRounded,
  MoreVertRounded,
} from '@mui/icons-material'
import PayrollPopup from 'TimeCards/PayrollPopup'
import { TravelLogsDropdown } from './components/TravelLogsDropdown'
import { BToBJobsDropdown } from './components/BToBJobsDropdown'
import { ResidentialJobsDropdown } from './components/ResidentialJobsDropdown'
import { NotesDialog } from './components/NotesDialog'
import PaidTimeOff from './components/PaidTimeOff'
import UnpaidTimeOff from './components/UnpaidTimeOff'
import { useTechnicianPaidTimeOff } from 'hooks/useTechnicianPaidTimeOff'
import { useTechnicianUnpaidTimeOff } from 'hooks/useTechnicianUnpaidTimeOff'
import { useTechnicianChargeBacks } from 'hooks/useTechnicianChargeBacks'
import CommissionChargeBacks from './components/CommissionChargeBacks'
import { useTechnicianSickTimeOff } from 'hooks/useTechnicianSickTimeOff'
import SickTimeOff from './components/SickTimeOff'
import { calculatePayoutData, calculateTimeCardPayoutData } from 'payrollCalculator'
import { PayoutDataPopover } from '../components/PayoutDataPopover'

const QUERY = gql`
  query timeCards(
    $isDeleted: Boolean
    $isAccepted: Boolean
    $hasPayoutId: Boolean
    $startDatetime: String
    $endDatetime: String
  ) {
    timeCards(
      isDeleted: $isDeleted
      isAccepted: $isAccepted
      hasPayoutId: $hasPayoutId
      startDatetime: $startDatetime
      endDatetime: $endDatetime
    ) {
      id
      timeCardDate
      createdAt
      acceptedAt
      workHours
      paidTravelHours
      unpaidTravelHours
      technicianId
      payrollNotesCount
      childrenPayrollNotesCount
      technician {
        id
        firstName
        lastName
        hourlyRate
        commissionAmount
        commissionPercent
        quoteCommissionStructure
        state
      }
      quotes {
        id
        tipSum
        ccFeeSum
        totalTransactionAmount
        laborSumAfterAllDiscounts
        technicianChargesSum
        payrollStatuses
        assignedTechnicianId
        forceIncludeInPayroll
        fullyPaidAt
        workLogs {
          id
          startedAt
          completedAt
          amount
          totalHours
          commissionType
          paidOutAt
          selectedForCommissionPayment
          hourlyType
          quoteId
          bToBJobId
          jobId
          job {
            id
            scheduledLength
            isActive
          }
        }
      }
      bToBJobsLogs {
        id
        createdAt
        startedAt
        completedAt
        type
        travelDistance
        totalHours
        commissionType
        selectedForCommissionPayment
        bToBJobId
        hourlyType
        bToBJob {
          id
          scheduledLength
        }
      }
      travelLogs {
        id
        createdAt
        startedAt
        completedAt
        type
        isPaid
        travelDistance
        totalHours
        technician {
          hourlyRate
          commissionAmount
        }
      }
      workLogs {
        id
        startedAt
        commissionType
        amount
        completedAt
        totalHours
        paidOutAt
        selectedForCommissionPayment
        hourlyType
        bToBJobId
        quoteId
        jobId
        job {
          id
          scheduledLength
          isActive
        }
        quote {
          id
          tipSum
          ccFeeSum
          totalTransactionAmount
          laborSumAfterAllDiscounts
          technicianChargesSum
          paymentStatus
          fullyPaidAt
          payrollStatuses
          payrollNotesCount
          forceIncludeInPayroll
          assignedTechnicianId
          workLogs {
            id
            hourlyType
            bToBJobId
            startedAt
            amount
            completedAt
            totalHours
            commissionType
            quoteId
            jobId
            paidOutAt
            selectedForCommissionPayment
            job {
              id
              scheduledLength
              isActive
            }
          }
        }
      }
    }
  }
`

export const TimeCardPayoutRow = ({
  timeCard,
  index,
  selectTimeCard,
  timeCardIsSelected,
  handleClickDisclude,
  hideCheckbox,
  unacceptButton,
  expandJobsArrow,
  disableActions,
  refetch,
}) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [modalTimeCardId, setModalTimeCardId] = useState(null)
  const [user] = useContext(UserContext)
  const [expanded, setExpanded] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [popoverRef, setPopoverRef] = useState(null)
  const { technician } = timeCard

  const [noteDialogOpen, setNoteDialogOpen] = useState(false)

  const handleClickUnaccept = id => {
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unaccept_time_card`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          timeCardId: id,
          userId: user.id,
        }),
      })
      .then(response => {
        if (response.ok) {
          refetch && refetch()
        } else {
          throw Error('error')
        }
      })
      .catch(err => {
        window.alert(err)
      })
  }

  const bToBWorkLogs = timeCard?.bToBJobsLogs || []
  const residentialWorkLogs = timeCard?.workLogs?.filter(log => log.jobId) || []
  const travelLogs = timeCard?.travelLogs?.filter(log => log.type === 'travel_log') || []
  const childrenNotesCount = (timeCard?.childrenPayrollNotesCount || 0) + (timeCard?.payrollNotesCount || 0)
  const timeCardPayoutData = calculateTimeCardPayoutData({ timeCard, technician })

  return (
    <>
      <TableRow
        onClick={expandJobsArrow ? () => setExpanded(prev => !prev) : null}
        sx={[
          { background: index % 2 === 0 ? '#fff' : '#f9f9f9' },
          expandJobsArrow && { cursor: 'pointer' },
          expanded && {
            borderLeft: '2px solid #3f51b5 !important',
            borderTop: '2px solid #3f51b5',
            borderRight: '2px solid #3f51b5',
            background: '#f2f6fa',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            marginBottom: '.5rem !important',
            marginTop: '.5rem !important',
          },
        ]}
      >
        {!hideCheckbox && (
          <TableCell sx={{ maxWidth: '60px', display: 'flex' }}>
            {expandJobsArrow && (
              <Button
                size='small'
                sx={{
                  padding: '.125rem',
                  '& svg': { width: '20px', height: '20px', transform: expanded ? 'rotate(180deg)' : 'scale(1)' },
                  minWidth: '20px',
                  minHeight: '20px',
                  mr: '0px',
                }}
              >
                <ExpandMoreRounded />
              </Button>
            )}

            <Checkbox
              onClick={e => {
                e.stopPropagation()
                selectTimeCard()
              }}
              sx={{
                padding: '0px',
                '& svg': {
                  height: '15px',
                  width: '15px',
                },
              }}
              checked={timeCardIsSelected}
              size='small'
            />
          </TableCell>
        )}
        <TableCell
          align='right'
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          <Link key={timeCard.id} onClick={e => e.preventDefault()} target='_blank' to={`/timecards/${timeCard.id}`}>
            {DateTime.fromISO(timeCard.timeCardDate).toISODate()}
          </Link>
        </TableCell>

        <TableCell align='right'>{timeCardPayoutData.numJobs} </TableCell>
        <TableCell align='right'>{timeCardPayoutData?.travelTotalDistance} mi</TableCell>
        <TableCell align='right'>{timeCardPayoutData?.unpaidTravelTotalHours.toFixed(2)}</TableCell>
        <TableCell align='right'>{timeCardPayoutData?.paidTravelTotalHours.toFixed(2)}</TableCell>
        <TableCell align='right'>{timeCardPayoutData?.totalWorkHours?.toFixed(2)}</TableCell>
        <TableCell align='right'>{timeCardPayoutData?.totalHours.toFixed(2)}</TableCell>

        <TableCell align='right'>{timeCardPayoutData?.totalPaidHours.toFixed(2)}</TableCell>

        <TableCell align='right' sx={{ fontWeight: 'bold' }}>
          <Dollars value={timeCardPayoutData?.totalTipsForDay || 0} />
        </TableCell>

        <TableCell align='right' sx={{ fontWeight: 'bold' }}>
          <Dollars value={timeCardPayoutData?.totalHourlyPay || 0} />
        </TableCell>

        <TableCell
          align='right'
          sx={{
            fontWeight: 'bold',
          }}
        >
          <Dollars value={timeCardPayoutData?.totalCommissionAmount || 0} />
        </TableCell>

        <TableCell
          align='right'
          sx={{
            fontWeight: 'bold',
          }}
        >
          <Dollars value={timeCardPayoutData?.totalPayout || 0} />
        </TableCell>
        {unacceptButton && (
          <TableCell
            sx={{
              position: 'sticky',
              right: '-1px',
              background: {
                xs: '#F8FAFB',
                sm: '#F8FAFB',
                md: '0px',
              },
            }}
            align='right'
          >
            <Badge color='primary' overlap='circular' badgeContent={childrenNotesCount}>
              <Button
                onClick={e => {
                  e.stopPropagation()
                  setMenuOpen(true)
                  setPopoverRef(e.target)
                }}
                sx={{
                  padding: '.125rem',
                  '& svg': { width: '20px', height: '20px' },
                  minWidth: '20px',
                  minHeight: '20px',
                }}
              >
                <MoreVertRounded />
              </Button>
            </Badge>
            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={e => {
                e.stopPropagation()
                setMenuOpen(false)
              }}
              open={menuOpen}
              anchorEl={popoverRef}
            >
              <List>
                <ListItem disablePadding disableGutters>
                  <ListItemButton
                    onClick={e => {
                      e.stopPropagation()
                      setModalTimeCardId(timeCard.id)
                    }}
                  >
                    Open Time Card <LaunchRounded sx={{ fontSize: '18px', ml: '.5rem' }} />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding disableGutters>
                  <ListItemButton
                    onClick={e => {
                      e.stopPropagation()
                      setNoteDialogOpen(true)
                    }}
                  >
                    View notes
                  </ListItemButton>
                </ListItem>
                <Divider />

                <ListItem disablePadding disableGutters>
                  <ListItemButton
                    onClick={e => {
                      e.stopPropagation()
                      handleClickUnaccept(timeCard.id)
                    }}
                  >
                    Unaccept
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>
          </TableCell>
        )}
        {handleClickDisclude && (
          <TableCell align='right'>
            <button
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                handleClickDisclude()
              }}
            >
              Remove
            </button>
          </TableCell>
        )}
      </TableRow>

      <NotesDialog timeCard={timeCard} noteDialogOpen={noteDialogOpen} setNoteDialogOpen={setNoteDialogOpen} />

      <Dialog
        PaperProps={{ sx: { borderRadius: '12px' } }}
        open={!!modalTimeCardId}
        onClose={() => setModalTimeCardId(null)}
      >
        <Box>
          {modalTimeCardId && (
            <PayrollPopup
              close={() => setModalTimeCardId(null)}
              refetch={() => console.log('asdf')}
              prefetched={keysToCamel(timeCard)}
            />
          )}
        </Box>
      </Dialog>

      {expanded && (
        <>
          <ResidentialJobsDropdown
            residentialWorkLogs={residentialWorkLogs}
            travelLogs={travelLogs}
            timeCard={timeCard}
            expanded={expanded}
            bToBWorkLogs={bToBWorkLogs}
            disableActions={disableActions}
          />
          <BToBJobsDropdown
            disableActions={disableActions}
            timeCard={timeCard}
            expanded={expanded}
            bToBWorkLogs={bToBWorkLogs}
          />

          <TravelLogsDropdown disableActions={disableActions} expanded={expanded} travelLogs={travelLogs} />
        </>
      )}
    </>
  )
}

const PayoutPreview = ({ timeCards, refetch, setRequestSuccess, setRequestLoading }) => {
  const [selectedTimeCardIds, setSelectedTimeCardIds] = useState(timeCards.map(tc => tc.id))
  const [user] = useContext(UserContext)
  const [ballerMode, setBallerMode] = useState(true)
  const [payoutDataOpen, setPayoutDataOpen] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(true)
  const [waiting, setWaiting] = useState(false)

  const { technician } = timeCards[0]
  const { paidTimeOff } = useTechnicianPaidTimeOff({ technicianId: technician.id })
  const { unpaidTimeOff } = useTechnicianUnpaidTimeOff({ technicianId: technician.id })
  const { commissionChargebacks } = useTechnicianChargeBacks({ technicianId: technician.id })
  const { sickTimeOff } = useTechnicianSickTimeOff({ technicianId: technician.id })

  const handleLinkChecked = () => {
    setWaiting(true)
    setRequestLoading(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/move_selected_time_cards_for_technician_to_payout`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        timeCardIds: selectedTimeCardIds,
        userId: user.id,
        technicianId: technician.id,
      }),
    }).then(() => {
      setTimeout(() => {
        setRequestLoading(false)
        setRequestSuccess('Time cards moved to payout')
        refetch()
      }, 500)
    })
  }

  let quotes = []
  timeCards.forEach(tc => tc.quotes.forEach(item => quotes.push(item)), [])

  const timeCardsWithPayoutData = timeCards.map(timeCard => ({
    ...timeCard,
    payoutData: calculateTimeCardPayoutData({ timeCard, technician }),
  }))

  const quotesThatShouldBePaidOut = timeCardsWithPayoutData.reduce(
    (acc, timeCard) => [...acc, ...timeCard.payoutData.quoteIdsToClaimForPayroll],
    []
  )

  const getUniqueQuotes = quotes => {
    let seen = {}
    let uniqueQuotes = []

    for (let i = 0; i < quotes.length; i++) {
      let quote = quotes[i]

      if (!seen.hasOwnProperty(quote.id)) {
        seen[quote.id] = true
        uniqueQuotes.push(quote)
      }
    }

    return uniqueQuotes
  }

  quotes = quotes.filter(quote => quotesThatShouldBePaidOut.includes(quote.id))

  quotes = getUniqueQuotes(quotes)

  const payoutCalculations = calculatePayoutData({
    reimbursements: [],
    pto: ballerMode ? paidTimeOff : [],
    uto: ballerMode ? unpaidTimeOff : [],
    sto: ballerMode ? sickTimeOff : [],
    manualCompensations: [],
    chargeBacks: ballerMode ? commissionChargebacks : [],
    timeCards: timeCards,
    quotes: quotes,
    bToBInvoices: [],
    bToBPayouts: [],
    technician: technician,
    loanDeductionAmount: 0,
    otherLoanDeductionAmount: 0,
  })

  const {
    minimumPayAdjustment,
    quoteTotalLaborAmount,
    totalHourlyPay,
    totalCommissionAmount,
    gustoWageAmount,
    overtimeHours,
    overtimePay,
    payoutTotalOverLaborTotal,
  } = payoutCalculations

  const allTimeCardsAreSelected = JSON.stringify(selectedTimeCardIds) === JSON.stringify(timeCards.map(tc => tc.id))
  const totalHourlyAndCommission = Number(totalCommissionAmount) + Number(totalHourlyPay)

  return (
    <Box sx={{ padding: '.5rem', border: '1px solid #ddd', borderRadius: '12px', mb: '1rem' }}>
      <Box sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
        <Typography color='textSecondary'>
          <Link target='_blank' to={`/technicians/${technician.id}`}>
            <b>
              {technician.firstName} {technician.lastName}
              {technician.contractorIsBusiness ? `(${technician.businessName})` : ''}
            </b>
          </Link>
          {(technician.hourlyRate && <small>&nbsp;&#8212;&nbsp; ${technician.hourlyRate}/hour</small>) || ''}
          {(technician.commissionAmount && (
            <small>
              &nbsp;&#8212;&nbsp; <Dollars value={technician?.commissionAmount || 0} /> commission amount
            </small>
          )) ||
            ''}
        </Typography>
        <FormControlLabel
          sx={{
            '& .MuiTypography-root': {
              fontSize: '12px !important',
            },
            ml: 'auto',
          }}
          control={
            <Switch
              size='small'
              checked={ballerMode}
              onChange={e => {
                e.stopPropagation()
                setBallerMode(!ballerMode)
              }}
            />
          }
          label='Show with time off and chargeback calculations'
        />
        <Button
          disableRipple
          sx={{ '& svg': { transform: collapseOpen ? 'rotate(90deg)' : 'rotate(0deg)' } }}
          size='small'
          onClick={() => setCollapseOpen(!collapseOpen)}
        >
          <ChevronRightRounded />
        </Button>
      </Box>
      <Collapse in={collapseOpen}>
        <Collapse in={ballerMode}>
          <PaidTimeOff prefetched={ballerMode ? paidTimeOff : []} technician={technician} /> <br />
          <UnpaidTimeOff prefetched={ballerMode ? unpaidTimeOff : []} technician={technician} /> <br />
          <SickTimeOff prefetched={ballerMode ? sickTimeOff : []} technician={technician} /> <br />
          <CommissionChargeBacks prefetched={ballerMode ? commissionChargebacks : []} technician={technician} />
        </Collapse>

        <Table size='small'>
          <TimeCardTableHeadRow
            selectAll={() =>
              allTimeCardsAreSelected ? setSelectedTimeCardIds([]) : setSelectedTimeCardIds(timeCards.map(tc => tc.id))
            }
            allSelected={allTimeCardsAreSelected}
            unacceptButton
          />
          <TableBody>
            {timeCards
              .map(timeCard => keysToCamel(timeCard))
              .map((timeCard, index) => (
                <TimeCardPayoutRow
                  refetch={refetch}
                  timeCardIsSelected={selectedTimeCardIds.includes(timeCard.id)}
                  selectTimeCard={() =>
                    selectedTimeCardIds.includes(timeCard.id)
                      ? setSelectedTimeCardIds(prev => prev.filter(id => id !== timeCard.id))
                      : setSelectedTimeCardIds(prev => [...prev, timeCard.id])
                  }
                  index={index}
                  key={timeCard.id}
                  timeCard={timeCard}
                  unacceptButton
                  expandJobsArrow
                />
              ))}
            <TimeCardSumRow unacceptButton timeCards={timeCards} />
          </TableBody>
        </Table>

        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', textAlign: 'right' }}>
          <Button
            onClick={handleLinkChecked}
            disabled={waiting}
            variant='contained'
            color='primary'
            endIcon={<ArrowRightIcon />}
            size='small'
          >
            Move to Payout
          </Button>
          <Button
            size='small'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => setPayoutDataOpen(!payoutDataOpen)}
          >
            Open payout data
          </Button>
          <Box
            sx={{
              background: '#f1f1f1',
              borderRadius: '6px',
              padding: '.125rem .5rem',
              marginTop: '.5rem',
              ml: 'auto',
            }}
          >
            <span style={{ fontSize: '11px' }}>Quote labor total:</span>
            <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
              <Dollars value={quoteTotalLaborAmount} />
            </Box>
          </Box>

          <Box
            sx={{
              background: '#f1f1f1',
              borderRadius: '6px',
              padding: '.125rem .5rem',
              marginTop: '.5rem',
              ml: '.5rem',
              mr: '.5rem',
            }}
          >
            <span style={{ fontSize: '11px' }}>Hours (regular/OT)</span>
            <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
              <span>
                {(payoutCalculations.totalTimeCardHours - payoutCalculations.overtimeHours).toFixed(2)}/
                {payoutCalculations.overtimeHours}
              </span>
            </Box>
          </Box>

          {payoutTotalOverLaborTotal !== Infinity && (
            <Box
              sx={{
                background: '#f1f1f1',
                borderRadius: '6px',
                padding: '.125rem .5rem',
                marginTop: '.5rem',
                ml: '.5rem',
              }}
            >
              <span style={{ fontSize: '11px' }}>Technician Cost (Percentage):</span>
              <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
                <span>{Number((payoutTotalOverLaborTotal ? payoutTotalOverLaborTotal : 1) * 100).toFixed(2)}%</span>
              </Box>
            </Box>
          )}

          {minimumPayAdjustment > 0 && (
            <Box
              sx={{
                background: '#f1f1f1',
                borderRadius: '6px',
                padding: '.125rem .5rem',

                marginTop: '.5rem',
                ml: '.5rem',
              }}
            >
              <span style={{ fontSize: '11px' }}>Minimum hourly pay increase:</span>
              <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
                <Dollars value={minimumPayAdjustment} />
                <b style={{ fontSize: '11px' }}>
                  ~{(Number(minimumPayAdjustment) / Number(technician.hourlyRate)).toFixed(2)} hour(s)
                </b>
              </Box>
            </Box>
          )}

          {overtimeHours > 0 && (
            <Box
              sx={{
                background: '#f1f1f1',
                borderRadius: '6px',
                padding: '.125rem .5rem',
                marginTop: '.5rem',
                ml: '.5rem',
              }}
            >
              <span style={{ fontSize: '11px' }}>Overtime pay:</span>
              <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
                <Dollars value={overtimePay} />
                <b style={{ fontSize: '11px' }}>{Number(overtimeHours).toFixed(2)} hour(s)</b>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              background: '#f1f1f1',
              borderRadius: '6px',
              padding: '.125rem .5rem',

              marginTop: '.5rem',
              ml: '.5rem',
              mr: '.5rem',
            }}
          >
            <span style={{ fontSize: '11px' }}>Hourly + commission:</span>
            <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
              <Dollars value={totalHourlyAndCommission} />
            </Box>
          </Box>

          <Box sx={{ background: '#f1f1f1', borderRadius: '6px', padding: '.125rem .5rem', marginTop: '.5rem' }}>
            <span style={{ fontSize: '11px' }}>Tech payout total (with tips):</span>
            <Box sx={{ '& span': { fontWeight: 600, fontSize: '22px' } }}>
              <Dollars value={gustoWageAmount} />
            </Box>
          </Box>
        </Box>
      </Collapse>
      {payoutDataOpen && (
        <PayoutDataPopover
          payoutDataOpen={payoutDataOpen}
          setPayoutDataOpen={setPayoutDataOpen}
          data={payoutCalculations}
        />
      )}
    </Box>
  )
}

const BToCEmployees = () => {
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)
  const { startDatetime, endDatetime } = useContext(ConfigurationContext)
  const [waiting, setWaiting] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [requestLoading, setRequestLoading] = useState(false)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      hasPayoutId: false,
      isDeleted: false,
      isAccepted: true,
      startDatetime: startDatetime,
      endDatetime: endDatetime,
    },
  })

  useEffect(() => {
    data && refetch && refetch()
  }, [])

  useEffect(() => {
    const key = registerUpdateRefetch({ types: ['TimeCard'], refetch })
    return () => unregisterUpdateRefetch(key)
  }, [])

  if (error) return <div>Error!</div>

  const timeCards = keysToCamel(data?.timeCards || []) || []

  const acceptedTimeCards = timeCards.filter(timeCard => timeCard.acceptedAt)
  const timeCardsGroupedByTechnician = acceptedTimeCards
    .filter(tc => tc.technician.quoteCommissionStructure === 'hourly')
    .reduce(
      (acc, timeCard) =>
        acc[timeCard.technicianId]
          ? { ...acc, [timeCard.technicianId]: [...acc[timeCard.technicianId], timeCard] }
          : { ...acc, [timeCard.technicianId]: [timeCard] },
      {}
    )

  const handleClickGeneratePayouts = () => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/generate_all_timecard_payouts`, {
      method: 'POST',
      headers: API_COMMAND_HEADERS,
      body: JSON.stringify({
        startDatetime: startDatetime,
        endDatetime: endDatetime,
      }),
    })
      .then(res => {
        res.ok ? refetch() : alert('Error')
      })

      .finally(() => setWaiting(false))
  }

  return (
    <Box sx={{ pb: '2rem' }}>
      <Snackbar
        onClose={() => setRequestSuccess(false)}
        open={!!requestSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5', mr: '1rem', height: '20px', width: '20px' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' },
          mb: '3rem',
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutlineRounded /> {requestSuccess}
          </Box>
        }
      />
      <Snackbar
        onClose={() => setRequestLoading(false)}
        open={requestLoading}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': { color: '#3f51b5', background: 'aliceblue', border: '1px solid #a1cff7' },
          '& svg': { color: '#3f51b5' },
          '& .MuiSnackbarContent-message': { color: '#3f51b5' },
          mb: '3rem',
        }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={'1rem'} sx={{ mr: '1rem' }} /> Loading...{' '}
          </Box>
        }
      />

      {waiting && (
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111140',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            zIndex: 100,
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}

      {data && timeCards.length === 0 && (
        <Box
          sx={{
            zIndex: 1,
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#11111180',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            color: '#fff',
            backdropFilter: 'blur(2px)',
            '& svg': {
              height: '40px',
              width: '40px',
              mb: '.5rem',
            },
          }}
        >
          <CheckCircleOutlineRounded />
          <Typography variant='body1' sx={{ fontSize: '24px' }}>
            All Employee Time Cards Have Been Added
          </Typography>
        </Box>
      )}

      <Button
        sx={{ position: 'fixed', bottom: '1rem', right: '1rem', '& svg': { ml: '.5rem' }, zIndex: 9999 }}
        disabled={waiting}
        variant='contained'
        color='primary'
        onClick={handleClickGeneratePayouts}
      >
        move all to payouts <KeyboardArrowRightRounded />
      </Button>

      <Box sx={{ padding: '.25rem' }}>
        {(loading || timeCards.length === 0) && (
          <>
            {[1, 2, 3, 4, 5, 6, 7].map(i => (
              <Card key={i} sx={{ mb: '1rem ' }}>
                <Box sx={{ width: '150px', padding: '.5rem 1rem' }}>
                  <Skeleton
                    sx={{
                      transform: 'scale(1)',
                      minHeight: '32px',
                    }}
                  />
                </Box>
                <Table size='small'>
                  <TimeCardTableHeadRow unacceptButton />
                  <TableBody>
                    {[1, 2].map(row => (
                      <SkeletonRow key={row} />
                    ))}
                  </TableBody>
                </Table>
              </Card>
            ))}
          </>
        )}

        {!loading &&
          Object.entries(timeCardsGroupedByTechnician).map(group => (
            <PayoutPreview
              requestLoading={requestLoading}
              setRequestLoading={setRequestLoading}
              setRequestSuccess={setRequestSuccess}
              refetch={refetch}
              waiting={waiting}
              setWaiting={setWaiting}
              key={group[0]}
              timeCards={group[1]}
            />
          ))}
      </Box>
    </Box>
  )
}

export const TimeCardSumRow = ({ timeCards, hideCheckbox, showDiscludeButton, unacceptButton, cached }) => {
  const { technician } = timeCards[0]

  const timeCardsWithPayoutData = cached
    ? timeCards.map(tc => ({
        ...tc,
        payoutData: tc.payoutData
          ? tc.payoutData
          : {
              totalPayout: 0,
              totalHours: 0,
              totalHourlyPay: 0,
              totalWorkHours: 0,
              totalPaidHours: 0,
              totalUnpaidHours: 0,
              totalCommissionAmount: 0,
              totalTipsForDay: 0,
              totalOvertimeHours: 0,
              totalOvertimePay: 0,
              residentialTotalHourlyPay: 0,
              residentialTotalHours: 0,
              residentialTotalPayout: 0,
              bToBTotalHourlyPay: 0,
              bToBTotalHours: 0,
              bToBTotalPayout: 0,
              paidTravelTotalHourlyPay: 0,
              paidTravelTotalHours: 0,
              paidTravelTotalPayout: 0,
              paidTravelTotalDistance: 0,
              unpaidTravelTotalHours: 0,
              unpaidTravelTotalDistance: 0,
              travelTotalDistance: 0,
              numJobs: 0,
              techHourlyRate: 0,
              techCommissionRate: 0,
              quoteIdsToClaimForPayroll: [],
            },
      }))
    : timeCards.map(tc => ({
        ...tc,
        payoutData: calculateTimeCardPayoutData({ timeCard: tc, technician }),
      }))

  // const timeCardsWithPayoutData = timeCards.map(tc => ({
  //   ...tc,
  //   payoutData: calculateTimeCardPayoutData({ timeCard: tc }),
  // }))

  const totalTipAmount = timeCardsWithPayoutData.reduce((acc, timeCard) => acc + timeCard.payoutData.totalTipsForDay, 0)
  const totalHourlyPay = timeCardsWithPayoutData.reduce((acc, timeCard) => acc + timeCard.payoutData.totalHourlyPay, 0)
  const totalJobCommission = timeCardsWithPayoutData.reduce(
    (acc, timeCard) => acc + timeCard.payoutData.totalCommissionAmount,
    0
  )
  const totalPaidHours = timeCardsWithPayoutData.reduce((acc, timeCard) => acc + timeCard.payoutData.totalPaidHours, 0)
  const totalHours = timeCardsWithPayoutData.reduce((acc, timeCard) => acc + timeCard.payoutData.totalHours, 0)
  const totalworkHours = timeCardsWithPayoutData.reduce((acc, timeCard) => acc + timeCard.payoutData.totalWorkHours, 0)
  const totalPaidTravelHours = timeCardsWithPayoutData.reduce(
    (acc, timeCard) => acc + timeCard.payoutData.paidTravelTotalHours,
    0
  )
  const totalUnpaidTravelHours = timeCardsWithPayoutData.reduce(
    (acc, timeCard) => acc + timeCard.payoutData.unpaidTravelTotalHours,
    0
  )
  const jobsTotal = timeCardsWithPayoutData.reduce((acc, timeCard) => acc + timeCard.payoutData.numJobs, 0)
  const totalPayoutAmount = timeCardsWithPayoutData.reduce((acc, timeCard) => acc + timeCard.payoutData.totalPayout, 0)
  const totalMiles = timeCardsWithPayoutData.reduce((acc, timeCard) => acc + timeCard.payoutData.travelTotalDistance, 0)

  const overtimeHours = (technician?.state !== 'CA' && totalPaidHours - 40) || 0
  const overtimeAdjustment = (technician?.state !== 'CA' && overtimeHours * (technician?.hourlyRate * 1.5)) || 0
  const additionalOvertimeForHourlySum =
    (overtimeAdjustment > 0 && technician?.state !== 'CA' && overtimeHours * (technician?.hourlyRate * 0.5)) || 0

  return (
    <TableRow sx={{ fontWeight: 'bold', background: 'aliceblue', borderTop: '2px solid #a1cff7' }}>
      {!hideCheckbox && <TableCell align='right'> </TableCell>}
      {!hideCheckbox && <TableCell align='right'> </TableCell>}
      {hideCheckbox && <TableCell align='right'> </TableCell>}

      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        {jobsTotal}
      </TableCell>
      <TableCell align='right'>{totalMiles.toFixed(2)} mi</TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        {totalUnpaidTravelHours.toFixed(2)}
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        {totalPaidTravelHours.toFixed(2)}
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        {totalworkHours.toFixed(2)}
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        {totalHours.toFixed(2)}
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        {totalPaidHours.toFixed(2)}
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        <Dollars value={totalTipAmount} />
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        {(overtimeAdjustment > 0 && (
          <span style={{ fontSize: '11px' }}>
            (<Dollars value={overtimeAdjustment} /> OT)
          </span>
        )) ||
          ''}
        <Dollars value={totalHourlyPay + additionalOvertimeForHourlySum} />
      </TableCell>
      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        <Dollars value={totalJobCommission} />
      </TableCell>

      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
        <Dollars value={totalPayoutAmount} />
      </TableCell>
      {showDiscludeButton && <TableCell align='right'></TableCell>}
      {unacceptButton && <TableCell align='right'></TableCell>}
    </TableRow>
  )
}

export const TimeCardTableHeadRow = ({ selectAll, allSelected, hideCheckbox, showDiscludeButton, unacceptButton }) => {
  return (
    <TableHead>
      <TableRow>
        {!hideCheckbox && (
          <TableCell sx={{ maxWidth: '60px', padding: '0rem .5rem' }}>
            <Checkbox
              sx={{
                padding: '0px',
                '& svg': {
                  height: '15px',
                  width: '15px',
                },
              }}
              size='small'
              checked={allSelected}
              onClick={() => selectAll()}
            />
          </TableCell>
        )}
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Date
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          # Jobs
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Distance
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Unpaid Travel Hours
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Paid Travel Hours
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Work Hours
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Total Hours
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Total paid hours
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Tips total
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Hourly pay
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Job Commission
        </TableCell>
        <TableCell sx={{ fontSize: '12px' }} align='right'>
          Total payout
        </TableCell>
        {showDiscludeButton && (
          <TableCell sx={{ fontSize: '12px' }} align='right'>
            Action
          </TableCell>
        )}
        {unacceptButton && <TableCell align='right'></TableCell>}
      </TableRow>
    </TableHead>
  )
}

const SkeletonRow = () => {
  return (
    <TableRow>
      <TableCell sx={{ maxWidth: '60px', padding: '0rem .5rem' }}>
        <Checkbox
          sx={{
            padding: '0px',
            '& svg': {
              height: '15px',
              width: '15px',
            },
          }}
          size='small'
        />
      </TableCell>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 11].map((i, index) => (
        <TableCell key={i}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '28px',
              transform: 'scale(1)',
              background: index % 2 !== 0 ? '#0089CD1a' : '#f1f1f1',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}

export const JobSkeletonRow = () => {
  return (
    <TableRow>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 11, 12, 13].map((i, index) => (
        <TableCell key={i}>
          <Skeleton
            sx={{
              minWidth: '100%',
              minHeight: '28px',
              transform: 'scale(1)',
              background: index % 2 !== 0 ? '#0089CD1a' : '#f1f1f1',
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}

export default BToCEmployees
