import { calculateBToBJobPayoutData } from './bToBJob'
import { calculateTravelLogPayoutData } from './travellogs'
import { TechnicianType } from './types/technician'
import { TimeCardType } from './types/timecard'
import { calculateWorklogPayoutData } from './worklogs'

export const calculateTimeCardPayoutData = ({
  timeCard,
  technician,
}: {
  timeCard: TimeCardType
  technician: TechnicianType
}) => {
  const workLogs = timeCard.workLogs.slice()
  const travelLogs = timeCard.travelLogs.slice()
  const bToBJobs = timeCard.bToBJobsLogs.slice()
  const residentialWorkLogs = workLogs.filter((wl: any) => wl.jobId)
  const numJobs = residentialWorkLogs.length + bToBJobs.length
  const unpaidTravel = travelLogs.filter((tl: any) => !tl.isPaid)
  const paidTravel = travelLogs.filter((tl: any) => !!tl.isPaid)

  const residentialWorklogPayoutData = residentialWorkLogs.map((wl: any) => ({
    ...wl,
    payoutData: calculateWorklogPayoutData({ workLog: wl, technician }),
  }))

  const travelLogPayoutData = paidTravel.map((tl: any) => ({
    ...tl,
    payoutData: calculateTravelLogPayoutData({ travelLog: tl, technician }),
  }))

  const bToBJobPayoutData = bToBJobs.map((b: any) => ({
    ...b,
    payoutData: calculateBToBJobPayoutData({ workLog: { ...b, technician } }),
  }))

  const unpaidTravelHours = Number(
    unpaidTravel.reduce((acc: any, item: any) => acc + Number(item.totalHours), 0).toFixed(2)
  )

  // ## RESIDENTIAL

  const totalCommissionAmount = Number(
    residentialWorklogPayoutData
      .reduce((acc: any, item: any) => acc + Number(item.payoutData.commissionAmount) || 0, 0)
      .toFixed(2)
  )

  const residentialHoursTotal = Number(
    residentialWorklogPayoutData
      .reduce((acc: any, item: any) => acc + Number(item.payoutData.workedHours) || 0, 0)
      .toFixed(2)
  )

  const residentialTotalHourlyPay = Number(
    residentialWorklogPayoutData
      .reduce((acc: any, item: any) => acc + Number(item.payoutData.hourlyPay) || 0, 0)
      .toFixed(2)
  )

  const residentialTotalPayout = Number(
    residentialWorklogPayoutData
      .reduce((acc: any, item: any) => acc + Number(item.payoutData.totalPayout) || 0, 0)
      .toFixed(2)
  )

  const totalTipsForDay = Number(
    residentialWorklogPayoutData.reduce((acc: any, item: any) => acc + Number(item.payoutData.tip) || 0, 0).toFixed(2)
  )

  // ## B2B
  const bToBTotalHours = Number(
    bToBJobPayoutData.reduce((acc: any, item: any) => acc + Number(item.payoutData.workedHours) || 0, 0).toFixed(2)
  )

  const bToBTotalHourlyPay = Number(
    bToBJobPayoutData.reduce((acc: any, item: any) => acc + Number(item.payoutData.hourlyPay) || 0, 0).toFixed(2)
  )

  const bToBTotalPayout = Number(
    bToBJobPayoutData.reduce((acc: any, item: any) => acc + Number(item.payoutData.totalPayout) || 0, 0).toFixed(2)
  )

  // ### TRAVEL

  const totalPaidTravelHours = Number(
    travelLogPayoutData
      .reduce((acc: any, item: any) => acc + Number(item.payoutData.paidTravelHours) || 0, 0)
      .toFixed(2)
  )

  const travelTotalHourlyPay = Number(
    travelLogPayoutData.reduce((acc: any, item: any) => acc + Number(item.payoutData.hourlyPay) || 0, 0).toFixed(2)
  )

  const paidTravelTotalDistance = Number(
    (paidTravel.reduce((acc: any, item: any) => acc + Number(item.travelDistance) || 0, 0) || 0).toFixed(2)
  )

  const unpaidTravelTotalDistance = Number(
    (unpaidTravel.reduce((acc: any, item: any) => acc + Number(item.travelDistance), 0) || 0).toFixed(2)
  )

  const totalTravelDistance = Number(
    travelLogs.reduce((acc: any, item: any) => acc + Number(item.travelDistance) || 0, 0).toFixed(2)
  )

  const totalWorkHours = Number((Number(bToBTotalHours) + Number(residentialHoursTotal)).toFixed(2))
  const totalPaidHours = Number(
    (Number(residentialHoursTotal) + Number(totalPaidTravelHours) + Number(bToBTotalHours)).toFixed(2)
  )

  const totalHours = Number((Number(unpaidTravelHours) + Number(totalPaidHours)).toFixed(2))

  let totalHourlyPay: any = Number(
    (Number(travelTotalHourlyPay) + Number(bToBTotalHourlyPay) + Number(residentialTotalHourlyPay)).toFixed(2)
  )

  let totalPayout: any = Number(
    (Number(totalCommissionAmount) + Number(totalTipsForDay) + Number(totalHourlyPay)).toFixed(2)
  )

  // the quote ids should only be those that are paying commission
  const quoteIdsToClaimForPayroll = residentialWorklogPayoutData
    .filter((wl: any) => wl?.payoutData?.commissionAmount > 0)
    .filter((wl: any) => wl.quoteId)
    .map((wl: any) => wl.quoteId)

  const bToBJobIdsToClaimForPayroll = bToBJobPayoutData.filter((wl: any) => wl.bToBJobId).map((wl: any) => wl.bToBJobId)

  let otTime = 0
  let otPay = 0
  let totalHourlyPayWithoutOvertime = totalHourlyPay

  if (technician.state === 'CA') {
    if (Number(totalPaidHours) > 8) {
      otTime = Number((Number(totalPaidHours) - 8).toFixed(2))
      otPay = Number((otTime * (technician.hourlyRate * 1.5)).toFixed(2))

      let halfRateOtPay: number = otTime * (technician.hourlyRate * 0.5)
      let overtimePayAddedOn: number = otTime * technician.hourlyRate
      totalHourlyPayWithoutOvertime -= overtimePayAddedOn

      // add the additional hourly pay to the total hourly pay
      totalHourlyPay += halfRateOtPay
      totalPayout += halfRateOtPay
    }
  }

  return {
    totalPayout: totalPayout,
    totalHours: totalHours,
    totalHourlyPay: totalHourlyPay,
    totalWorkHours: totalWorkHours,
    totalPaidHours: totalPaidHours,
    totalUnpaidHours: unpaidTravelHours,
    totalCommissionAmount: totalCommissionAmount,
    totalTipsForDay: totalTipsForDay,

    totalHourlyPayWithoutOvertime: totalHourlyPayWithoutOvertime,
    totalOvertimeHours: otTime,
    totalOvertimePay: otPay,

    residentialTotalHourlyPay: residentialTotalHourlyPay,
    residentialTotalHours: residentialHoursTotal,
    residentialTotalPayout: residentialTotalPayout,
    bToBTotalHourlyPay: bToBTotalHourlyPay,
    bToBTotalHours: bToBTotalHours,
    bToBTotalPayout: bToBTotalPayout,
    paidTravelTotalHourlyPay: travelTotalHourlyPay,
    paidTravelTotalHours: totalPaidTravelHours,
    paidTravelTotalPayout: travelTotalHourlyPay,
    paidTravelTotalDistance: paidTravelTotalDistance,
    unpaidTravelTotalHours: unpaidTravelHours,
    unpaidTravelTotalDistance: unpaidTravelTotalDistance,
    travelTotalDistance: totalTravelDistance,

    numJobs: numJobs,
    techHourlyRate: technician.hourlyRate,
    techCommissionRate: technician.commissionAmount,
    quoteIdsToClaimForPayroll: quoteIdsToClaimForPayroll || [],
    bToBJobIdsToClaimForPayroll: bToBJobIdsToClaimForPayroll || [],
  }
}
