import { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { UserContext } from 'UserStore'
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  Checkbox,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Dialog,
  IconButton
} from '@mui/material'
import { DeleteRounded, StrikethroughS } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceInput, Dollars, diff } from 'tools'
import 'react-super-select/lib/react-super-select.css'
import { useHistory } from 'react-router-dom'
import QuoteTotalsV2 from './components/QuoteTotalsV2'
import AddOtherLabor from '../components/AddOtherLabor'
import AddTechnicianCharge from '../components/AddTechnicianCharge'
import AddCarbodylabCharge from '../components/AddCarbodylabCharge'
import AddApprovedTechniciansDialog from '../components/AddApprovedTechnicansDialog.js'
import { techSkillsets } from 'data/techSkillsets.js'
// import { createDiffBetweenQuoteAndTemporaryQuote, produceTotalsFromQuoteItems } from './components'
import { newDiff, newTotals } from 'quoteCalculator'
import JSONPretty from 'react-json-pretty'
import { DateTime } from 'luxon'
import Parts from './components/Parts'
import { PrimaryLaborItems } from './components/PrimaryLaborItems'
import { EstimateApprovalButtons } from './components/EstimateApprovalButtons'

const VEHICLE_QUERY = gql`
  query vehicle($year: Int!, $makeShortName: String!, $modelShortName: String!) {
    vehicle(year: $year, makeShortName: $makeShortName, modelShortName: $modelShortName) {
      makePrettyName
      modelPrettyName
    }
  }
`

export const inputStyles = makeStyles(theme => ({
  inputField: {
    '& .MuiInput-root::before': {
      borderWidth: '0'
    },
    '& .MuiInput-root::before:hover': {
      borderWidth: '1px'
    },
    '& .MuiInput-root input': {
      borderWidth: '0',
      textAlign: 'right',
      fontSize: '15px'
    }
  }
}))

export const ListItemValue = ({ title, value }) => (
  <Grid container>
    <Grid item xs={9} style={{ textAlign: 'right' }}>
      {title}
    </Grid>
    <Grid item xs={3} style={{ textAlign: 'right' }}>
      {value}
    </Grid>
  </Grid>
)

export const SectionHeaderGrid = styled(Grid)(() => ({
  padding: '0.33em 1em',
  width: '100%',
  backgroundColor: 'aliceblue',
  color: '#3f51b5',
  fontWeight: 500
}))

export const SectionFooterGrid = styled(Grid)(() => ({
  backgroundColor: '#f2f2f2',
  '.MuiGrid-container:last-child': {
    marginBottom: '0.5em'
  }
}))

const OtherLaborCatalogItem = ({ item, disabled, handleItemChanged, handleItemDeleted }) => {
  const [user] = useContext(UserContext)
  const classes = inputStyles()

  return (
    <Grid container sx={{ px: 1 }}>
      <Grid item xs={8} sx={{ borderBottom: '1px dotted lightgray' }}>
        <TextField
          className={classes.inputField}
          fullWidth
          size='small'
          name='name'
          multiline
          inputProps={{ sx: { fontSize: '15px' } }}
          variant='standard'
          value={item.name || ''}
          onClick={e => e.stopPropagation()}
          error={(item.name || '').trim() === ''}
          disabled={disabled}
          onChange={e => {
            handleItemChanged(e, {
              ...item,
              name: e.target.value
            })
          }}
        />
      </Grid>

      <Grid item xs={2} style={{ paddingLeft: 0 }} sx={{ borderBottom: '1px dotted lightgray' }}>
        <TextField
          required
          variant='standard'
          className={classes.inputField}
          size='small'
          name='price'
          value={item.price}
          disabled={disabled}
          onChange={e => {
            handleItemChanged(e, {
              ...item,
              price: Number(e.target.value)
            })
          }}
          InputProps={{
            inputComponent: PriceInput
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid
        item
        xs={2}
        style={{
          borderBottom: '1px dotted lightgray',
          textAlign: 'right',
          padding: 0
        }}
      >
        <IconButton
          cy-data='void-labor-item-button'
          disabled={disabled || !user.roles.includes('admin')}
          onClick={e => handleItemChanged(e, { ...item, void: !item.void })}
          sx={{ p: 0.25 }}
        >
          <StrikethroughS fontSize='small' color={item.void ? 'warning' : ''} />
        </IconButton>
        <IconButton disabled={disabled} onClick={() => handleItemDeleted(item)} sx={{ p: 0.25 }}>
          <DeleteRounded fontSize='small' />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  gridRoot: {
    flexGrow: 1
  },
  emptyContainer: {
    marginTop: '1em'
  },
  inputField: {
    '& .MuiInput-root::before': {
      borderWidth: '0'
    },
    '& .MuiInput-root::before:hover': {
      borderWidth: '1px'
    },
    '& .MuiInput-root input': {
      borderWidth: '0',
      textAlign: 'right',
      fontSize: '15px'
    }
  }
}))

const addCalcPayloadPrimaryLabor = (calcPayload, laborObject, currentPhotoId) => ({
  ...calcPayload,
  primaryLaborItems: [{ ...laborObject, photoId: currentPhotoId }, ...calcPayload.primaryLaborItems]
})

const addCalcPayloadOtherLabor = (calcPayload, laborObject, currentPhotoId) => ({
  ...calcPayload,
  otherLaborItems: [{ ...laborObject, photoId: currentPhotoId }, ...calcPayload.otherLaborItems]
})

export const QuoteCalculatorV2 = ({ quote, refetchQuote, currentPhotoId, lead }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const classes = useStyles()
  const history = useHistory()
  const [payoutDataOpen, setPayoutDataOpen] = useState(false)

  const [waiting, setWaiting] = useState(false)
  const [user] = useContext(UserContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [calcPayload, setCalcPayload] = useState({ ...quote })
  const [editingMutex, setEditingMutex] = useState('')
  const [updateQuoteClickGate, setUpdateQuoteClickGate] = useState(true)

  const partIsActive = part => !part.canceledAt && !part.removedFromQuoteAt

  const quoteTotals = newTotals({ ...calcPayload, laborIsTaxable: quote.laborIsTaxable }, quote.lead)
  const temporaryQuote = { ...calcPayload, ...quoteTotals }

  const primaryLaborItems = temporaryQuote.primaryLaborItems
  const otherLaborItems = temporaryQuote.otherLaborItems
  const carbodylabCharges = temporaryQuote.carbodylabCharges
  const technicianCharges = temporaryQuote.technicianCharges
  const parts = temporaryQuote.parts

  const activeParts = parts.filter(part => !part.canceledAt && !part.removedFromQuoteAt)

  const quoteIsPaid = quote.paymentStatus === 'fully_paid'
  const quoteIsDisabled = !!quote.payoutId

  const quoteDiff = newDiff(quote, temporaryQuote)
  const quoteNeedsSaving = Object.keys(quoteDiff).length > 0

  useEffect(() => {
    // Check if there are changes from the last saved quote. This allows
    // us to block the apollo cache trampling of the quote when the
    // map/availability panel is opened
    const diffs = {
      ...diff(calcPayload, {
        primaryLaborItems: quote.primaryLaborItems,
        otherLaborItems: quote.otherLaborItems,
        carbodylabCharges: quote.carbodylabCharges,
        technicianCharges: quote.technicianCharges,
        offeredDiscount: quote.offeredDiscount,
        taxRate: quote.taxRate,
        parts: quote.parts
      }),
      ...diff(
        {
          primaryLaborItems: quote.primaryLaborItems,
          otherLaborItems: quote.otherLaborItems,
          carbodylabCharges: quote.carbodylabCharges,
          technicianCharges: quote.technicianCharges,
          offeredDiscount: quote.offeredDiscount,
          taxRate: quote.taxRate,
          parts: quote.parts
        },
        calcPayload
      )
    }

    if (Object.keys(diffs).length === 0) {
      return
    }

    setCalcPayload({
      primaryLaborItems: quote.primaryLaborItems,
      otherLaborItems: quote.otherLaborItems,
      carbodylabCharges: quote.carbodylabCharges,
      technicianCharges: quote.technicianCharges,
      offeredDiscount: quote.offeredDiscount,
      taxRate: quote.taxRate,
      parts: quote.parts
    })

    setUpdateQuoteClickGate(true)
  }, [quote])

  const handleClickResetQuote = () => {
    setUpdateQuoteClickGate(true)
    setCalcPayload({
      primaryLaborItems: quote.primaryLaborItems,
      otherLaborItems: quote.otherLaborItems,
      carbodylabCharges: quote.carbodylabCharges,
      technicianCharges: quote.technicianCharges,
      offeredDiscount: quote.offeredDiscount,
      taxRate: quote.taxRate,
      parts: quote.parts
    })
  }

  const handleLaborSelected = laborObject =>
    setCalcPayload(addCalcPayloadPrimaryLabor(calcPayload, laborObject, currentPhotoId))

  const handleOtherLaborSelected = laborObject =>
    setCalcPayload(addCalcPayloadOtherLabor(calcPayload, laborObject, currentPhotoId))

  const handleTechnicianChargeSelected = technicianChargeObject =>
    setCalcPayload({
      ...calcPayload,
      technicianCharges: [technicianChargeObject, ...calcPayload.technicianCharges]
    })

  const handleCarbodylabChargeSelected = carbodylabChargeObject =>
    setCalcPayload({
      ...calcPayload,
      carbodylabCharges: [carbodylabChargeObject, ...calcPayload.carbodylabCharges]
    })

  const handlePrimaryLaborChanged = (e, newValue) => {
    setCalcPayload({
      ...calcPayload,
      primaryLaborItems: calcPayload.primaryLaborItems.map(laborItem =>
        laborItem.id === newValue.id ? newValue : laborItem
      )
    })
  }

  const handleOtherLaborChanged = (e, newValue) => {
    setCalcPayload({
      ...calcPayload,
      otherLaborItems: calcPayload.otherLaborItems.map(laborItem =>
        laborItem.id === newValue.id ? newValue : laborItem
      )
    })
  }
  const handleDeletedPrimaryLaborId = laborItemId =>
    setCalcPayload({
      ...calcPayload,
      primaryLaborItems: calcPayload.primaryLaborItems.filter(laborItem => laborItem.id !== laborItemId)
    })

  const handleDeletedOtherLaborId = laborItemId =>
    setCalcPayload({
      ...calcPayload,
      otherLaborItems: calcPayload.otherLaborItems.filter(laborItem => laborItem.id !== laborItemId)
    })

  const handleTechnicianChargeUpdated = (e, newValue) => {
    setCalcPayload({
      ...calcPayload,
      technicianCharges: calcPayload.technicianCharges.map(chargeItem =>
        chargeItem.id === newValue.id ? newValue : chargeItem
      )
    })
  }
  const handleDeletedTechnicianCharge = chargeId => {
    const filteredTechnicianCharges = calcPayload.technicianCharges.filter(charge => charge.id !== chargeId)
    setCalcPayload({
      ...calcPayload,
      ...{ technicianCharges: filteredTechnicianCharges }
    })
  }

  const handleCarbodylabChargeUpdated = (e, newValue) => {
    setCalcPayload({
      ...calcPayload,
      carbodylabCharges: calcPayload.carbodylabCharges.map(chargeItem =>
        chargeItem.id === newValue.id ? newValue : chargeItem
      )
    })
  }
  const handleDeletedCarbodylabCharge = chargeId => {
    const carbodylabCharges = calcPayload.carbodylabCharges.filter(charge => charge.id !== chargeId)
    setCalcPayload({ ...calcPayload, carbodylabCharges })
  }

  const updateQuoteDisabled =
    (quote.paymentStatus === 'fully_paid' && !user.roles.includes('eric_and_rudy')) || !quoteNeedsSaving

  const handleSetOfferedDiscount = event =>
    setCalcPayload({
      ...calcPayload,
      ...{ offeredDiscount: Number(event.target.value) }
    })

  const duplicateButtonShouldBeDisabled = quote.paymentStatus === 'fully_paid'

  const handleDuplicateQuote = e => {
    if (window.confirm('Are you sure you want to duplicate this?')) {
      setIsSubmitting(true)
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/commands/duplicate_quote`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          quote_id: quote.id
        })
      })
        .then(res => {
          if (res.status === 200) {
            res.json().then(res => {
              setIsSubmitting(false)
              history.push(`/leads/${lead.id}/quotes/${res.quote_id}`)
              setTimeout(() => alert('You have arrived at the duplicate quote.'))
            })
          } else {
            setIsSubmitting(false)
            alert('An error occured')
          }
        })
        .catch(() => alert('An error occured'))
    }
  }

  const handleUpdateQuoteSkillsets = v => {
    setWaiting(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_quote_skillsets`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quote_id: quote.id,
        skillsets: v
      })
    })
      .then(res => {
        if (res.status === 200) {
          refetchQuote()
          setTimeout(() => setWaiting(false), 500)
        } else {
          alert('An error occured')
        }
      })
      .catch(() => alert('An error occured'))
  }

  const handleUpdateQuoteDifficulty = e => {
    setWaiting(true)
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_quote_difficulty`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quote_id: quote.id,
        difficulty_level: e.target.value
      })
    })
      .then(res => {
        if (res.status === 200) {
          refetchQuote()
          setTimeout(() => setWaiting(false), 500)
        } else {
          alert('An error occured')
        }
      })
      .catch(() => alert('An error occured'))
  }

  const isEditingParts = editingMutex === 'parts' || editingMutex === 'add-part'

  const {
    loading: vehicleLoading,
    error: vehicleError,
    data: vehicleData
  } = useQuery(VEHICLE_QUERY, {
    variables: {
      year: lead.year,
      makeShortName: lead.makeShortName || '',
      modelShortName: lead.modelShortName || ''
    }
  })

  if (vehicleLoading) return <div>LOADING...</div>
  if (vehicleError) return <div>Error!</div>

  const vehicle = vehicleData.vehicle

  const handleClickUpdateQuote = () => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_quote_with_diff`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        quote_id: quote.id,
        user_id: user.id,
        diff: {
          ...quoteDiff,
          totals: {
            ...quoteTotals
          }
        }
      })
    })
      .then(res => {
        if (res.ok) {
          refetchQuote()
          setUpdateQuoteClickGate(true)
          setTimeout(() => setWaiting(false), 500)
        } else {
          window.alert('Error')
        }
      })
      .catch(() => alert('An error occured'))

    // fetch(`https://quote-calculator.carbodylab.local/generate_tax_calculations`, {
    //   method: 'POST',
    //   headers: bearerTokenHeaders,
    //   body: JSON.stringify({
    //     quote: temporaryQuote,
    //     lead: quote.lead,
    //   }),
    // })
    //   .then(res => res.json().then(res => console.log({ res })))
    //   .finally(() => setWaiting(false))
  }

  const quoteActions = (
    <Grid container sx={{ p: 1, justifyContent: 'space-between' }}>
      <Grid item>
        {user.roles.includes('developer') && (
          <>
            <button onClick={() => setPayoutDataOpen(!payoutDataOpen)}>
              {payoutDataOpen ? 'hide' : 'view'} full calculations
            </button>{' '}
            <br />
          </>
        )}
        <Button
          variant='outlined'
          style={{ minWidth: '157px', height: '38px' }}
          disabled={updateQuoteDisabled}
          onClick={handleClickUpdateQuote}
          color='primary'
          cy-data='save-quote-button'
        >
          {waiting ? <CircularProgress size={18} /> : <>Save Changes {quoteIsPaid ? 'fully paid already!' : ''}</>}
        </Button>
        <Button disabled={duplicateButtonShouldBeDisabled} onClick={handleDuplicateQuote} sx={{ ml: '1rem' }}>
          Duplicate
        </Button>
      </Grid>

      <Grid item>
        <Button
          variant='outlined'
          // style={{ minWidth: '157px', height: '38px' }}
          disabled={updateQuoteDisabled}
          onClick={handleClickResetQuote}
          color='primary'
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  )

  return (
    <>
      {payoutDataOpen && (
        <Dialog onClose={() => setPayoutDataOpen(false)} open={payoutDataOpen}>
          <JSONPretty style={{ fontSize: '12px' }} data={temporaryQuote} />
        </Dialog>
      )}

      <Grid
        item
        xs={4}
        style={{ padding: 0 }}
        sx={{
          border: '4px solid',
          borderColor: quoteNeedsSaving ? '#ffad50' : 'aliceblue',
          borderRadius: '3px'
        }}
      >
        {quoteActions}
        {user.roles.includes('admin') && quote?.inspektlabsEstimate && (
          <Box>
            <SectionHeaderGrid
              item
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
              xs={12}
            >
              <span> InspektLabs Estimate </span>
              <EstimateApprovalButtons estimateId={quote?.inspektlabsEstimate?.id} />
            </SectionHeaderGrid>
          </Box>
        )}
        <Box>
          <SectionHeaderGrid
            item
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            xs={12}
          >
            <span> Skillsets and Difficulty </span>
            <AddApprovedTechniciansDialog quote={quote} refetchQuote={refetchQuote} />
          </SectionHeaderGrid>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '.75rem .5rem'
            }}
          >
            <Autocomplete
              sx={{
                display: 'inline-block',
                minWidth: '225px'
              }}
              name='requiredSkillsets'
              multiple
              size='small'
              options={techSkillsets.filter(skill => !skill.includes('level') && !skill.includes('part replacements'))}
              disableCloseOnSelect
              value={quote.requiredSkillsets}
              onChange={(e, v) => handleUpdateQuoteSkillsets(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} size='small' />
                  {option}
                </li>
              )}
              renderInput={params => (
                <TextField size='small' {...params} label='Required Skillsets' placeholder='Add a skillset' />
              )}
            />
            <FormControl sx={{ minWidth: '125px' }} size='small' variant='outlined'>
              <InputLabel>Difficulty</InputLabel>
              <Select label='Difficulty level' value={quote.difficultyLevel} onChange={handleUpdateQuoteDifficulty}>
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                {techSkillsets
                  .filter(skill => skill.includes('level'))
                  .map(level => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <PrimaryLaborItems
          primaryLaborItems={primaryLaborItems}
          quote={quote}
          handleLaborSelected={handleLaborSelected}
          handlePrimaryLaborChanged={handlePrimaryLaborChanged}
          handleDeletedPrimaryLaborId={handleDeletedPrimaryLaborId}
          quoteNeedsSaving={quoteNeedsSaving}
          temporaryQuote={temporaryQuote}
          isEditingParts={isEditingParts}
        />

        {/* PARTS */}
        <Parts
          quote={quote}
          editingDisabled={quoteNeedsSaving}
          refetchQuote={refetchQuote}
          parts={activeParts}
          currentPhotoId={currentPhotoId}
          editingMutex={editingMutex}
          setEditingMutex={setEditingMutex}
          updateQuoteClickGate={updateQuoteClickGate}
        />

        {parts.length > 0 && (
          <SectionFooterGrid item xs={12} sx={{ px: 2 }}>
            {parts.total > 0 && (
              <b>
                <ListItemValue
                  style={{ textAlign: 'right' }}
                  title='subtotal'
                  value={<Dollars value={temporaryQuote.partsTotal} />}
                />
              </b>
            )}
          </SectionFooterGrid>
        )}
        {/* END PARTS */}

        {parts.length > 0 && (
          <SectionFooterGrid item xs={12} sx={{ px: 2 }}>
            {parts.total > 0 && (
              <b>
                <ListItemValue
                  style={{ textAlign: 'right' }}
                  title='subtotal'
                  value={<Dollars value={temporaryQuote.partsTotal} />}
                />
              </b>
            )}
          </SectionFooterGrid>
        )}

        {/* OTHER LABOR */}
        <SectionHeaderGrid item xs={12}>
          Other Labor
        </SectionHeaderGrid>

        <AddOtherLabor quote={quote} disabled={isEditingParts} onSave={handleOtherLaborSelected} />

        {otherLaborItems.length > 0 && (
          <Grid item xs={12}>
            {otherLaborItems
              .sort((a, b) => (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? 1 : -1))
              .map(item => (
                <OtherLaborCatalogItem
                  key={item.id}
                  item={item}
                  disabled={isEditingParts}
                  handleItemChanged={handleOtherLaborChanged}
                  handleItemDeleted={labor => handleDeletedOtherLaborId(labor.id)}
                />
              ))}
          </Grid>
        )}

        {temporaryQuote.otherLaborSum > 0 && (
          <SectionFooterGrid item xs={12} sx={{ px: 2 }}>
            {otherLaborItems.length > 0 && (
              <b>
                <ListItemValue
                  style={{ textAlign: 'right' }}
                  title='subtotal'
                  value={<Dollars value={temporaryQuote.otherLaborSum} />}
                />
              </b>
            )}
          </SectionFooterGrid>
        )}
        {/* END OTHER LABOR */}

        {/* LABOR SUMMARY */}
        <SectionHeaderGrid item xs={12}>
          All Labor summary
        </SectionHeaderGrid>

        <SectionFooterGrid item xs={12} sx={{ px: 2 }}>
          <Grid container>
            <Grid item xs={9} style={{ textAlign: 'right' }}>
              all labor subtotal (
              <Dollars value={temporaryQuote.primaryLaborSumAfterVolumeDiscount} />
              ) + (
              <Dollars value={temporaryQuote.otherLaborSum} />)
            </Grid>

            <Grid item xs={3} style={{ textAlign: 'right' }}>
              <Dollars value={temporaryQuote.laborSumAfterVolumeDiscount} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={9} style={{ textAlign: 'right' }}>
              Labor Discount&nbsp;
              <small>(shown on Quote Page, ${`${temporaryQuote.laborSumAfterVolumeDiscount}`} max.)</small>
            </Grid>

            <Grid item xs={3}>
              <TextField
                size='small'
                variant='standard'
                value={temporaryQuote.offeredDiscount}
                onChange={handleSetOfferedDiscount}
                className={classes.inputField}
                InputProps={{
                  style: {
                    border: '2px inset',
                    textAlign: 'right',
                    backgroundColor: 'white',
                    fontWeight: 'bold'
                  },
                  inputComponent: PriceInput,
                  name: 'laborDiscountInput'
                }}
                disabled={quoteIsDisabled || isEditingParts}
              />
            </Grid>
          </Grid>

          <b>
            <ListItemValue
              sx={{ py: 0, px: 1 }}
              title='Final Labor Total'
              value={<Dollars value={temporaryQuote.laborSumAfterAllDiscounts} />}
            />
          </b>
        </SectionFooterGrid>
        {/* END LABOR SUMMARY */}

        {/* TECHNICIAN CHARGES */}
        <SectionHeaderGrid item xs={12}>
          Technician Charges
        </SectionHeaderGrid>

        <AddTechnicianCharge
          quote={quote}
          vehicle={vehicle}
          disabled={isEditingParts}
          onSave={handleTechnicianChargeSelected}
        />

        {technicianCharges.length > 0 && (
          <Grid item xs={12}>
            {technicianCharges
              .sort((a, b) => (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? 1 : -1))
              .map(item => (
                <OtherLaborCatalogItem
                  key={item.id}
                  item={item}
                  disabled={isEditingParts}
                  handleItemChanged={handleTechnicianChargeUpdated}
                  handleItemDeleted={charge => handleDeletedTechnicianCharge(charge.id)}
                />
              ))}
          </Grid>
        )}

        {technicianCharges?.length > 0 && (
          <SectionFooterGrid item xs={12} sx={{ px: 2 }}>
            {technicianCharges.length > 0 && (
              <b>
                <ListItemValue
                  style={{ textAlign: 'right' }}
                  title='subtotal'
                  value={<Dollars value={temporaryQuote.technicianChargesSum} />}
                />
              </b>
            )}
          </SectionFooterGrid>
        )}
        {/* END TECHNICIAN CHARGES */}

        {/* CARBODYLAB CHARGES */}
        <SectionHeaderGrid item xs={12}>
          Carbodylab Charges
        </SectionHeaderGrid>

        <AddCarbodylabCharge quote={quote} disabled={isEditingParts} onSave={handleCarbodylabChargeSelected} />

        {carbodylabCharges.length > 0 && (
          <Grid item xs={12}>
            {carbodylabCharges.map(item => (
              <OtherLaborCatalogItem
                key={item.id}
                item={item}
                disabled={isEditingParts}
                handleItemChanged={handleCarbodylabChargeUpdated}
                handleItemDeleted={charge => handleDeletedCarbodylabCharge(charge.id)}
              />
            ))}
          </Grid>
        )}

        {carbodylabCharges.sort((a, b) => (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? 1 : -1))
          .length > 0 && (
            <SectionFooterGrid item xs={12} sx={{ px: 2 }}>
              {carbodylabCharges.length > 0 && (
                <b>
                  <ListItemValue
                    style={{ textAlign: 'right' }}
                    title='subtotal'
                    value={<Dollars value={temporaryQuote.carbodylabChargesSum} />}
                  />
                </b>
              )}
            </SectionFooterGrid>
        )}
        {/* END CARBODYLAB CHARGES */}

        {/* FINAL TOTALS */}
        <SectionHeaderGrid item xs={12}>
          Totals
        </SectionHeaderGrid>

        <QuoteTotalsV2 quoteTotals={temporaryQuote} />

        {quoteActions}

        {isSubmitting && (
          <Box
            sx={{
              zIndex: 1902,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#11111180',
              backdropFilter: 'blur(2px)',
              position: 'fixed',
              top: '0px',
              bottom: '0px',
              left: '0px',
              right: '0px',
              color: '#fff'
            }}
          >
            <CircularProgress sx={{ mb: '1rem', color: '#fff' }} />
            Duplicating quote...
          </Box>
        )}
      </Grid>
    </>
  )
}
