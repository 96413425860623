import { BToBInvoiceType } from './types/bToBInvoice'
import { TechnicianType } from './types/technician'

export const calculateBToBInvoicePayoutData = ({
  bToBInvoice,
  technician,
}: {
  bToBInvoice: BToBInvoiceType
  technician: TechnicianType
}) => {
  let grandTotal = bToBInvoice.grandTotal || bToBInvoice.lineItemsSum
  let totalPayoutBeforeCcFee = 0
  let payoutStructure = 'panel'
  let percentageToUse = bToBInvoice.commissionPercent ? bToBInvoice.commissionPercent : technician.commissionPercent

  if (technician.bToBInvoiceCommissionStructure === 'panel') {
    payoutStructure = 'panel'

    totalPayoutBeforeCcFee = bToBInvoice.lineItemsCommissionSum
  } else {
    payoutStructure = 'labor cost'


    totalPayoutBeforeCcFee = grandTotal * (technician.commissionPercent / 100)
  }

  let ccFee = technician.enableB2bSquareFee ? totalPayoutBeforeCcFee * 0.0243 + 0.15 : 0
  let totalPayout = totalPayoutBeforeCcFee - ccFee

  if (bToBInvoice.payoutOverrideTotal !== null) {
    totalPayout = bToBInvoice.payoutOverrideTotal
  }

  return {
    commissionPercent: percentageToUse,
    totalPayoutBeforeCcFee: totalPayoutBeforeCcFee,
    ccFee: ccFee,
    totalPayout: totalPayout,
    technicianId: technician.id,
    payoutStructure: payoutStructure,
    totalPoints: bToBInvoice.lineItemsPointsSum,
  }
}
