import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useState } from 'react'
import { Button } from '@mui/material'
import { Dollars } from 'tools'
import { DateTime } from 'luxon'
import { ExpandMoreRounded, MoreVertRounded } from '@mui/icons-material'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { calculateTravelLogPayoutData } from 'payrollCalculator'
import { useLocalStorage } from 'hooks/useLocalStorage'

export const TravelLogsDropdown = ({ travelLogs, expanded }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [travelLogsExpanded, setTravelLogsExpanded] = useState(true)
  const [popoverRef, setPopoverRef] = useState(null)
  const [travelLogMenu, setTravelLogMenu] = useState(false)
  const [selectedTravelLog, setSelectedTravelLog] = useState(null)
  const [payrollOpen] = useLocalStorage('payrollSessionOpen', 'false', false)

  const handleClickTogglePaidStatus = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_travel_log_as_paid`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        travelLogId: selectedTravelLog.id,
      }),
    })
  }

  return (
    <>
      {travelLogs.length > 0 && (
        <TableRow
          sx={[
            expanded && {
              // border: '2px solid #3f51b5',
              borderTop: '0px solid red',
              borderBottom: '0px !important',
              borderLeft: '2px solid #3f51b5',
              borderRight: '2px solid #3f51b5',
            },
          ]}
        >
          <TableCell
            sx={{ padding: '.25rem .5rem', background: '#f2f6fa', fontWeight: 600, cursor: 'pointer' }}
            colSpan={14}
          >
            <Box
              onClick={() => setTravelLogsExpanded(!travelLogsExpanded)}
              sx={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}
            >
              Travel Logs <ExpandMoreRounded sx={{ transform: travelLogsExpanded ? 'rotate(180deg)' : 'scale(1)' }} />
            </Box>
          </TableCell>
        </TableRow>
      )}

      {travelLogs.length > 0 && (
        <TableRow
          sx={{
            background: '#ddd',
            borderBottom: '2px solid #3f51b5',
            borderLeft: '2px solid #3f51b5',
            borderRight: '2px solid #3f51b5',
          }}
        >
          <TableCell
            colSpan={14}
            sx={{ transition: 'all .25s ease-in-out', padding: travelLogsExpanded ? '.5rem' : '0rem' }}
          >
            <Collapse in={travelLogsExpanded}>
              <Table size='small' component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: '12px' }} align='right'>
                      Travel
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align='right'>
                      Total distance
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align='right'>
                      Total travel hours
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align='right'>
                      Hourly pay
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align='right'>
                      Total payout
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }} align='right'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {travelLogs.map(log => {
                    const isPaid = log.isPaid
                    const travelLogsPayoutData = calculateTravelLogPayoutData({
                      travelLog: log,
                      technician: log.technician,
                    })

                    return (
                      <TableRow sx={[{ background: '#f9f9f9' }, isPaid && { background: '#2e7d321a' }]}>
                        <TableCell align='right'>
                          {DateTime.fromISO(log.startedAt, { zone: 'utc' }).toFormat('t')} -{' '}
                          {DateTime.fromISO(log.completedAt, { zone: 'utc' }).toFormat('t')}
                        </TableCell>
                        <TableCell align='right'>{log.travelDistance} miles</TableCell>
                        <TableCell align='right'>{log.totalHours} hours</TableCell>
                        <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                          <Dollars value={travelLogsPayoutData?.hourlyPay || 0} />
                        </TableCell>
                        <TableCell align='right' sx={[{ fontWeight: 'bold' }, isPaid && { color: '#2e7d32' }]}>
                          <Dollars value={travelLogsPayoutData?.totalPayout || 0} />
                        </TableCell>
                        <TableCell align='right'>
                          <Button
                            disabled={payrollOpen === 'false'}
                            onClick={e => {
                              setTravelLogMenu(true)
                              setPopoverRef(e.target)
                              setSelectedTravelLog(log)
                            }}
                            size='small'
                            sx={{
                              minWidth: '20px',
                              minHeight: '20px',
                              padding: '.125rem',
                              '& svg': { width: '20px', height: '20px' },
                            }}
                          >
                            <MoreVertRounded />{' '}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      <Popover
        onClose={() => {
          setTravelLogMenu(false)
          setSelectedTravelLog(null)
        }}
        open={travelLogMenu}
        anchorEl={popoverRef}
      >
        <List disablePadding size='small'>
          <ListItem disablePadding>
            <ListItemButton onClick={handleClickTogglePaidStatus}>
              Mark as {selectedTravelLog?.isPaid ? 'unpaid' : 'paid'}
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
