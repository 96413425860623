import { calculateBToBInvoicePayoutData } from './bToBInvoice'
import { calculateWeeklyPayAdjustments } from './calculateWeeklyPayAdjustments'
import { calculateQuotePayoutData } from './quotes'
import { calculateTimeCardPayoutData } from './timecards'
import { BToBInvoiceType } from './types/bToBInvoice'
import { BToBPayoutType } from './types/bToBPayout'
import { ChargeBackType } from './types/chargeBack'
import { ManualCompensationType } from './types/manualCompensation'
import { PaidTimeOffType } from './types/paidTimeOff'
import { QuoteType } from './types/quote'
import { ReimbursementType } from './types/reimbursement'
import { SickTimeOffType } from './types/sickTimeOff'
import { TechnicianType } from './types/technician'
import { TimeCardType } from './types/timecard'
import { UnpaidTimeOffType } from './types/unpaidTimeOff'

export const calculatePayoutData = ({
  reimbursements,
  pto,
  uto,
  sto,
  manualCompensations,
  chargeBacks,
  timeCards,
  quotes,
  bToBInvoices,
  bToBPayouts,
  technician,
}: {
  reimbursements: Array<ReimbursementType>
  pto: Array<PaidTimeOffType>
  uto: Array<UnpaidTimeOffType>
  sto: Array<SickTimeOffType>
  manualCompensations: Array<ManualCompensationType>
  chargeBacks: Array<ChargeBackType>
  timeCards: Array<TimeCardType>
  quotes: Array<QuoteType>
  bToBInvoices: Array<BToBInvoiceType>
  bToBPayouts: Array<BToBPayoutType>
  technician: TechnicianType
}) => {
  let payoutData: any = {}

  const timeCardsWithPayoutData = timeCards.map((tc: any) => ({
    ...tc,
    payoutData: calculateTimeCardPayoutData({ timeCard: tc, technician }),
  }))

  const quotesWithPayoutData = quotes.map((quote: any) => ({
    ...quote,
    payoutData: calculateQuotePayoutData({ quote, technician }),
  }))

  const bToBInvoicesWithPayoutData = bToBInvoices.map((invoice: any) => ({
    ...invoice,
    payoutData: calculateBToBInvoicePayoutData({ bToBInvoice: invoice, technician }),
  }))

  // this value is the summation of all the labor amounts for quotes
  // in addition to the total amount technician charges (BEFORE CC FEE)
  const quoteTotalLaborAmount = quotesWithPayoutData.reduce(
    (acc: any, quote: any) => acc + Number(quote.payoutData.totalLaborAmount || 0),
    0
  )

  const quoteTechChargesTotalAmount = quotesWithPayoutData.reduce(
    (acc: any, quote: any) => acc + Number(quote.payoutData.fullPayoutItemsAfterCcFee || 0),
    0
  )

  const totalCommissionAmount = quotesWithPayoutData.reduce(
    (acc: any, quote: any) => acc + Number(quote.payoutData.laborCommissionAfterCcFee || 0),
    0
  )

  const quotePayoutsSum = Number(
    quotesWithPayoutData.reduce((acc: any, item: any) => acc + Number(item?.payoutData?.totalPayout || 0), 0)
  )

  const bToBPayoutsSum = bToBPayouts.reduce(
    (acc: any, item: any) => acc + Number(item?.payoutData?.totalPayout || 0),
    0
  )
  const bToBInvoicesSum = bToBInvoicesWithPayoutData.reduce(
    (acc: any, item: any) => acc + Number(item?.payoutData?.totalPayout || 0),
    0
  )

  const bToBInvoiceGrandTotal = bToBInvoicesWithPayoutData.reduce(
    (acc: any, item: any) => acc + Number(item?.grandTotal || 0),
    0
  )

  const bToBInvoicePayouts = bToBInvoicesWithPayoutData.map(invoice => ({
    bToBInvoiceId: invoice.id,
    payoutData: invoice.payoutData,
  }))

  const reimbursementsSum = reimbursements.reduce((acc: any, item: any) => acc + (item?.amount || 0), 0)
  const manualCompensationsSum = manualCompensations.reduce(
    (acc: any, item: any) => acc + (Number(item?.amount || 0) || 0),
    0
  )

  const totalTipSum = quotesWithPayoutData.reduce((acc, item) => item.payoutData.tipAfterFee + acc, 0)
  const totalPaidTimeOffHours = pto.reduce((acc: any, item: any) => acc + Number(item?.totalHours || 0), 0)
  const totalUnpaidTimeOffHours = uto.reduce((acc: any, item: any) => acc + Number(item?.totalHours || 0), 0)
  const totalSickTimeOffHours = sto.reduce((acc: any, item: any) => acc + Number(item?.totalHours || 0), 0)

  const timeCardsSum = Number(
    timeCardsWithPayoutData
      .reduce((acc: any, item: any) => acc + Number(item.payoutData.totalHourlyPay || 0), 0)
      .toFixed(2)
  )

  const paidAndSickTimeSum = (totalPaidTimeOffHours + totalSickTimeOffHours) * technician.hourlyRate

  const chargeBacksSum = chargeBacks.reduce((acc: any, item: any) => acc + Number(item?.amount || 0), 0)

  const loanDeductableAmount = Number(
    (
      Number(quotePayoutsSum) +
      Number(bToBPayoutsSum) +
      Number(bToBInvoicesSum) +
      Number(timeCardsSum || 0) +
      // PTO and STO hours should be added to timecards hourly sum
      Number(paidAndSickTimeSum || 0) +
      Number(manualCompensationsSum)
    ).toFixed(2)
  )

  let newDeduction = 0

  if (loanDeductableAmount == 0) {
    newDeduction = 0
  } else if (technician.loanBalance > 0) {
    // # start with the weekly fixed amount set on the technician record
    newDeduction = technician.loanWeeklyDeduction
    // # but if we're about to take over 30% of weekly payout
    if (newDeduction > loanDeductableAmount * 0.3) {
      // # let's only take 10% instead
      newDeduction = loanDeductableAmount * 0.1
    }
    // # cap it off at the balance
    newDeduction = Math.min(newDeduction, technician.loanBalance)
  } else {
    newDeduction = 0
  }

  let newOtherDeduction = 0

  if (loanDeductableAmount == 0) {
    newOtherDeduction = 0
  } else if (technician.otherLoanBalance > 0) {
    // # start with the weekly fixed amount set on the technician record
    newOtherDeduction = technician.otherLoanWeeklyDeduction
    // # but if we're about to take over 30% of weekly payout
    if (newOtherDeduction > loanDeductableAmount * 0.3) {
      // # let's only take 10% instead
      newOtherDeduction = loanDeductableAmount * 0.1
    }
    // # cap it off at the balance
    newOtherDeduction = Math.min(newOtherDeduction, technician.otherLoanBalance)
  } else {
    newOtherDeduction = 0
  }

  let totalHourlyPay = 0
  let minimumPayAdjustment = 0
  let overtimePayAdjustment = 0
  let totalPtoHours = totalPaidTimeOffHours
  let totalUtoHours = totalUnpaidTimeOffHours
  let totalPayAdjustments = 0

  payoutData = {
    quotePayoutsSum: Number(quotePayoutsSum.toFixed(2)),
    bToBPayoutsSum: bToBPayoutsSum,
    bToBInvoicesSum: bToBInvoicesSum,
    reimbursementsSum: reimbursementsSum,
    manualCompensationsSum: manualCompensationsSum,
    loanDeductableAmount: loanDeductableAmount,
    timeCardsSum: timeCardsSum,
    totalSickTimeOffHours: totalSickTimeOffHours,
    totalPaidTimeOffHours: totalPtoHours,
    minimumPayAdjustment: minimumPayAdjustment,
    overtimePayAdjustment: overtimePayAdjustment,
    totalHourlyPay: totalHourlyPay,
    commissionChargeBacksSum: chargeBacksSum,
    totalPayAdjustments: totalPayAdjustments,
    loanDeductionAmount: newDeduction,
    otherLoanDeductionAmount: newOtherDeduction,
    totalUtoHours: totalUtoHours,
  }

  const {
    minimumPayAdjustment: mpa,
    totalTimeCardHours,
    overtimeAdjustment,
    overtimePay,
    overtimeHours,
    totalHourlyPay: thp,
    totalUtoHours: tuh,
    totalPtoHours: tph,
    totalStoHours,
  } = calculateWeeklyPayAdjustments({
    timeCards: timeCardsWithPayoutData,
    pto: pto,
    uto: uto,
    sto: sto,
    hourlyRate: technician.hourlyRate,
    state: technician.state,
    quoteCommissionStructure: technician.quoteCommissionStructure,
  })

  payoutData.totalPaidTimeOffHours = Number(tph || 0)
  payoutData.totalUnpaidTimeOffHours = Number(tuh || 0)
  payoutData.totalSickTimeOffHours = Number(totalStoHours || 0)
  payoutData.totalTimeCardHours = Number(totalTimeCardHours || 0)
  payoutData.totalHourlyPay = Number(thp || 0)

  // ## only calculate overtime and minimumPayAdjustment for payouts with timecards
  if (timeCards.length > 0) {
    payoutData.minimumPayAdjustment = Number(mpa)
    payoutData.overtimePayAdjustment = Number(overtimeAdjustment)
    payoutData.overtimePay = Number(overtimePay)
    payoutData.overtimeHours = Number(overtimeHours)
    payoutData.totalPayAdjustments = Number((Number(mpa) + Number(overtimeAdjustment)).toFixed(2))
  }

  // # everything minus the reimbursementsSum
  const gustoWageAmount =
    Number(quotePayoutsSum) +
    Number(manualCompensationsSum) +
    Number(bToBInvoicesSum) +
    Number(timeCardsSum || 0) +
    Number(paidAndSickTimeSum || 0) +
    Number(payoutData.totalPayAdjustments) +
    Number(bToBPayoutsSum) -
    Number(payoutData.loanDeductionAmount || 0) -
    Number(payoutData.otherLoanDeductionAmount || 0) -
    Number(chargeBacksSum)

  const finalCheckAmount = gustoWageAmount

  payoutData.quoteTotalLaborAmount = quoteTotalLaborAmount
  payoutData.quoteTechChargesTotalAmount = Number(quoteTechChargesTotalAmount.toFixed(2))
  payoutData.totalCommissionAmount = totalCommissionAmount

  payoutData.gustoWageAmount = Number(gustoWageAmount.toFixed(2))
  payoutData.finalCheckAmount = Number(finalCheckAmount.toFixed(2))
  payoutData.minimumHourlyPayAdjustment = Number(payoutData.minimumPayAdjustment.toFixed(2))

  if (payoutData.gustoWageAmount < 0) {
    payoutData.gustoWageAmount = 0
  }

  payoutData.bToBInvoicePayouts = bToBInvoicePayouts

  payoutData.quoteCommissionBeforeTip = quotesWithPayoutData.reduce(
    (acc, item) => acc + Number(item?.payoutData?.laborCommissionAfterCcFee || 0),
    0
  )

  const quoteLaborGrandTotal = Number(quoteTotalLaborAmount) == 0 ? 1 : Number(quoteTotalLaborAmount)
  const b2bInvoiceGrandTotal = Number(bToBInvoiceGrandTotal) == 0 ? 1 : Number(bToBInvoiceGrandTotal)

  payoutData.invoiceAndQuoteLaborTotal = Number(bToBInvoiceGrandTotal) + Number(quoteTotalLaborAmount)

  const numberForQuoteLaborGrandTotalAndInvoiceGrandTotal = Number(quoteLaborGrandTotal) + Number(b2bInvoiceGrandTotal)

  payoutData.payoutTotalOverLaborTotal =
    Number(Number(payoutData.gustoWageAmount) - Number(totalTipSum)) /
    (numberForQuoteLaborGrandTotalAndInvoiceGrandTotal === 0 ? 1 : numberForQuoteLaborGrandTotalAndInvoiceGrandTotal)

  if (payoutData.payoutTotalOverLaborTotal === null || payoutData.payoutTotalOverLaborTotal == Infinity) {
    payoutData.payoutTotalOverLaborTotal = 0
  }

  payoutData.payoutTotalOverLaborTotal = Number(payoutData.payoutTotalOverLaborTotal || 0)

  payoutData.totalTipSum = Number(totalTipSum.toFixed(2))

  payoutData.quotePayouts = quotesWithPayoutData.map((quote: any) => ({
    quoteId: quote.id,
    payoutData: quote.payoutData,
  }))

  payoutData.timeCardPayouts = timeCardsWithPayoutData.map((timeCard: any) => ({
    timeCardId: timeCard.id,
    payoutData: timeCard.payoutData,
  }))

  return payoutData
}
