import { Quote, Lead } from './types'

// isLaborTaxable decides whether to tax labor
//
// Labor is always taxable in North Carolina
//
// Labor is taxable in Florida when there are more than 0 non-voided parts
const exp = /nc|fl/i

export default (quote: Quote, lead: Lead): boolean =>
  !!lead.state.match(exp)
