import {
  Badge,
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import { useRef, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Button } from '@mui/material'
import { Dollars, keysToCamel } from 'tools'
import { DateTime } from 'luxon'
import { ExpandMoreRounded, InfoRounded, LaunchRounded, MoreVertRounded } from '@mui/icons-material'
import { ChangeCommissionDialog } from './ChangeCommissionDialog'
import { JobSkeletonRow } from '../TimeCards'
import { NotesDialog } from './NotesDialog'
import { calculateWorklogPayoutData } from 'payrollCalculator'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { Link } from 'react-router-dom'

const JOB_QUERY = gql`
  query job($id: ID!) {
    job(id: $id) {
      id
      visitNumber
      technician {
        id
        commissionAmount
        isEmployee
      }
      quoteId
      startDatetime
      endDatetime
      status
      scheduledLength
      quote {
        id
        quoteGrandTotal
        ccFeeSum
        totalTransactionAmount
        tipSum
        laborSumAfterAllDiscounts
        technicianChargesSum
        paymentStatus
        fullyPaidAt
        payoutIds
        payrollStatuses
        payrollNotesCount
        technician {
          id
          commissionAmount
          isEmployee
          state
        }
      }
      lead {
        id
        name
        make
        model
        year
      }
      workLog {
        id
        createdAt
        startedAt
        completedAt
        type
        isPaid
        jobId
        travelDistance
        totalHours
        quoteId
        payoutData
        selectedForCommissionPayment
        commissionType
        hourlyType
        amount
        technician {
          id
          firstName
          lastName
          hourlyRate
          commissionAmount
          isEmployee
          state
        }
        job {
          id
          scheduledLength
          isActive
        }
        quote {
          id
          quoteGrandTotal
          tipSum
          ccFeeSum
          totalTransactionAmount
          laborSumAfterAllDiscounts
          technicianChargesSum
          paymentStatus
          fullyPaidAt
          payoutIds
          payrollStatuses
          payrollNotesCount
          technician {
            id
            firstName
            lastName
            hourlyRate
            commissionAmount
            isEmployee
            state
          }
          workLogs {
            id
            hourlyType
            createdAt
            startedAt
            completedAt
            amount
            quoteId
            type
            isPaid
            jobId
            travelDistance
            totalHours
            commissionType
            payoutData
            selectedForCommissionPayment
            technician {
              id
              firstName
              lastName
              hourlyRate
              commissionAmount
              state
              isEmployee
            }
            job {
              id
              scheduledLength
              isActive
            }
          }
        }
      }
    }
  }
`

export const JobRow = ({ jobId, previousLogs }) => {
  const { loading, error, data, refetch } = useQuery(JOB_QUERY, { variables: { id: jobId } })

  const [menuOpen, setMenuOpen] = useState(false)
  const [commissionMenuOpen, setCommissionMenuOpen] = useState(false)
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [payrollOpen] = useLocalStorage('payrollSessionOpen', 'false', false)

  const popoverItemRef = useRef()

  if (loading) return <JobSkeletonRow />
  if (error) return <div>Error!</div>

  const job = data?.job
  const { workLog } = keysToCamel(job)

  const departureFromPreviousLocation = previousLogs[previousLogs.length - 1]?.startedAt
  const quoteIsAlreadyPaid = !!job.quote.payoutId
  const anotherJobWillGetQuotePayout = keysToCamel(job?.workLog.payoutData)?.commissionType === 'None'
  const worklogIsPayingCommission = !!job?.workLog.selectedForCommissionPayment
  const payrollNotesCount = job?.quote?.payrollNotesCount

  const worklogPayoutData = calculateWorklogPayoutData({ workLog, technician: workLog.technician })

  return (
    !loading && (
      <>
        <TableRow
          disablePadding
          sx={[
            {
              background: '#f9f9f9',
              borderRadius: '12px',
            },
            worklogIsPayingCommission && { background: '#2e7d321a' },
            quoteIsAlreadyPaid && { background: '#ff00001a' },
            anotherJobWillGetQuotePayout && { background: '#f1f1f1' },
          ]}
        >
          <TableCell padding='0' align='right' sx={{ '& a': { fontSize: '13px' }, whiteSpace: 'nowrap' }}>
            <Link to={`/jobs/${job.id}`}>
              {DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('t')} -{' '}
              {DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat('t')}
            </Link>
            <b style={{ fontSize: '12px', color: '#444' }}> &#8212; #{job.visitNumber}</b>
          </TableCell>
          <TableCell disablePadding align='right'>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link to={`/quotes/${job.quoteId}`}>
                {' '}
                <Dollars value={job?.quote?.quoteGrandTotal || 0} />
              </Link>{' '}
              <Tooltip
                title={
                  job.quote?.fullyPaidAt
                    ? `fully paid on ${DateTime.fromISO(job.quote.fullyPaidAt).toISODate()}`
                    : `payment status: ${job.quote.paymentStatus}`
                }
              >
                <Box
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '3px',
                    ml: '.5rem',
                    width: '12px',
                    height: '12px',
                    background: job.quote?.fullyPaidAt ? '#2e7d32' : 'orange',
                  }}
                />
              </Tooltip>
            </Box>
          </TableCell>
          {/* <TableCell align='right'>2</TableCell> */}
          <TableCell align='right'>
            <Link
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 1,
                maxWidth: '200px',
              }}
              to={`/leads/${job.lead.id}`}
            >
              {' '}
              {job.lead.name}{' '}
            </Link>{' '}
          </TableCell>
          <TableCell align='right'>
            {job.lead.year} {job.lead.make} {job.lead.model}
          </TableCell>

          <TableCell align='right'>
            {DateTime.fromISO(departureFromPreviousLocation, { zone: 'UTC' }).toFormat('HH:mm')}
          </TableCell>

          <TableCell align='right'>{DateTime.fromISO(job.startDatetime, { zone: 'UTC' }).toFormat('HH:mm')}</TableCell>

          <TableCell align='right'>{DateTime.fromISO(workLog.startedAt, { zone: 'UTC' }).toFormat('HH:mm')}</TableCell>

          <TableCell align='right'>{job.scheduledLength}</TableCell>
          <TableCell align='right'>{workLog.totalHours}</TableCell>
          <TableCell align='right' sx={{ fontWeight: 'bold' }}>
            {' '}
            <Dollars value={worklogPayoutData?.tip || 0} />
          </TableCell>
          <TableCell
            align='right'
            sx={{
              fontWeight: 'bold',
              '& svg': { fontSize: '18px', cursor: 'pointer', color: '#999', '&:hover': { color: '#3f51b5' } },
              whiteSpace: 'nowrap',
            }}
          >
            <Dollars value={worklogPayoutData?.hourlyPay || 0} />
            &nbsp;
            <Tooltip title={worklogPayoutData?.hourlyPayDescription}>
              <InfoRounded />
            </Tooltip>
          </TableCell>
          <TableCell
            align='right'
            sx={[
              {
                fontWeight: 'bold',
                '& svg': { fontSize: '18px', cursor: 'pointer', color: '#999', '&:hover': { color: '#3f51b5' } },
                whiteSpace: 'nowrap',
              },
              quoteIsAlreadyPaid && {
                color: '#ff0000',
                '& svg': { fontSize: '18px', cursor: 'pointer', color: '#ff0000bf', '&:hover': { color: '#ff0000' } },
              },
              worklogIsPayingCommission && {
                color: '#2e7d32',
                '& svg': { fontSize: '18px', cursor: 'pointer', color: '#2e7d32bf', '&:hover': { color: '#2e7d32' } },
              },
              anotherJobWillGetQuotePayout && {
                color: '#444',
                '& svg': { fontSize: '18px', cursor: 'pointer', color: '#444444bf', '&:hover': { color: '#444' } },
              },
            ]}
          >
            <Dollars value={worklogPayoutData?.commissionAmount || 0} />
            &nbsp;
            <Tooltip title={worklogPayoutData?.commissionDescription}>
              <InfoRounded />
            </Tooltip>
          </TableCell>
          <TableCell
            align='right'
            sx={{
              fontWeight: 'bold',
            }}
          >
            <Dollars value={worklogPayoutData?.totalPayout || 0} />
          </TableCell>
          <TableCell
            sx={{
              position: 'sticky',
              right: '-1px',
              background: {
                xs: '#F8FAFB',
                sm: '#F8FAFB',
                md: '0px',
              },
            }}
            align='right'
          >
            <Badge overlap='circular' color='primary' badgeContent={payrollNotesCount}>
              <Button
                onClick={() => setMenuOpen(true)}
                ref={popoverItemRef}
                sx={{
                  minWidth: '20px',
                  minHeight: '20px',
                  padding: '.125rem',
                  '& svg': { width: '20px', height: '20px' },
                }}
              >
                <MoreVertRounded />
              </Button>
            </Badge>
            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => {
                setMenuOpen(false)
                setCommissionMenuOpen(false)
              }}
              open={menuOpen}
              anchorEl={popoverItemRef.current}
            >
              <List size='small' disablePadding>
                <ListItem onClick={() => setNoteDialogOpen(true)} disablePadding disableGutters>
                  <ListItemButton>View notes</ListItemButton>
                </ListItem>
                <Divider />
                {payrollOpen === 'true' && (
                  <ListItem disablePadding disableGutters>
                    <ListItemButton onClick={_ => setCommissionMenuOpen(!commissionMenuOpen)}>
                      Payment settings <LaunchRounded sx={{ fontSize: '18px', ml: '.5rem' }} />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Popover>
          </TableCell>
        </TableRow>
        <ChangeCommissionDialog
          job={job}
          workLog={workLog}
          worklogPayoutData={worklogPayoutData}
          commissionMenuOpen={commissionMenuOpen}
          setCommissionMenuOpen={setCommissionMenuOpen}
          refetch={refetch}
        />

        <NotesDialog quote={job.quote} noteDialogOpen={noteDialogOpen} setNoteDialogOpen={setNoteDialogOpen} />
      </>
    )
  )
}

export const TimeCardJobsRow = ({ residentialWorkLogs, timeCard }) => {
  const jobIds = residentialWorkLogs?.filter(log => log.jobId) || []

  return jobIds.map((log, index) => {
    const logsBeforeThisOne = timeCard.travelLogs
      .concat(timeCard.workLogs)
      .concat(timeCard.bToBJobsLogs)
      ?.filter(timeLog => DateTime.fromISO(log.startedAt) > DateTime.fromISO(timeLog.startedAt))

    return <JobRow previousLogs={logsBeforeThisOne} workLog={log} jobId={log.jobId} index={index} />
  })
}

export const ResidentialJobsDropdown = ({ timeCard, residentialWorkLogs, travelLogs, bToBWorkLogs, expanded }) => {
  const [residentialExpanded, setResidentialExpanded] = useState(true)

  return (
    <>
      {residentialWorkLogs.length > 0 && (
        <TableRow sx={[expanded && { border: '2px solid #3f51b5', borderBottom: '0px', borderTop: '0px !important' }]}>
          <TableCell sx={{ padding: '.25rem .5rem', background: '#f2f6fa' }} colSpan={14}>
            <Box
              onClick={() => setResidentialExpanded(!residentialExpanded)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                userSelect: 'none',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              Residential Jobs{' '}
              <ExpandMoreRounded sx={{ transform: residentialExpanded ? 'rotate(180deg)' : 'scale(1)' }} />
            </Box>
          </TableCell>
        </TableRow>
      )}
      <TableRow
        sx={[
          { background: '#ddd' },
          expanded && {
            borderLeft: '2px solid #3f51b5',
            borderRight: '2px solid #3f51b5',
            borderTop: '0px !important',
            // borderBottomm: '0px !important',
          },
          // bToBWorkLogs.length !== 0 && { borderBottom: '0px !important' },
          travelLogs.length == 0 && bToBWorkLogs.length === 0 && { borderBottom: '2px solid #3f51b5' },
        ]}
      >
        <TableCell
          sx={{ transition: 'all .25s ease-in-out', padding: residentialExpanded ? '.5rem !important' : '0rem' }}
          colSpan={14}
        >
          <Table size='small' component={Paper}>
            {residentialWorkLogs.length > 0 && (
              <>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ padding: '0px' }} colSpan={14}>
                      <Collapse in={residentialExpanded}>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Job
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Quote
                              </TableCell>
                              {/* <TableCell align='right'>Visit #</TableCell> */}
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Customer
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Vehicle
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Depart last location
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Scheduled Arrival{' '}
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Actual Arrival{' '}
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Hours scheduled
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Hours worked
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Tip
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Hourly pay
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Commission
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'>
                                Total payout
                              </TableCell>
                              <TableCell sx={{ fontSize: '12px' }} align='right'></TableCell>
                            </TableRow>
                          </TableHead>

                          <TimeCardJobsRow residentialWorkLogs={residentialWorkLogs} timeCard={timeCard} />
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </>
            )}
          </Table>
        </TableCell>
      </TableRow>
    </>
  )
}
