import { TechnicianType } from './types/technician'
import { TravelLogType } from './types/travelLog'

export const calculateTravelLogPayoutData = ({ technician, travelLog }: { technician: TechnicianType, travelLog: TravelLogType }) => {
  const hourlyPay = travelLog.totalHours * (technician.hourlyRate || 0)
  const totalPayout = hourlyPay
  let payoutData: any = {}

  payoutData = {
    hourlyType: 'TravelLog',
    commissionType: 'None',
    commissionDescription: 'No commission can be paid for travel logs.',
    hourlyPayDescription: `Hourly pay is based on the total paid travel time of ${travelLog.totalHours} hour(s).`,
    commissionHours: 0,
    commissionAmount: 0,
    tip: 0,
    workedHours: 0,
    paidTravelHours: travelLog.totalHours,
    hourlyPay: hourlyPay,
    totalPayout: totalPayout,
    travelDistance: travelLog.travelDistance,
  }

  if (!travelLog.isPaid) {
    payoutData.hourlyPay = 0
    payoutData.totalPayout = 0
  }

  return payoutData
}
