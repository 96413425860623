import { WorkLogType } from './types/workLog'

export const calculateBToBJobPayoutData = ({ workLog }: { workLog: WorkLogType }) => {
  let hourlyPay = workLog.totalHours * (workLog.technician.hourlyRate || 0)
  let payoutData = {}

  let totalHours = 0
  let description = ''

  if (workLog.hourlyType === 'Withheld') {
    hourlyPay = 0
    description = 'Hourly pay has been withheld from this worklog.'
  } else if (workLog.hourlyType === 'Job') {
    hourlyPay = workLog.bToBJob.scheduledLength * (workLog.technician.hourlyRate || 0)
    totalHours = workLog.bToBJob.scheduledLength
    description = `Hourly pay is based on the scheduled time of ${workLog.bToBJob.scheduledLength} hour(s)`
  } else if (workLog.hourlyType === 'Worklog') {
    hourlyPay = workLog.totalHours * (workLog.technician.hourlyRate || 0)
    totalHours = workLog.totalHours
    description = `Hourly pay is based on the worked time of ${workLog.totalHours} hour(s)`
  } else {
    hourlyPay = workLog.totalHours * (workLog.technician.hourlyRate || 0)
    totalHours = workLog.totalHours
    description = `Hourly pay is based on the worked time of ${workLog.totalHours} hour(s)`
  }

  const totalPayout = hourlyPay

  payoutData = {
    hourlyType: workLog.hourlyType,
    commissionType: 'None',
    commissionDescription: 'No commission can be paid for this b2b job logs.',
    hourlyPayDescription: description,
    commissionHours: 0,
    commissionAmount: 0,
    tip: 0,
    workedHours: totalHours,
    paidTravelHours: 0,
    hourlyPay: hourlyPay,
    totalPayout: totalPayout,
    travelDistance: workLog.travelDistance,
  }

  return payoutData
}
