import { useMemo } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Collapse, TableRow, TableCell } from '@mui/material'
import { useToggle } from 'hooks/useToggle'

import { DateTime } from 'luxon'
import { Money } from 'quoteCalculator/types'
import { Dollars } from 'tools'
import { Link } from 'react-router-dom'
import PartAndSiblings from 'Leads/Quotes/Parts/Part/PartAndSiblings'
import { useDebounce } from 'use-debounce'

export default function PartsOrderingJobRow ({ job, today }) {
  const [expanded, toggleExpanded] = useToggle(false)
  const [debouncedExpanded] = useDebounce(expanded, 500, { leading: false })

  const data = useMemo(() => {
    const needsOrderingToday = []
    let combinedPrice = Money(0)
    let combinedPriceToday = Money(0)

    const daysAhead = Math.floor(DateTime.fromISO(job.startDatetime, { zone: 'utc' }).diff(today, ['days']).days)

    job.quote.parts.forEach(val => {
      if (!val.orderPlacedAt && ((val.etaBusinessDays == null && daysAhead <= 3) || val.etaBusinessDays >= daysAhead)) {
        needsOrderingToday.push(val.id)
        combinedPriceToday = combinedPriceToday.add(Money(val.price))
      }

      combinedPrice = combinedPrice.add(Money(val.price))
    })

    return {
      needsOrderingToday,
      combinedPrice: combinedPrice.toNumber(),
      combinedPriceToday: combinedPriceToday.toNumber()
    }
  }, [job, today])

  const rowStyles = {
    cursor: 'pointer',
    backgroundOpacity: 0.25
  }
  const subTableRowStyles = {}

  if (data.needsOrderingToday.length < 1) {
    rowStyles.backgroundColor = '#4caf5018' // success.light@10%
  } else {
    rowStyles.backgroundColor = '#ff980018' // warning.light@10%
  }
  if (expanded) {
    rowStyles.borderColor = 'info.light'
    rowStyles.borderStyle = 'solid'
    rowStyles.borderBottomWidth = 0

    subTableRowStyles.borderColor = 'info.light'
    subTableRowStyles.borderStyle = 'solid'
    subTableRowStyles.borderTopWidth = 0
  }

  return (
    <>
      <TableRow onClick={toggleExpanded} sx={rowStyles}>
        <TableCell>{expanded ? <ArrowDropDown /> : <ArrowDropUp />}</TableCell>
        <TableCell>{data.needsOrderingToday.length}</TableCell>
        <TableCell>
          <Link to={`/leads/${job.leadId}/quotes/${job.quoteId}`}>{job.lead.name}</Link>
        </TableCell>
        <TableCell>
          <Link to={`/jobs/${job.id}`}>{DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toLocaleString()}</Link>
        </TableCell>
        <TableCell>{DateTime.fromISO(job.startDatetime, { zone: 'utc' }).toFormat('t')}</TableCell>
        <TableCell>{DateTime.fromISO(job.endDatetime, { zone: 'utc' }).toFormat('t')}</TableCell>
        <TableCell>{job.quote.parts.length}</TableCell>
        <TableCell>
          <Dollars value={data.combinedPrice} />
        </TableCell>
        <TableCell>
          <Dollars value={data.combinedPriceToday} />
        </TableCell>
      </TableRow>
      <TableRow sx={subTableRowStyles}>
        <TableCell colSpan={9}>
          <Collapse in={expanded} sx={{ width: '100%', px: 4 }}>
            {(expanded || debouncedExpanded) && (
              <PartAndSiblings parts={job.quote.parts} quote={job.quote} lead={job.lead} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
