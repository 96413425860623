import { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { UserContext } from 'UserStore'
import {
  Box, Button, Grid, Typography, FormControlLabel, Switch, Checkbox,
  Divider, TextField, Accordion, AccordionSummary, AccordionDetails, Chip
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Breadcrumbs from 'components/Breadcrumbs'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceInput, TLD } from 'tools'
import { CreateATask } from 'Tasks'
import 'react-super-select/lib/react-super-select.css'
import { RefetchRegistry } from 'RefetchRegistry'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import { DateTime } from 'luxon'
import LeadPhotoCarousel from './components/LeadPhotoCarousel'
import Jobs from '../components/Jobs'
import Notes from 'components/Notes'
import PercentageInput from 'components/PercentageInput'
import DescendentNotes from 'components/DescendentNotes'
import TechNotes from 'components/TechNotes'
import TechNotesForJobsUnderQuote from 'components/TechNotesForJobsUnderQuote'
import ManualCompensations from '../ManualCompensations'
import StreamDump from 'components/StreamDump'
import AddWriteoffAmountDialog from './components/AddWriteoffAmountDialog'
import Admin from '../components/Admin'
import InsurancePhotoUploader from './components/InsurancePhotoUploader'
import { CheckCircleRounded, KeyboardArrowDownRounded } from '@mui/icons-material'
import Appointments from '../components/Appointments'
import { QuoteCalculatorV2 } from './QuoteCalculatorV2'
import PaymentsTableV2 from './components/PaymentsTableV2'
import CommissionChargeBacks from '../CommissionChargeBacks'
import { formatFullDate } from 'BToB/Invoices/BToBInvoice/InvoicePDF'
import PayrollHistoryTable from './components/PayrollHistoryTable'
import QuoteHistory from './components/QuoteHistory'
import LabelReadWriter from 'components/LabelReadWriter'

const JOBS_QUERY = gql`
  query getJobs($quoteId: ID!) {
    jobs(quoteId: $quoteId) {
      id
      startDatetime
      endDatetime
      canceledAt
      heldForReschedulingAt
    }
  }
`

const useStyles = makeStyles(theme => ({
  gridRoot: {
    flexGrow: 1
  },
  emptyContainer: {
    marginTop: '1em'
  },
  inputField: {
    '& .MuiInput-root::before': {
      borderWidth: '0'
    },
    '& .MuiInput-root::before:hover': {
      borderWidth: '1px'
    },
    '& .MuiInput-root input': {
      borderWidth: '0',
      textAlign: 'right',
      fontSize: '15px'
    }
  }
}))

const ShowEditV2 = ({ lead, quote, refetchQuote }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const classes = useStyles()

  const [showHistory, setShowHistory] = useState(false)
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)
  const [currentPhotoId, setCurrentPhotoId] = useState('')
  const [showWriteoffDialog, setShowWriteoffDialog] = useState(false)

  const [editableFields, setEditableFields] = useState({
    payoutBonus: quote.payoutBonus,
    commissionPercentOverride: quote.commissionPercentOverride,
    isAllowedPayoutBeforeFollowups: quote.isAllowedPayoutBeforeFollowups
  })

  const handleChangeCommissionPercentOverride = e => {
    if (e.target.value === '' || Number(e.target.value) === 0) {
      setEditableFields({ ...editableFields, commissionPercentOverride: null })
    } else {
      setEditableFields({
        ...editableFields,
        commissionPercentOverride: Math.min(Number(e.target.value), 100)
      })
    }
  }

  const handleChangeTechnicianPayoutBonus = e =>
    setEditableFields({
      ...editableFields,
      payoutBonus: Number(e.target.value)
    })

  const commissionPercentOverrideNeedsSaving =
    Number(editableFields.commissionPercentOverride) !== Number(quote.commissionPercentOverride)

  const payoutBonusNeedsSaving = Number(editableFields.payoutBonus) !== Number(quote.payoutBonus)

  const allowSavingAttributes =
    !quote.payoutId &&
    (commissionPercentOverrideNeedsSaving ||
      payoutBonusNeedsSaving ||
      quote.isAllowedPayoutBeforeFollowups !== editableFields.isAllowedPayoutBeforeFollowups)

  const handleClickSaveAttributes = () => {
    const updatedAttributes = {}

    if (commissionPercentOverrideNeedsSaving) {
      updatedAttributes.commission_percent_override = editableFields.commissionPercentOverride
    }

    if (payoutBonusNeedsSaving) {
      updatedAttributes.payout_bonus = editableFields.payoutBonus
    }

    if (quote.isAllowedPayoutBeforeFollowups !== editableFields.isAllowedPayoutBeforeFollowups) {
      updatedAttributes.is_allowed_payout_before_followups = editableFields.isAllowedPayoutBeforeFollowups
    }

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_quote_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        quoteId: quote.id,
        updatedAttributes
      })
    }).then(res => (res.ok && setTimeout(refetchQuote, 2500)) || window.alert('Error'))
  }

  const {
    loading: jobsLoading,
    error: jobsError,
    data: jobsData,
    refetch: jobsRefetch
  } = useQuery(JOBS_QUERY, {
    variables: { quoteId: quote.id }
  })

  useEffect(() => {
    jobsData && jobsRefetch && jobsRefetch()
  }, [])

  const handleToggleQuoteAttribute = event =>
    setEditableFields({
      ...editableFields,
      [event.target.name]: event.target.checked
    })

  const handleClickAddCashPayment = () => {
    const amount = Number(window.prompt('Enter only and exactly the payment amount, something like "19.99"'))
    if (amount > 0) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_cash_payment`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          quote_id: quote.id,
          amount: amount.toFixed(2)
        })
      })
        .then(res => res.ok || alert('Error'))
        .then(res => refetchQuote())
    }
  }

  const handleClickAddInsurancePayment = () => {
    const amount = Number(window.prompt('Enter only and exactly the payment amount, something like "19.99"'))
    if (amount > 0) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/add_insurance_payment`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          quote_id: quote.id,
          amount: amount.toFixed(2)
        })
      })
        .then(res => res.ok || alert('Error'))
        .then(res => refetchQuote())
    }
  }

  const handleClickUnlinkSquarePayment = paymentId => {
    if (window.confirm('Confirm you want to UNLINK this payment from this quote')) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/unlink_square_payment_from_quote`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          quote_id: quote.id,
          payment_id: paymentId
        })
      })
        .then(res => res.ok || alert('Error'))
        .then(res => refetchQuote())
    }
  }

  const handleClickProcessRefund = paymentId => {
    if (window.confirm('Confirm you want to process this as a refund? This cant be undone')) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/process_square_refund`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          payment_id: paymentId
        })
      })
        .then(res => res.ok || alert('Error'))
        .then(res => refetchQuote())
    }
  }

  const handleClickDeleteCashPayment = paymentId => {
    if (window.confirm('Please confirm')) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/remove_cash_payment_from_quote`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          quote_id: quote.id,
          payment_id: paymentId
        })
      })
        .then(res => res.ok || alert('Error'))
        .then(res => refetchQuote())
    }
  }

  const handleClickDeleteInsurancePayment = paymentId => {
    if (window.confirm('Please confirm')) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_insurance_payment`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          quote_id: quote.id,
          payment_id: paymentId
        })
      })
        .then(res => res.ok || alert('Error'))
        .then(res => refetchQuote())
    }
  }

  const handleClickDeleteWriteoffAmount = paymentId => {
    if (window.confirm('Please confirm')) {
      fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_writeoff_amount`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          quote_id: quote.id,
          payment_id: paymentId
        })
      })
        .then(res => res.ok || alert('Error'))
        .then(res => refetchQuote())
    }
  }

  const handleClickLinkASquarePayment = () => {
    const squarePaymentId = window
      .prompt(
        'Find the SQUARE ORDER ID and enter it here.  You can find it in the receipt URL for the specific payment - https://squareup.com/receipt/preview/THIS_IS_THE_PAYMENT_ID'
      )
      .trim()

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/link_square_payment_id_to_quote`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quoteId: quote.id,
        square_payment_id: squarePaymentId
      })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(newRes => {
            if (Object.keys(newRes)[0] === 'ERROR') {
              const errorMessage = Object.values(newRes)[0]
              alert(`ERROR: ${errorMessage}`)
            }
          })
        }
      })
      .then(res => refetchQuote())
  }

  const handleToggleAudatex = e => {
    if (e.target.checked) {
      window.confirm('Flag this for Audatex?') &&
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_audatex_on`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            quote_id: quote.id
          })
        })
    } else {
      window.confirm('Audatex is complete?') &&
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_audatex_off`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            quote_id: quote.id
          })
        })
    }
  }

  const handleToggleInsurance = e => {
    if (e.target.checked) {
      window.confirm('Flag this for insurance?') &&
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_insurance_on`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            quote_id: quote.id
          })
        })
    } else {
      window.confirm('Remove insurance flag?') &&
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_insurance_off`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            quote_id: quote.id
          })
        })
    }
  }

  const handleToggleDeleted = e => {
    if (e.target.checked) {
      window.confirm('Delete (hide) this?') &&
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_quote`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            quote_id: quote.id
          })
        })
    } else {
      window.confirm('Restore (reverse delete) this?') &&
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/reverse_delete_quote`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            user_id: user.id,
            quote_id: quote.id
          })
        })
    }
  }

  const handleToggleApprovalRequired = e => {
    if (quote.customerApprovalRequired === null) {
      if (quote?.market?.quoteApprovalRequired) {
        window.confirm('Remove approval required flag?') &&
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_approval_required_off`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              userId: user.id,
              quoteId: quote.id
            })
          }).then(res => res.ok || window.alert('Error'))
      } else {
        window.confirm('Flag approval required?') &&
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_approval_required_on`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              userId: user.id,
              quoteId: quote.id
            })
          }).then(res => res.ok || window.alert('Error'))
      }
    } else {
      if (!quote.customerApprovalRequired) {
        // updatedAttributes.customer_approval_required = true
        window.confirm('Flag approval required?') &&
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_approval_required_on`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              userId: user.id,
              quoteId: quote.id
            })
          }).then(res => res.ok || window.alert('Error'))
      } else {
        window.confirm('Remove approval required flag?') &&
          fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_approval_required_off`, {
            method: 'POST',
            headers: bearerTokenHeaders,
            body: JSON.stringify({
              userId: user.id,
              quoteId: quote.id
            })
          }).then(res => res.ok || window.alert('Error'))
      }
    }
  }

  if (jobsError) return <div>Error!</div>
  if (jobsLoading) return <div>Loading...</div>

  return (
    <>
      <Grid className={classes.gridRoot} container spacing={1}>
        {quote.customerMarkedApprovedAt && (
          <Grid item xs={12}>
            <Chip
              sx={{ mt: '.5rem' }}
              color='success'
              variant='outlined'
              size='small'
              icon={<CheckCircleRounded />}
              label={
                quote.customerMarkedApprovedAt
                  ? `Approved by customer on ${formatFullDate(quote.customerMarkedApprovedAt)}`
                  : ''
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Breadcrumbs lead={lead} quote={quote} activeObject={quote} />
          &nbsp;&nbsp;
          <Button
            variant='outlined'
            size='small'
            color='primary'
            onClick={() => window.open(`${process.env.REACT_APP_WWW_HOST}/quotes/${quote.id}`)}
          >
            open customer quote page
          </Button>
          {!quote.deletedAt && (
            <>
              &nbsp;&nbsp;
              <CreateATask targetType='Quote' targetId={quote.id} />
            </>
          )}
          &nbsp;&nbsp;
          <LabelReadWriter
            obj={quote}
            idName='quoteId'
            propName='nonPaymentReason'
            options={['Repair quality', 'Customer not reachable', 'Customer not at home']}
            endpoint='update_quote_attributes'
            refetch={refetchQuote}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value='delete'
            control={
              <Switch
                id='delete-quote-switch'
                color='primary'
                checked={!!quote.deletedAt}
                onChange={handleToggleDeleted}
              />
            }
            label='Delete'
            labelPlacement='bottom'
          />
          <FormControlLabel
            cy-data='audatex-switch'
            value='audatex'
            control={<Switch color='primary' checked={!!quote.toggledAudatexOnAt} onChange={handleToggleAudatex} />}
            label='Audatex'
            labelPlacement='bottom'
          />
          <FormControlLabel
            cy-data='insurance-switch'
            control={<Switch color='primary' disabled={!user.roles.includes('admin')} checked={!!quote.markedInsuranceAt} onChange={handleToggleInsurance} />}
            label='Insurance payment'
            labelPlacement='bottom'
          />
          <FormControlLabel
            cy-data='approval-switch'
            control={
              <Switch
                disabled={!!quote.customerMarkedApprovedAt}
                color='primary'
                checked={
                  quote.customerApprovalRequired === null
                    ? !!quote.customerApprovalRequired
                    : !!quote?.market?.quoteApprovalRequired
                }
                onChange={handleToggleApprovalRequired}
              />
            }
            label={`Approval required${quote.customerApprovalRequired === null ? '' : ' (inherited)'}`}
            labelPlacement='bottom'
          />
        </Grid>

        <Grid item xs={12}>
          <hr />
        </Grid>

        <Grid item xs={6}>
          <Typography color='primary' variant='h6'>
            Jobs:
          </Typography>
          <Jobs lead={lead} quote={quote} parts={quote.parts} />
        </Grid>

        <Grid item xs={6}>
          <Typography color='primary' variant='h6'>
            Appointments:
          </Typography>
          <Appointments lead={lead} quote={quote} parts={quote.parts} />
        </Grid>
        <Divider style={{ width: '100%' }} />

        <Grid item xs={8} style={{ position: 'relative' }}>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '.5em' }}>
              {quote.createdBy && (
                <>
                  Created by: <b>{quote.createdBy.firstName + ' ' + quote.createdBy.lastName.charAt(0) + '. '}</b>
                </>
              )}
              ({DateTime.fromISO(quote.createdAt).toFormat('f')})
              {quote.updatedAt && quote.updatedBy && (
                <>
                  , &nbsp; Updated by:{' '}
                  <b>{quote.updatedBy.firstName + ' ' + quote.updatedBy.lastName.charAt(0) + '. '}</b>(
                  {DateTime.fromISO(quote.updatedAt).toFormat('f')})
                </>
              )}
              , &nbsp; [
              <QuoteHistory quoteId={quote.id} />
              ]
              <br />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sx={{ pr: 2 }}>
              <LeadPhotoCarousel lead={lead} quote={quote} setCurrentPhotoId={setCurrentPhotoId} />
            </Grid>
          </Grid>
        </Grid>

        {/* RIGHT QUOTING PANE */}

        <QuoteCalculatorV2 lead={lead} quote={quote} currentPhotoId={currentPhotoId} refetchQuote={refetchQuote} />

        <Divider style={{ width: '100%', margin: '1.5em 0' }} />

        <Grid item xs={6}>
          <Notes objectType='quote' objectId={quote.id} />
          <DescendentNotes objectType='quote' objectId={quote.id} />
        </Grid>
        <Grid item xs={6}>
          <TechNotes
            headerText='TechNotes on this Quote'
            parentType='quote'
            parentId={quote.id}
            leadId={lead.id}
            quoteId={quote.id}
          />
          <TechNotesForJobsUnderQuote quote={quote} />
        </Grid>

        <Divider style={{ width: '100%', margin: '1.5em 0' }} />

        <Grid item xs={6}>
          <>
            <b style={{ fontSize: '12px' }}> payroll statuses:</b>
            {quote.payrollStatuses?.map(status => (
              <Chip size='small' key={status} label={status} sx={{ ml: '.25rem', fontWeight: 'bold' }} />
            ))}
          </>{' '}
          <br />
          <b style={{ fontSize: '12px' }}> payment status:</b>
          <Chip size='small' label={quote.paymentStatus} sx={{ ml: '.25rem', fontWeight: 'bold' }} /> <br />
          <Typography color='primary' variant='h6' sx={{ mb: '.5rem', mt: '.5rem' }}>
            Payroll history:
          </Typography>
          {quote.payoutIds?.length === 0 && (
            <div style={{ color: 'red', display: 'block' }}>This Quote has not yet been processed through payroll.</div>
          )}
          <PayrollHistoryTable quote={quote} />
        </Grid>

        <Grid item xs={6}>
          <Notes headerText='PAYROLL NOTES' objectType='QuoteForPayroll' objectId={quote.id} />
        </Grid>

        {quote.groupedPayments.length > 0 && (
          <Grid item xs={12}>
            <Typography color='primary' variant='h6' sx={{ mb: '.5rem' }}>
              Payments:
            </Typography>
            <PaymentsTableV2
              quote={quote}
              handleClickProcessRefund={handleClickProcessRefund}
              handleClickUnlinkSquarePayment={handleClickUnlinkSquarePayment}
              handleClickDeleteCashPayment={handleClickDeleteCashPayment}
              handleClickDeleteWriteoffAmount={handleClickDeleteWriteoffAmount}
              handleClickDeleteInsurancePayment={handleClickDeleteInsurancePayment}
              refetchQuote={refetchQuote}
            />
          </Grid>
        )}

        <Divider style={{ width: '100%', margin: '1.5em 0' }} />

        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            style={{
              padding: '1em',
              border: '1px solid lightgray',
              backgroundColor: 'ivory'
            }}
          >
            These must be saved separately:
            <br />
            <br />
            <TextField
              label='Commission % Override'
              value={editableFields.commissionPercentOverride}
              name='commissionPercentOverride'
              onChange={handleChangeCommissionPercentOverride}
              variant='outlined'
              size='small'
              InputLabelProps={{ shrink: true }}
              disabled={quote.payout}
              InputProps={{
                inputComponent: PercentageInput
              }}
            />
            <TextField
              label='Technician Payout Bonus'
              value={editableFields.payoutBonus}
              variant='outlined'
              name='technicianPayoutBonus'
              onChange={handleChangeTechnicianPayoutBonus}
              size='small'
              InputLabelProps={{ shrink: true }}
              disabled={quote.payout}
              InputProps={{
                inputComponent: PriceInput
              }}
            />
            &nbsp;
            {user.roles.includes('admin') && (
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='isAllowedPayoutBeforeFollowups'
                    checked={editableFields.isAllowedPayoutBeforeFollowups || false}
                    onChange={event => handleToggleQuoteAttribute(event, quote)}
                  />
                }
                label='Allow payout before followups complete'
              />
            )}
            <Button
              cy-data='save-technician-commission-override-button'
              variant='outlined'
              color='primary'
              onClick={handleClickSaveAttributes}
              disabled={!allowSavingAttributes}
            >
              save
            </Button>
          </Grid>

          <ManualCompensations quoteId={quote.id} /> <br />

          {quote?.payoutIds?.length > 0 && <CommissionChargeBacks quote={quote} quoteId={quote.id} />}
          {!!quote.markedInsuranceAt && (
            <Box
              sx={{
                maxWidth: '500px',
                padding: '1rem',
                minWidth: '250px',
                background: '#f1f1f1',
                border: '1px solid #ddd',
                mt: '1rem',
                mb: '1rem',
                '& .dzu-dropzone': {
                  overflow: 'hidden',
                  textAlign: 'center',
                  minHeight: '50px'
                },
                '& .dzu-inputLabel': {
                  fontSize: '14px'
                }
              }}
            >
              <Typography>Insurance Photos</Typography>

              <InsurancePhotoUploader refetchQuote={refetchQuote} quoteId={quote.id} />

              {quote.insurancePhotos?.length > 0 && (
                <Accordion>
                  <AccordionSummary expandIcon={<KeyboardArrowDownRounded />}>
                    Total insurance uploads: {quote.insurancePhotos.length}
                  </AccordionSummary>
                  <AccordionDetails>
                    {quote.insurancePhotos.map(photo => (
                      <img
                        onClick={() => window.open(photo)}
                        key={photo}
                        style={{ maxWidth: '100%', cursor: 'pointer' }}
                        src={photo}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          )}
        </Grid>

        {user.roles.includes('admin') && (
          <Grid container>
            <Grid item xs={6} style={{ padding: '1em', border: '1px solid red' }}>
              <Typography color='primary' variant='h6'>
                admin:
              </Typography>

              <Box>
                <Button size='small' variant='outlined' onClick={handleClickAddCashPayment}>
                  Add cash payment
                </Button>
                <Button size='small' variant='outlined' onClick={handleClickLinkASquarePayment}>
                  link a square payment
                </Button>
                {/* <Button size='small' variant='outlined' onClick={() => setShowWriteoffDialog(true)}>
                  Add writeoff amount
                </Button> */}
                {!!quote.markedInsuranceAt && (
                  <Button size='small' variant='outlined' onClick={handleClickAddInsurancePayment}>
                    Add insurance payment
                  </Button>
                )}
                <AddWriteoffAmountDialog
                  quote={quote}
                  isOpen={showWriteoffDialog}
                  onClose={() => setShowWriteoffDialog(false)}
                />
              </Box>

              <br />
              <hr />
              <br />
              <Admin quote={quote} />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sx={{ display: 'flex' }}>
          <StreamDump stream={`Quote$${quote.id}`} />
        </Grid>
      </Grid>
    </>
  )
}

export default ShowEditV2
