import { Quote, isPrimaryLaborEqual, isChargeEqual, Diff } from './types'

function doDiff<T>(old: Array<T>, cur: Array<T>, match: (t1: T, t2: T) => boolean) {
  const diff: { addItems: Array<T>; deleteItems: Array<T> } = { addItems: [], deleteItems: [] }

  for (let i = 0; i < old.length; i++) {
    if (!cur.find(item => match(old[i], item))) diff.deleteItems.push({ ...old[i] })
  }
  for (let i = 0; i < cur.length; i++) {
    if (!old.find(item => match(cur[i], item))) diff.addItems.push({ ...cur[i] })
  }

  return diff
}

function buildDiff<T>(diff: any, old: Array<T>, cur: Array<T>, diffKey: string, match: (t1: T, t2: T) => boolean) {
  const { addItems, deleteItems } = doDiff(old, cur, match)
  if (addItems.length > 0) diff[diffKey + 'Add'] = addItems
  if (deleteItems.length > 0) diff[diffKey + 'Delete'] = deleteItems
}

export default function newDiff(q1: Quote, q2: Quote): Diff {
  const diff: Diff = {}

  buildDiff(diff, q1.primaryLaborItems, q2.primaryLaborItems, 'primaryLabor', isPrimaryLaborEqual)
  buildDiff(diff, q1.otherLaborItems, q2.otherLaborItems, 'otherLabor', isChargeEqual)
  buildDiff(diff, q1.technicianCharges, q2.technicianCharges, 'techCharges', isChargeEqual)
  buildDiff(diff, q1.carbodylabCharges, q2.carbodylabCharges, 'carbodylabCharges', isChargeEqual)
  if (q1.offeredDiscount !== Number(q2.offeredDiscount)) diff.offeredDiscount = Number(q2.offeredDiscount)

  return diff
}
