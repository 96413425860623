import { TechnicianType } from './types/technician'
import { QuoteType } from './types/quote'
import {
  calculateWorklogPayoutData,
  getOnlyClosestWorklogToQuoteFullyPaid,
  getWorklogToSelectForCommissionPayment,
} from './worklogs'

export const calculateQuotePayoutData = ({ quote, technician }: { quote: QuoteType; technician: TechnicianType }) => {
  // for employees, quote payouts are dependent on there being a worklog
  // to pay the technician out with
  let payoutData = {}
  const commissionStructure = technician?.quoteCommissionStructure

  // worklogs need to have payout data calculated
  // const workLogs = quote.workLogs

  if (commissionStructure === 'hourly') {
    if (!quote.payrollStatuses.includes('commission_payable') && !quote.payrollStatuses.includes('tip_payable')) {
      return {}
    }

    // this checks to see if there is a worklog in this payroll period that is marked to pay out
    // commission for this particular job, if there isn't one then it finds the next eligible worklog
    // to pay commission
    const foundOrNextEligibleWorklogPayingCommision = getWorklogToSelectForCommissionPayment({ quote })
    const foundWorklogPayingTipForThisQuote = getOnlyClosestWorklogToQuoteFullyPaid({ quote })

    let tipToUse = 0
    if (quote.payrollStatuses && quote.payrollStatuses.includes('tip_payable') && foundWorklogPayingTipForThisQuote) {
      tipToUse = quote.tipSum
    }

    const commissionWorklog = foundOrNextEligibleWorklogPayingCommision
    let worklogPayoutData = {
      commissionAmount: 0,
    }
    if (commissionWorklog) {
      // this means that there IS a worklog selected to pay commission
      // for this quote... so use that worklog to determine what the
      // commission amount is for this given quote
      worklogPayoutData = calculateWorklogPayoutData({ workLog: commissionWorklog, technician })
    }

    payoutData = calculateEmployeeQuotePayoutData({
      technicianId: quote.assignedTechnicianId,
      technicianCharges: quote.technicianChargesSum,
      laborAmount: quote.laborSumAfterAllDiscounts,
      creditCardFeeSum: quote.ccFeeSum,
      tipSumBeforeFee: tipToUse,
      totalTransactionAmount: quote.totalTransactionAmount,
      hourlyRate: technician.hourlyRate,
      commissionAmount: quote.payrollStatuses.includes('commission_payable')
        ? // this needs to be the right value... commission needs to be recalculated for this worklog
          // quote.technician.commissionAmount we need to figure out the comission amount from the
          // appopriate worklog
          worklogPayoutData?.commissionAmount || 0
        : 0,
    })
  } else {
    let commissionPercent = technician?.commissionPercent || 0
    if (quote.commissionPercentOverride) {
      commissionPercent = quote.commissionPercentOverride
    }
    if (
      quote.payrollStatuses &&
      !quote.payrollStatuses.includes('commission_payable') &&
      !quote.forceIncludeInPayroll
    ) {
      commissionPercent = 0
    }

    if (technician.isEmployee) {
      payoutData = calculateEmployeeLaborCostQuotePayoutData({
        technicianId: quote.assignedTechnicianId,
        technicianCharges: quote.technicianChargesSum,
        laborAmount: quote.laborSumAfterAllDiscounts,
        creditCardFeeSum: quote.ccFeeSum,
        tipSumBeforeFee: quote.payrollStatuses && quote.payrollStatuses.includes('tip_payable') ? quote.tipSum : 0,
        totalTransactionAmount: quote.totalTransactionAmount,
        commissionPercent: commissionPercent,
        payoutBonus: quote.payoutBonus,
        techChargesCommissionPercent: technician.techChargesCommissionPercent,
      })
    } else {
      payoutData = calculateContractorQuotePayoutData({
        technicianId: quote.assignedTechnicianId,
        technicianCharges: quote.technicianChargesSum,
        laborAmount: quote.laborSumAfterAllDiscounts,
        creditCardFeeSum: quote.ccFeeSum,
        tipSumBeforeFee: quote.payrollStatuses && quote.payrollStatuses.includes('tip_payable') ? quote.tipSum : 0,
        totalTransactionAmount: quote.totalTransactionAmount,
        commissionPercent: commissionPercent,
        payoutBonus: quote.payoutBonus,
        techChargesCommissionPercent: technician.techChargesCommissionPercent,
      })
    }
  }

  return payoutData
}

export const calculateEmployeeQuotePayoutData = ({
  technicianCharges,
  laborAmount,
  creditCardFeeSum,
  tipSumBeforeFee,
  totalTransactionAmount,
  hourlyRate,
  commissionAmount,
  technicianId,
}: any) => {
  const tc = technicianCharges
  const t = tipSumBeforeFee
  const f = creditCardFeeSum
  const tt = totalTransactionAmount

  // # square doesnt send us the fee percent applied to each transaction, only the amount
  // # so lets calculate the % they applied to this transaction:
  const squareFeePercent = (tt == 0 && 0) || (f == 0 ? 0 : f / tt)

  // # determine the amount of processing fee derived from "technician charges"
  const squareFeeTechnicianChargesPortion = Number((tc * squareFeePercent).toFixed(2))

  // # determine the amount of processing fee derived from tip
  const squareFeeTipPortion = t * (squareFeePercent === 0 ? 1 : squareFeePercent)

  // # techs raw commissionAmount
  const techLaborCommission = commissionAmount

  // # technician charges minus the cc fee
  const technicianChargesLessSquareFee = Number((tc - squareFeeTechnicianChargesPortion).toFixed(2))

  // # techs tip after square fee
  const techTipLessSquareFee = Number((t - squareFeeTipPortion).toFixed(2))

  // # his final amount is
  // # commision minus square fee
  // # + tip minus square fee
  // + technicianChargesLessSquareFee removed from employee payouts
  const totalPayout = Number((techLaborCommission + techTipLessSquareFee).toFixed(2))

  return {
    totalPayout: totalPayout,
    technicianCcFee: Number((squareFeeTechnicianChargesPortion + squareFeeTipPortion).toFixed(2)),
    totalLaborAmount: laborAmount,

    laborCommissionBeforeCcFee: commissionAmount,
    laborCommissionCcFee: 0,
    laborCommissionAfterCcFee: commissionAmount,

    tipBeforeCcFee: t,
    tipCcFee: squareFeeTipPortion,
    tipAfterFee: techTipLessSquareFee,

    fullPayoutItemsBeforeCcFee: tc,
    fullPayoutItemsCcFee: squareFeeTechnicianChargesPortion,
    fullPayoutItemsAfterCcFee: technicianChargesLessSquareFee,

    payoutBonus: 0,
    deducedCcFeePercent: squareFeePercent,
    commissionPercentApplied: 0,
    commissionRateApplied: commissionAmount,
    hourlyRateApplied: hourlyRate,
    payoutType: 'hourly',
    technicianId: technicianId,
  }
}

export const calculateContractorQuotePayoutData = ({
  technicianCharges,
  laborAmount,
  creditCardFeeSum,
  tipSumBeforeFee,
  totalTransactionAmount,
  commissionPercent,
  payoutBonus,
  technicianId,
  techChargesCommissionPercent,
}: any) => {
  const tc = technicianCharges
  const t = tipSumBeforeFee
  const f = creditCardFeeSum
  const tt = totalTransactionAmount

  // cast numberic inputs (floats) into Money objects
  const l = laborAmount
  const bonus = payoutBonus || 0

  // square doesnt send us the fee percent applied to each transaction, only the amount
  // so lets calculate the % they applied to this transaction:
  const squareFeePercent = (tt === 0 && 0) || (f === 0 ? 0 : f / tt)

  // determine the amount of processing fee derived from labor
  const squareFeeLaborPortion = l * squareFeePercent
  const squareFeeTechnicianChargesPortion = tc * squareFeePercent
  const squareFeeTipPortion = t * squareFeePercent

  // out of the total square fee, determine how much of the fee should be covered by the technician
  // and thus deducted
  const techSquareFeeLaborDeduction = squareFeeLaborPortion * (commissionPercent / 100)
  const techLaborCommission = laborAmount * (commissionPercent / 100)
  const techLaborCommissionLessSquareFee = techLaborCommission - techSquareFeeLaborDeduction

  // techs commission after square fee
  const techSquareFeeTechChargesDeduction = squareFeeTechnicianChargesPortion * (techChargesCommissionPercent / 100)
  const techChargesCommission = tc * (techChargesCommissionPercent / 100)
  const technicianChargesLessSquareFee = Number(techChargesCommission - techSquareFeeTechChargesDeduction)

  // techs tip after square fee
  const techTipLessSquareFee = t - squareFeeTipPortion

  // his final amount is commision minus square fee + tip minus square fee
  const totalPayout = Number(
    (techLaborCommissionLessSquareFee + technicianChargesLessSquareFee + techTipLessSquareFee + bonus).toFixed(2)
  )

  return {
    totalPayout: totalPayout,

    technicianCcFee: Number(
      (techSquareFeeLaborDeduction + squareFeeTechnicianChargesPortion + squareFeeTipPortion).toFixed(2)
    ),
    totalLaborAmount: laborAmount,

    laborCommissionBeforeCcFee: techLaborCommission.toFixed(2),
    laborCommissionCcFee: techSquareFeeLaborDeduction.toFixed(2),
    laborCommissionAfterCcFee: techLaborCommissionLessSquareFee.toFixed(2),

    tipBeforeCcFee: t,
    tipCcFee: Number(squareFeeTipPortion.toFixed(2)),
    tipAfterFee: Number(techTipLessSquareFee.toFixed(2)),

    fullPayoutItemsBeforeCcFee: tc,
    fullPayoutItemsCcFee: Number(squareFeeTechnicianChargesPortion.toFixed(2)),
    fullPayoutItemsAfterCcFee: Number(technicianChargesLessSquareFee.toFixed(2)),

    payoutBonus: payoutBonus,
    deducedCcFeePercent: squareFeePercent,
    commissionPercentApplied: commissionPercent,
    commissionRateApplied: 0,
    techChargesCommissionPercent: techChargesCommissionPercent,
    payoutType: 'labor cost',
    technicianId: technicianId,
  }
}

export const calculateEmployeeLaborCostQuotePayoutData = ({
  technicianCharges,
  laborAmount,
  creditCardFeeSum,
  tipSumBeforeFee,
  totalTransactionAmount,
  commissionPercent,
  payoutBonus,
  technicianId,
  techChargesCommissionPercent,
}: any) => {

  const techLaborCommission = laborAmount * (commissionPercent / 100)
  const techChargesCommission = technicianCharges * (techChargesCommissionPercent / 100)

  const totalPayout = Number(
    (techLaborCommission + techChargesCommission + tipSumBeforeFee + payoutBonus)
  )

  return {
    totalPayout: totalPayout,
    technicianCcFee: 0,
    totalLaborAmount: laborAmount,
    laborCommissionBeforeCcFee: Number(techLaborCommission.toFixed(2)),
    laborCommissionCcFee: 0,
    laborCommissionAfterCcFee: Number(techLaborCommission.toFixed(2)),
    tipBeforeCcFee: tipSumBeforeFee,
    // we don't charge employee technicians the square fee
    tipCcFee: creditCardFeeSum,
    tipAfterFee: tipSumBeforeFee,
    fullPayoutItemsBeforeCcFee: techChargesCommission,
    fullPayoutItemsCcFee: 0,
    fullPayoutItemsAfterCcFee: techChargesCommission,
    payoutBonus: payoutBonus,
    deducedCcFeePercent: 0,
    commissionPercentApplied: commissionPercent,
    commissionRateApplied: 0,
    techChargesCommissionPercent: techChargesCommissionPercent,
    payoutType: 'labor cost',
    technicianId: technicianId,
  }
}
